import {useCallback, useEffect, useMemo} from "react";
import {useFormikContext} from "formik";
import classnames from "classnames";
import {useNavigate} from "react-router-dom";

import {Button, InputRow} from "../../../../components/input";
import {useDebounceCallback} from "../../../../hooks/use-debounce-callback";
import {FormValues} from "./types";
import {PostEditor} from "./post-editor";
import {postServices, Service, Share, useMyUser} from "../../../../data";
import {Span, Span2} from "../../../../components/text";
import {SharePreview, SocialScore} from "../../components";
import {HoverTooltip} from "../../../../components/tooltip";
import {useConfirmModal} from "../../../../modals";

import styles from "./styles.module.scss";

const bStyles = classnames.bind(styles);

interface EditPostFormProps {
	share: Share;
	socialScore?: {
		value: number;
		potential?: number;
		suggestion?: string;
	};
	disableContentEditing?: boolean;
}

export const EditPostForm = ({share, socialScore, disableContentEditing}: EditPostFormProps) => {
	const {
		submitForm,
		isValid,
		values,
		isSubmitting,
		setFieldValue,
		setSubmitting,
		dirty,
	} = useFormikContext<FormValues>();
	const me = useMyUser();
	const navigate = useNavigate();
	const onClose = useCallback(async () => {
		await submitForm();
		navigate(`/collections/posts`);
	}, [navigate, submitForm]);
	const debouncedSubmit = useDebounceCallback(submitForm, 2000);
	const onShareAgain = useCallback(
		() =>
			navigate("/collections/posts/new", {
				state: {id: share?.id, type: "personal"},
			}),
		[navigate, share?.id]
	);
	const {open: openShareNowModal} = useConfirmModal(
		() => ({
			title: "Share all posts now",
			body:
				"All posts that have not already been shared will be immediately shared on social media. This action cannot be undone.",
			confirming: isSubmitting,
			onConfirm: close => {
				close();
				setSubmitting(true);
				setFieldValue("schedule", {...values.schedule, immediately: true});
			},
		}),
		[isSubmitting, setSubmitting, setFieldValue, values]
	);

	const futureShares = useMemo(
		() =>
			share.shareEvents.filter(
				se =>
					(se.scheduledFor.isAfter() && (!se.sharedAt || !se.result)) ||
					(se.scheduledFor.isBefore() && !se.result)
			),
		[share.shareEvents]
	);

	const pastShares = useMemo(
		() => share.shareEvents.filter(se => (se.sharedAt || se.scheduledFor.isBefore()) && se.result),
		[share.shareEvents]
	);

	useEffect(() => {
		dirty && debouncedSubmit();
	}, [dirty, values, debouncedSubmit]);

	const scSuggestion = useMemo(() => {
		const messages = Object.entries(share.smartScoreSuggestions)
			.filter(([key, suggestions]) => postServices.includes(key as Service) && !!suggestions)
			.flatMap(([, suggestions]) => suggestions.map(s => s.message));

		return (
			<>
				{Array.from(new Set(messages)).map((message, index) => (
					<p key={index}>{message}</p>
				))}
			</>
		);
	}, [share]);

	return (
		<div className={bStyles(styles.postForm, styles.editMode)}>
			<div className={styles.postBody}>
				{!!futureShares.length && (
					<div className={styles.sharesGroup}>
						<div className={styles.groupHeader}>
							<div>
								<div className={styles.titleContainer}>
									<Span className={styles.title}>Not shared yet</Span>
									<Span2>These social media posts have not been shared yet.</Span2>
								</div>
								{!!socialScore && (
									<div className={styles.socialScoreContainer}>
										<SocialScore
											socialScore={socialScore.value}
											potentialSocialScore={socialScore.potential}
										/>
									</div>
								)}
							</div>
							<HoverTooltip text={scSuggestion} positions={["top", "bottom"]}>
								<div className={styles.socialScoreSuggestion}>{socialScore?.suggestion}</div>
							</HoverTooltip>
						</div>
						{futureShares.map((se, index) => (
							<PostEditor
								key={`${se.id}-${index}`}
								shareEvent={se}
								totalShareEvents={futureShares.length}
								disableContentEditing={disableContentEditing}
							/>
						))}
					</div>
				)}
				{!!futureShares.length && !!pastShares.length && <div className={styles.separator} />}
				{!!pastShares.length && (
					<div className={styles.sharesGroup}>
						<div className={styles.groupHeader}>
							<div className={styles.titleContainer}>
								<Span className={styles.title}>Shared</Span>
								<Span2>These social media posts were already shared.</Span2>
							</div>
							{!!socialScore && (
								<div className={styles.socialScoreContainer}>
									<SocialScore socialScore={socialScore.value} potentialSocialScore={socialScore.potential} />
								</div>
							)}
						</div>
						{pastShares.map((se, index) => (
							<SharePreview key={`${se.id}-${index}`} shareEvent={se} share={share} />
						))}
					</div>
				)}
			</div>
			<InputRow className={styles.formBottomToolbar}>
				<Button value={"Close"} onClick={onClose} invert />
				{!!pastShares.length && !me.org?.options?.blockDuplicatePost && (
					<Button value={"Share Again"} invert onClick={onShareAgain} loading={isSubmitting} />
				)}
				<Button value={"Share Now"} onClick={openShareNowModal} loading={isSubmitting} disabled={!isValid} />
			</InputRow>
		</div>
	);
};
