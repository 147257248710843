import {useCallback, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";

import {Button, Checkbox, InputRow, SearchableSelect, Select, Separator, Text} from "../../components/input";
import {P, P1, Span1} from "../../components/text";
import {
	CREATE_SSO_CERTIFICATE,
	CREATE_SSO_SETTINGS,
	GET_SSO_SETTINGS,
	UPDATE_SSO_SETTINGS,
} from "../../data/sso";
import {Loading} from "../../components/loading";
import {useMutationToast} from "../../toast";

import styles from "./sso.module.scss";

type SSOSettings = {
	domains: string[];
	ssoUrl: string;
	sloUrl: string;
	signinCert: string;
	issuerUrl: string;
	certificate: string;
	service: string;
	disableRequestedAuthnContext: boolean;
};

const SSOInputs = ({defaultValues}: {defaultValues: SSOSettings}) => {
	const [updateSSOSettings, {loading: loadingUpdate}] = useMutationToast(UPDATE_SSO_SETTINGS);
	const [createSSOSettings, {loading: loadingCreate}] = useMutationToast(CREATE_SSO_SETTINGS);
	const [singInUrl, setSignInUrl] = useState(defaultValues.ssoUrl);
	const [singOutUrl, setSignOutUrl] = useState(defaultValues.sloUrl);
	const [issuerUrl, setIssuerUrl] = useState(defaultValues.issuerUrl);
	const [ssoPlatform, setSsoPlatform] = useState(defaultValues.service);
	const [disableAuthContext, setDisableAuthContext] = useState(defaultValues.disableRequestedAuthnContext);
	const [domains, setDomains] = useState<string[]>(defaultValues.domains);
	const [publicCertificate, setPublicCertificate] = useState(defaultValues.certificate);

	const onSave = useCallback(() => {
		const variables = {
			ssoUrl: singInUrl,
			sloUrl: singOutUrl,
			issuerUrl: issuerUrl,
			service: ssoPlatform,
			domains: domains,
			certificate: publicCertificate,
			disableRequestedAuthnContext: disableAuthContext,
		};
		if (defaultValues.signinCert) {
			updateSSOSettings({
				variables,
			});
		} else {
			createSSOSettings({
				variables,
			});
		}
	}, [
		singInUrl,
		singOutUrl,
		issuerUrl,
		ssoPlatform,
		disableAuthContext,
		publicCertificate,
		defaultValues,
		updateSSOSettings,
		createSSOSettings,
		domains,
	]);

	const isDirty = useMemo(
		() =>
			singInUrl !== defaultValues.ssoUrl ||
			singOutUrl !== defaultValues.sloUrl ||
			issuerUrl !== defaultValues.issuerUrl ||
			ssoPlatform !== defaultValues.service ||
			disableAuthContext !== defaultValues.disableRequestedAuthnContext ||
			publicCertificate !== defaultValues.certificate ||
			domains.join(",") !== defaultValues.domains.join(","),
		[
			singInUrl,
			singOutUrl,
			issuerUrl,
			ssoPlatform,
			disableAuthContext,
			publicCertificate,
			domains,
			defaultValues,
		]
	);
	const options = [];
	const loading = loadingUpdate || loadingCreate;

	return (
		<>
			<Text value={singInUrl} onChange={setSignInUrl} label="Single Sign-In URL:" />
			<Text value={singOutUrl} onChange={setSignOutUrl} label="Single Logout URL:" />
			<Text value={issuerUrl} onChange={setIssuerUrl} label="Issuer URL:" />

			<SearchableSelect
				className={styles.select}
				label="Domains:"
				isMulti
				options={options}
				value={domains.map(domain => ({label: domain, value: domain}))}
				onChange={selectedOptions =>
					setDomains(Array.isArray(selectedOptions) ? selectedOptions.map(option => option.value) : [])
				}
				onAdd={value => setDomains([...domains, value])}
			/>

			<Select
				value={ssoPlatform}
				onChange={setSsoPlatform}
				label="SSO Platform:"
				options={[
					{
						label: "Okta",
						value: "okta",
					},
					{
						label: "OneLogin",
						value: "onelogin",
					},
					{
						label: "Azure AD",
						value: "azuread",
					},
				]}
			/>
			{ssoPlatform === "azuread" && (
				<div className="space">
					<P>Having issues with requesting particular authentication context? </P>
					<Checkbox
						value={disableAuthContext}
						onChange={setDisableAuthContext}
						label="Disable the Requested Authentication Context, and allow ADFS to make its own decision."
					/>
				</div>
			)}
			<Text
				maxRows={10}
				className={styles.textarea}
				type="textarea"
				value={publicCertificate}
				onChange={setPublicCertificate}
				label="Your Public Certificate:"
			/>
			<Button disabled={!isDirty} onClick={onSave} value="Save Changes" loading={loading} />
		</>
	);
};

export const SSO = () => {
	const {data, loading} = useQuery(GET_SSO_SETTINGS);
	const [createSSOCertificate, {loading: loadingCertificate}] = useMutationToast(CREATE_SSO_CERTIFICATE, {
		update: (cache, {data}) => {
			if (data) {
				const existingCache: {sso: SSOSettings} | null = cache.readQuery({query: GET_SSO_SETTINGS});
				cache.writeQuery({
					query: GET_SSO_SETTINGS,
					data: {
						sso: {
							...(existingCache ? existingCache.sso : {}),
							signinCert: data.createSSOCertificate,
						},
					},
				});
			}
		},
	});

	const sso = useMemo(() => data?.sso, [data]);
	const downloadCertificate = useCallback(() => {
		const certificate = sso?.signinCert;
		if (certificate) {
			const blob = new Blob([certificate], {type: "text/plain"});
			const a = document.createElement("a");
			a.href = URL.createObjectURL(blob);
			a.download = "cvsPublicCertificate.cert";
			a.click();
			URL.revokeObjectURL(a.href);
		}
	}, [sso]);
	return (
		<div>
			<InputRow position="between">
				<div>
					<h3>Single-Sign on</h3>
					<Span1 className={styles.description}>
						Single sign-on (SSO) is an authentication process where users can log in to multiple applications
						from different devices using a single set of credentials.{" "}
						<a href="https://cvssupport.wpenginepowered.com/article/single-sign-on-with-clearview-social">
							Learn more
						</a>{" "}
						about SSO.
					</Span1>
				</div>
			</InputRow>
			<Separator horizontal />
			<div className={styles.inputs}>
				{loading ? (
					<Loading />
				) : !sso ? (
					<div>Failed to load sso settings</div>
				) : (
					<>
						<div className={styles.box}>
							<P1 bold>Provider SSO settings</P1>
							<Text onChange={() => undefined} label="Our SSO URL:" value={sso.ourSSOUrl} disabled withCopy />
							<Text onChange={() => undefined} label="Our SLO URL:" value={sso.ourSLOUrl} disabled withCopy />
							<Text
								onChange={() => undefined}
								label="Our Entity ID:"
								value={sso.ourEntityId}
								disabled
								withCopy
							/>

							{sso.signinCert && (
								<Text
									maxRows={10}
									className={styles.textarea}
									type="textarea"
									disabled
									withCopy
									value={sso.signinCert}
									onChange={() => undefined}
									loading={loadingCertificate}
									label="Our Public Certificate:"
								/>
							)}
							<InputRow>
								{sso.signinCert && (
									<Button
										disabled={loadingCertificate}
										onClick={downloadCertificate}
										value="Download Certificate"
									/>
								)}
								<Button
									loading={loadingCertificate}
									onClick={createSSOCertificate}
									invert
									value={`${sso.signinCert ? "Reg" : "G"}enerate Certificate`}
								/>
							</InputRow>
						</div>
						<div className={styles.box}>
							<SSOInputs defaultValues={sso} />
						</div>
					</>
				)}
			</div>
		</div>
	);
};
