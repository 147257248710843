import {ReactElement, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLocalStorage, useSearchParam} from "react-use";
import {useQuery} from "@apollo/client";
import dayjs from "dayjs";
import {useLocation} from "react-router";

import {Button, DropdownButton, InputRow, Option, TabProps, Text} from "../../components/input";
import {
	GET_COMPANY_SHARE_EVENTS,
	GET_USER_EVENTS,
	GET_USER_SHARE_EVENTS,
	SCHEDULE_QUEUE,
	SCHEDULE_SHARE_EVENT,
	ShareEvent,
	ShareStatus,
	loadShareEvent,
	useMyUser,
} from "../../data";
import {defaultLoadingProps as loadingProps, usePaginatedQuery} from "../../paginated-query";
import {usePageScroll} from "../../layout";
import {ShareItem} from "./share-item";
import {ToggleGroup} from "../../components/toggle";
import {useDebounce} from "../../debounce";
import {useMutationToast, useToast} from "../../toast";
import {useCompanyPagesList} from "../../data/company";
import {TabsSelectSomeMenu} from "../../components/input/dropdown-button";
import {CloseFunc, useConfirmModal} from "../../modals";
import {P, Span2} from "../../components/text";

import styles from "./collection.module.scss";

const renderItem = (shareEvent: ShareEvent) => <ShareItem key={shareEvent.id} shareEvent={shareEvent} />;

const options: Option<boolean>[] = [
	{value: false, label: "Company"},
	{value: true, label: "Personal"},
];

export interface PostsFilter {
	status: ShareStatus[];
	sort: "created" | "scheduled";
	accounts: number[];
}

const defaultFilter = {
	status: ["scheduled", "failed"],
	sort: "created",
	accounts: [],
} as PostsFilter;

export const Posts = (): ReactElement => {
	const [cookieValue, setPersonalShareEvents] = useLocalStorage("collections-posts-personal", false);
	const personalShareEvents = useMemo(() => cookieValue ?? false, [cookieValue]);
	const [search, setSearch] = useState("");
	const location = useLocation();
	const debouncedSearch = useDebounce(search, 300);
	const navigate = useNavigate();
	const user = useMyUser();
	const [filter, setFilter] = useLocalStorage<PostsFilter>(
		`collections-posts-filter-${user.id}`,
		defaultFilter
	);
	const toast = useToast();

	const shareId = useSearchParam("queued_url_id");
	const queueId = useSearchParam("queue_id");
	const companyId = useSearchParam("company_user_id");
	const url = useSearchParam("url");

	const {companies, loading: loadingCompanies} = useCompanyPagesList();

	const [scheduleSharesByQueueUrlId, {data: scheduleSharesByQueueUrlData}] = useMutationToast(
		SCHEDULE_SHARE_EVENT
	);
	const [scheduleSharesByQueueId, {data: scheduleSharesByQueueData}] = useMutationToast(SCHEDULE_QUEUE);
	const unsharedUrls = useMemo(() => {
		const shares = scheduleSharesByQueueUrlData?.scheduleSharesByQueueUrlId
			? [scheduleSharesByQueueUrlData?.scheduleSharesByQueueUrlId]
			: scheduleSharesByQueueData?.scheduleSharesByQueueId;

		return shares?.filter(share => !share.shareEvents?.length).map(share => share.url);
	}, [scheduleSharesByQueueUrlData, scheduleSharesByQueueData]);

	const schedulingResultModal = useConfirmModal(
		() => ({
			title: queueId ? "Your posts have been scheduled!" : "Your post has been scheduled!",
			body: (
				<>
					<P>{`Some of your posts could not be scheduled. The following URL${
						unsharedUrls?.length > 1 ? "s have" : " has"
					} been scheduled before:`}</P>
					{unsharedUrls?.map((url, index) => (
						<P key={index} href={url}>
							{url}
						</P>
					))}
				</>
			),
			Footer: ({close}: {close: CloseFunc<null>}) => (
				<InputRow position="right">
					<Button autoFocus invert color="black" onClick={() => close()} value="Close" />
				</InputRow>
			),
			onConfirm: close => close(),
		}),
		[unsharedUrls, queueId]
	);

	useEffect(() => {
		const scheduleFunction = shareId ? scheduleSharesByQueueUrlId : queueId ? scheduleSharesByQueueId : null;
		if (url) {
			navigate("new", {state: {type: "personal", url}});
		}
		if (scheduleFunction) {
			setPersonalShareEvents(!companyId);
			toast({
				color: "blue",
				text: queueId
					? "Scheduling all items from your Collection for sharing"
					: "Scheduling your post for sharing",
				timeout: 2,
			});
			scheduleFunction({
				variables: {id: queueId ?? shareId, ...(companyId && {companyId}), timezone: dayjs.tz.guess()},
				onCompleted: data => {
					const shares = data?.scheduleSharesByQueueUrlId
						? [data?.scheduleSharesByQueueUrlId]
						: data?.scheduleSharesByQueueId;
					const unsharedUrls = shares?.filter(share => !share.shareEvents?.length).map(share => share.url);

					if (unsharedUrls?.length) {
						schedulingResultModal.open();
						return;
					}

					toast({
						color: "green",
						text: queueId ? "Your posts have been scheduled!" : "Your post has been scheduled!",
					});
				},
			});
		}
	}, [
		navigate,
		toast,
		shareId,
		companyId,
		url,
		setPersonalShareEvents,
		queueId,
		scheduleSharesByQueueUrlId,
		scheduleSharesByQueueId,
		schedulingResultModal,
	]);

	const variables = useMemo(() => {
		const filterStatuses = Array.from(
			new Set([...(filter?.status ?? []), ...(location?.state?.filter?.status ?? [])])
		);
		const statuses = filterStatuses.length ? filterStatuses : ["scheduled", "delivered", "failed"];

		return {
			limit: 20,
			sort: filter?.sort?.toUpperCase(),
			status: statuses.map(s => s.toUpperCase()),
			ids: filter?.accounts.length && !personalShareEvents ? filter.accounts : undefined,
			search: debouncedSearch,
		};
	}, [filter, debouncedSearch, personalShareEvents, location?.state?.filter?.status]);

	const SCHEDULED_SHARE_EVENTS_QUERY =
		!personalShareEvents && user.role === "admin" ? GET_COMPANY_SHARE_EVENTS : GET_USER_SHARE_EVENTS;

	const empty = useMemo(
		() => (
			<div className={styles.empty}>
				<img src="/svgs/collection_blank.svg" alt="No Posts" />
				{!!(filter?.status?.length || filter?.accounts?.length) && (
					<div>
						<Span2>No posts found with the selected filter.</Span2>{" "}
						<Span2
							color={"blue"}
							onClick={() => setFilter({...filter, status: [], accounts: []} as PostsFilter)}
						>
							Clear Filter
						</Span2>
					</div>
				)}
			</div>
		),
		[filter, setFilter]
	);

	const {handleScroll, render, data} = usePaginatedQuery<ShareEvent>(SCHEDULED_SHARE_EVENTS_QUERY, {
		loadingProps,
		inflateItem: loadShareEvent,
		variables,
		renderItem,
		itemsClassName: styles.collectionList,
		empty,
	});
	usePageScroll(handleScroll);

	const toBeSentPosts = useMemo(
		() =>
			data
				?.filter(share => !share.sharedAt && dayjs(share?.scheduledFor).isBefore())
				?.map(share => share.id) ?? [],
		[data]
	);
	const {stopPolling, startPolling} = useQuery(GET_USER_EVENTS, {
		variables: {ids: toBeSentPosts},
		skip: !toBeSentPosts?.length,
	});

	useEffect(() => {
		if (toBeSentPosts.length === 0) return stopPolling();
		startPolling(5000);
	}, [startPolling, stopPolling, toBeSentPosts]);

	const postsTab = useMemo(() => {
		let t: TabProps<keyof Omit<PostsFilter, "sort">, string | number>[] = [
			{
				label: "Status",
				value: "status",
				options: [
					{value: "scheduled", label: "Scheduled"},
					{value: "failed", label: "Failed"},
					{value: "delivered", label: "Delivered"},
				],
			},
		];
		if (!personalShareEvents) {
			t = [
				{
					label: "Account",
					value: "accounts",
					loading: loadingCompanies,
					options: companies,
				},
				...t,
			];
		}
		return t;
	}, [companies, personalShareEvents, loadingCompanies]);

	const filterByButton: TabProps<keyof Omit<PostsFilter, "sort">, string | number>[] = useMemo(
		() =>
			user.role === "admin"
				? postsTab
				: [
						{
							label: "Status",
							value: "status",
							options: [
								{value: "scheduled", label: "Scheduled"},
								{value: "failed", label: "Failed"},
								{value: "delivered", label: "Delivered"},
							],
						},
				  ],
		[user, postsTab]
	);

	const total = useMemo(
		() =>
			Object.keys(filter ?? {}).reduce(
				(acc, current) =>
					acc +
					(Array.isArray(filter?.[current]) && (current !== "accounts" || !personalShareEvents)
						? filter?.[current].length
						: 0),
				0
			),
		[filter, personalShareEvents]
	);

	const filterLabel = total > 0 ? `${total} filter(s) applied` : "Filter By";

	return (
		<>
			{user.role === "admin" && (
				<div className="space">
					<ToggleGroup options={options} onChange={setPersonalShareEvents} value={personalShareEvents} />
				</div>
			)}
			<InputRow position="between" className={styles.postsHeader}>
				<InputRow className={styles.headerRow}>
					<Button
						onClick={() =>
							navigate("new", {
								state: {
									type: personalShareEvents || user.role === "user" ? "personal" : "company",
									askLoadUnsaved: true,
								},
							})
						}
						value="Create Post"
						icon="edit"
						className={styles.dropdownButton}
					/>
				</InputRow>
				<InputRow className={styles.headerRow}>
					<Text value={search} onChange={setSearch} placeholder="Search" icon="search" />
					<DropdownButton
						value="Sort By"
						arrow
						invert
						options={[
							{label: "Last Created", onClick: () => setFilter({...filter, sort: "created"} as PostsFilter)},
							{label: "Scheduled", onClick: () => setFilter({...filter, sort: "scheduled"} as PostsFilter)},
						]}
					/>
					<TabsSelectSomeMenu
						label={filterLabel}
						value={filter ?? ({} as PostsFilter)}
						tabs={filterByButton}
						onChange={v => setFilter({...filter, ...v} as PostsFilter)}
					/>
				</InputRow>
			</InputRow>
			<div className="space">{render}</div>
		</>
	);
};
