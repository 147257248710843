import {useMutation, useQuery} from "@apollo/client";
import {useCallback, useEffect, useMemo, useState} from "react";

import {CREATE_CANVA_EXPORT_DESIGN_JOB, GET_CANVA_EXPORT_DESIGN_JOB} from "../data/canva";
import {useToast} from "../toast";
import {UPLOAD_IMAGE_URL} from "../data/media";

export const useCanvaDesign = ({
	onCompleted,
	onError,
}: {
	onCompleted?: ({url, id}: {url: string; id: string}) => void;
	onError?: (error: Error) => void;
}) => {
	const [id, setId] = useState<string>();
	const [designId, setDesignId] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);
	const [url, setUrl] = useState<string>();
	const {startPolling, stopPolling, data} = useQuery(GET_CANVA_EXPORT_DESIGN_JOB, {
		variables: {
			id: id,
		},
		onError,
		skip: !id,
	});
	const [uploadImageUrl, {loading: uploadLoading}] = useMutation(UPLOAD_IMAGE_URL, {
		onCompleted: data => {
			setUrl(data.uploadImageUrl);
			if (onCompleted && designId) {
				onCompleted({url: data.uploadImageUrl, id: designId});
			}
		},
		onError,
	});

	const toast = useToast();
	const [createCanvaDesignJob] = useMutation(CREATE_CANVA_EXPORT_DESIGN_JOB, {
		onCompleted: data => {
			if (data) {
				setId(data.createCanvaDesignExportJob.id);
				startPolling(1000);
			}
		},
		onError: error => {
			if (onError) onError(error);
			setLoading(false);
			if (error.message.includes("bad_request_body")) {
				toast({
					color: "red",
					text: "Unsupported file format.",
				});
			}
		},
	});
	const exportUrl = useMemo(() => data?.exportDesignJob?.urls?.[0], [data?.exportDesignJob?.urls]);
	const status = useMemo(() => data?.exportDesignJob?.status, [data?.exportDesignJob?.status]);

	const createDesignJob = useCallback(
		(id: string) => {
			setLoading(true);
			setDesignId(id);
			createCanvaDesignJob({
				variables: {
					id: id,
				},
			});
		},
		[createCanvaDesignJob]
	);

	const onExportJobCompleted = useCallback(() => {
		setId(undefined);
		uploadImageUrl({
			variables: {
				url: exportUrl,
			},
		});
		setLoading(false);
		stopPolling();
	}, [exportUrl, stopPolling, setLoading, setId, uploadImageUrl]);

	useEffect(() => {
		if (status && status !== "in_progress") {
			onExportJobCompleted();
		}
	}, [status, onExportJobCompleted]);

	const loadingExport = loading;
	const loadingUpload = uploadLoading;
	return {
		createDesignJob,
		loading: status === "in_progress" || loadingExport || loadingUpload,
		loadingExport: loadingExport,
		loadingUpload: loadingUpload,
		status:
			loadingUpload || loadingExport || status || !url
				? loadingUpload
					? "uploading_image"
					: url
					? "completed"
					: "exporting_image"
				: "ready_to_start",
		step: loadingUpload || loadingExport || status || !url ? (loadingUpload ? 2 : url ? 3 : 1) : 0,
		url,
	};
};
