import {useMutation, useQuery} from "@apollo/client";
import {useCallback, useEffect, useMemo, useState} from "react";

import {GET_ASSET_UPLOAD_JOB, UPLOAD_IMAGE_TO_CANVA} from "../data/canva";
import {useToast} from "../toast";

export const useCanvaAsset = ({onComplete}) => {
	const toast = useToast();

	const [jobId, setJobId] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);

	const {startPolling, stopPolling, data} = useQuery(GET_ASSET_UPLOAD_JOB, {
		variables: {
			id: jobId,
		},
		skip: !jobId,
	});
	const [createCanvaDesignJob] = useMutation(UPLOAD_IMAGE_TO_CANVA, {
		onCompleted: data => {
			if (data) {
				setJobId(data.uploadImageToCanva.id);
				startPolling(1000);
			}
		},
		onError: error => {
			setLoading(false);
			if (error.message.includes("bad_request_body")) {
				toast({
					color: "red",
					text: "Unsupported file format.",
				});
			}
		},
	});
	const status = useMemo(() => data?.assetUploadJob?.status, [data?.assetUploadJob?.status]);
	const assetId = useMemo(() => data?.assetUploadJob?.asset?.id, [data?.assetUploadJob?.asset?.id]);

	const createAssetJob = useCallback(
		(url: string) => {
			setLoading(true);

			createCanvaDesignJob({
				variables: {
					url,
				},
			});
		},
		[createCanvaDesignJob]
	);

	const onExportJobCompleted = useCallback(() => {
		setLoading(false);
		stopPolling();
	}, [stopPolling, setLoading]);

	useEffect(() => {
		if (status && status !== "in_progress" && jobId) {
			setJobId(undefined);
			onExportJobCompleted();
			onComplete(assetId);
		}
	}, [status, onExportJobCompleted, assetId, onComplete, jobId]);

	return {
		createAssetJob,
		assetId: assetId,
		loading: status === "in_progress" || loading,
	};
};
