import React, {FC, useCallback, useMemo} from "react";
import dayjs from "dayjs";
import {useFormikContext} from "formik";

import {Modal, ModalData} from "../../../../modals/new";
import {Button, CalendarButton, InputRow} from "../../../../components/input";
import {Icon} from "../../../../components/images";
import {Span2, Span3} from "../../../../components/text";
import {Recipients} from "./recipients";
import {CollectionFormValues} from "./collection-form";
import {Collection, getPostEmv, SAVE_COLLECTION} from "../../../../data";
import {formatMoney} from "../../../../utils/number";
import {useDebounceCallback} from "../../../../hooks/use-debounce-callback";
import {useMutationToast} from "../../../../toast";

import styles from "./styles.module.scss";

interface CollectionScheduleModalProps {
	modal: ModalData;
	collection: Collection;
	onConfirm: () => void;
}
export const CollectionScheduleModal: FC<CollectionScheduleModalProps> = ({modal, collection, onConfirm}) => {
	const [saveCollection, {loading: saving}] = useMutationToast(SAVE_COLLECTION);
	const {values, isSubmitting, setFieldValue, submitForm} = useFormikContext<CollectionFormValues>();
	const hasRecipients = useMemo(() => {
		const hasItems = ({groupIds, virtualAssistantIds, companyIds, slackChannels, teamsChannels}) =>
			[groupIds, virtualAssistantIds, companyIds, slackChannels, teamsChannels].some(
				array => array.length > 0
			);

		return hasItems(values.to);
	}, [values.to]);
	const emvValue = useMemo(
		() => formatMoney(collection.posts.map(getPostEmv).reduce((acc, obj) => acc + obj, 0)),
		[collection.posts]
	);
	const debouncedSubmit = useDebounceCallback(submitForm, 200);
	const onChange = useCallback(
		(field, value) => {
			setFieldValue(field, value);

			debouncedSubmit();
		},
		[setFieldValue, debouncedSubmit]
	);
	const handleConfirm = useCallback(async () => {
		const changes = {
			status: "APPROVED",
			...(!values.scheduledFor ? {scheduledFor: dayjs()} : {}),
		};

		await saveCollection({
			variables: {
				id: collection.id,
				changes,
			},
		});

		modal.close();
		onConfirm();
	}, [values.scheduledFor, collection.id, onConfirm, modal, saveCollection]);

	const footer = useMemo(
		() => (
			<div className={styles.footer}>
				<Button onClick={modal.close} value="Cancel" invert color="black" border={false} />
				<Button
					onClick={handleConfirm}
					value="Approve"
					color="blue"
					loading={saving}
					disabled={saving || isSubmitting || !hasRecipients || !collection.posts?.length}
				/>
			</div>
		),
		[modal.close, handleConfirm, isSubmitting, saving, hasRecipients, collection.posts]
	);

	return (
		<Modal
			className={styles.collectionScheduleModal}
			modal={modal}
			title="Post schedule summary"
			size="fit-content"
			header={
				<InputRow>
					<h3>Your Collection is ready to fly!</h3>
					<Icon icon="send" className={styles.sendIcon} />
				</InputRow>
			}
			footer={footer}
		>
			<div className={styles.content}>
				<Span2 color="grey">Review the details below and confirm everything looks right.</Span2>
				<div className={styles.scheduledForCt}>
					<Span3 color="pink">Scheduled for:</Span3>
					<br />
					<CalendarButton
						className={styles.scheduleButton}
						value={values.scheduledFor}
						onChange={value => onChange("scheduledFor", value)}
						formattedValue={
							values.scheduledFor ? values.scheduledFor?.format("M/DD/YYYY h:mm A") : "Immediately"
						}
						size="small"
						icon="schedule"
						emptyValue="Immediately"
						min={dayjs()}
						showClear={true}
						border={false}
						color="grey"
						invert
					/>
				</div>
				<div>
					<Span3 color="pink">Send to:</Span3>
					<br />
					<Recipients onChange={onChange} label={""} />
				</div>
				<Span3 color={collection.posts?.length ? "grey" : "pink"}>
					{collection.posts?.length
						? `${collection.posts.length} Posts - Total EMV: ${emvValue} Saved`
						: "No posts added yet."}
				</Span3>
			</div>
		</Modal>
	);
};
