import React, {
	forwardRef,
	ReactElement,
	useImperativeHandle,
	useRef,
	ClipboardEvent,
	FocusEvent,
	useState,
	useEffect,
	useCallback,
} from "react";
import classnames from "classnames/bind";
import TextareaAutosize from "react-textarea-autosize";

import {HoverTooltip} from "../tooltip";
import {Input, InputComponent, useId, useValidate} from ".";
import {Icon, IconType} from "../images";

import styles from "./input.module.scss";
const bStyles = classnames.bind(styles);

export interface TextProps extends InputComponent<string | undefined, string> {
	type?: "text" | "password" | "textarea" | "number";
	maxLength?: number;
	characterCount?: boolean;
	hideInfo?: boolean;
	placeholder?: string;
	icon?: IconType;
	onFocus?: () => void;
	onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	autoFocus?: boolean;
	bare?: boolean;
	loading?: boolean;
	size?: 1 | 2 | 3 | 4 | 5;
	bold?: boolean;
	minRows?: number;
	withRemove?: boolean;
	withCopy?: boolean;
	currency?: string;
	maxRows?: number;
	error?: string;
}

export const Text = forwardRef(
	(
		{
			autoFocus,
			bare,
			bold,
			characterCount,
			hideInfo,
			disabled,
			icon,
			id: maybeId,
			loading,
			maxLength,
			maxRows,
			minRows = 6,
			onBlur,
			onChange,
			onFocus,
			onKeyDown,
			placeholder,
			size,
			withRemove = true,
			type = "text",
			validate,
			value,
			currency,
			withCopy,
			...props
		}: TextProps,
		ref: React.Ref<HTMLInputElement | HTMLTextAreaElement | null>
	): ReactElement => {
		const id = useId(maybeId);
		const [copied, setCopied] = useState(false);
		const {error: validationError, inputProps} = useValidate(id, value, validate);
		const info = maxLength
			? `${value?.length || 0}/${maxLength}`
			: characterCount
			? `${value?.length || 0}`
			: undefined;
		const inputReference = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

		useImperativeHandle(ref, () => inputReference.current);

		const focusInput = () => {
			inputReference.current?.focus();
		};
		const clear = () => {
			onChange("");
			inputReference.current?.focus();
		};
		const onPaste = (e: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const text = e.clipboardData?.getData("text/plain");

			if (text?.includes("hashtag#")) {
				onChange(text.replaceAll("hashtag#", "#"));
				e.preventDefault();
			}
		};

		const handleCopyText = useCallback(() => {
			navigator.clipboard.writeText(value ?? "");
			setCopied(true);
		}, [value, setCopied]);

		useEffect(() => {
			const timer = setTimeout(() => {
				setCopied(false);
			}, 1000);
			return () => clearTimeout(timer);
		}, [copied]);

		return (
			<Input
				baseClass="text"
				id={id}
				info={hideInfo ? undefined : info}
				disabled={disabled || loading}
				{...inputProps}
				{...props}
			>
				<div
					className={bStyles("textDiv", "overlay", {
						bare,
						inputBorder: !bare,
						error: !!validationError,
						disabled,
					})}
				>
					{currency && <span>{currency}</span>}
					{icon && <Icon icon={icon} className={styles.icon} onClick={focusInput} tabIndex={-1} />}
					{type !== "textarea" ? (
						<input
							ref={inputReference as React.Ref<HTMLInputElement>}
							id={id}
							type={type}
							onChange={e => onChange(e.target.value)}
							onFocus={onFocus}
							autoFocus={autoFocus}
							onBlur={onBlur}
							onKeyDown={onKeyDown}
							onPaste={onPaste}
							value={value ?? ""}
							className={bStyles("text", size && `size${size}`, {bold, disabled})}
							placeholder={placeholder}
							maxLength={maxLength}
							disabled={disabled || loading}
						/>
					) : (
						<TextareaAutosize
							maxRows={maxRows}
							ref={inputReference as React.Ref<HTMLTextAreaElement>}
							id={id}
							onChange={e => onChange(e.target.value)}
							onKeyDown={onKeyDown}
							onFocus={onFocus}
							onBlur={onBlur}
							onPaste={onPaste}
							autoFocus={autoFocus}
							value={value ?? ""}
							className={bStyles("textArea", size && `size${size}`, {bold, disabled})}
							placeholder={placeholder}
							maxLength={maxLength}
							disabled={disabled || loading}
							minRows={minRows}
						/>
					)}
					{withRemove && !bare && value && !disabled && (
						<Icon icon="close" onClick={!loading && clear} className={styles.clear} />
					)}
					{withCopy && !bare && value && disabled && (
						<HoverTooltip text={"Copy"} positions={["top"]}>
							<Icon
								icon={copied ? "check" : "bulkEdit"}
								viewBox={!copied ? "-4 -4 24 24" : ""}
								onClick={handleCopyText}
								className={styles.copy}
							/>
						</HoverTooltip>
					)}
					{loading && (
						<div className={styles.loadingDiv}>
							<img src="/loading.png" />
						</div>
					)}
				</div>
			</Input>
		);
	}
);

Text.displayName = "Text";
