import React, {useCallback, useMemo} from "react";
import {useNavigate} from "react-router";
import dayjs from "dayjs";
import {useFormikContext} from "formik";

import {
	CalendarButton,
	DropdownButton,
	IconOption,
	RichText,
	Text,
	UserSelect,
} from "../../../../components/input";
import {Badge} from "../../../../components/badge";
import {Recipients} from "./recipients";
import {getCollectionStatusBadge} from "../../../collections";
import {Collection, DELETE_COLLECTION} from "../../../../data";
import {CollectionFormValues, OnChange} from "./collection-form";
import {AutoLikeButton} from "./auto-like-button";
import {useConfirmDeleteModal} from "../../../../modals";
import {useMutationToast} from "../../../../toast";
import {DUPLICATE_COLLECTION} from "../../../../data/collection";

import styles from "./styles.module.scss";

interface CollectionSettingsProps {
	collection: Collection;
	onChange: OnChange;
	disabled?: boolean;
}

export const CollectionSettings = ({collection, onChange, disabled}: CollectionSettingsProps) => {
	const navigate = useNavigate();
	const {values} = useFormikContext<CollectionFormValues>();
	const {id, title, owner, scheduledFor, message} = values;
	const statusBadge = getCollectionStatusBadge(collection);
	const [duplicateCollection, {loading: loadingDuplicateCollection}] = useMutationToast(
		DUPLICATE_COLLECTION,
		{variables: {id}}
	);
	const [deleteCollection, {loading: isDeleting}] = useMutationToast(DELETE_COLLECTION, {
		variables: {id},
		onCompleted: () => navigate("/collections"),
	});
	const deleteModal = useConfirmDeleteModal({
		what: title,
		onDelete: () => deleteCollection(),
		deleting: isDeleting,
	});
	const onDuplicate = useCallback(
		() =>
			duplicateCollection().then(
				({data}) => data?.duplicateCollection?.id && navigate(`/collections/${data.duplicateCollection.id}`)
			),
		[duplicateCollection, navigate]
	);

	const options: IconOption[] = useMemo(
		() => [
			{
				label: "Duplicate Collection",
				onClick: onDuplicate,
				disabled,
			},
			{label: "Delete", onClick: deleteModal.open},
		],
		[disabled, deleteModal.open, onDuplicate]
	);

	return (
		<div className={styles.collectionSettings}>
			<div className={styles.ownerStatusContainer}>
				<UserSelect
					className={styles.ownerSelect}
					value={owner}
					name
					onChange={value => onChange("owner", value)}
					roles={["admin"]}
					size="extraSmall"
					menuMaxHeight={300}
				/>
				<div className={styles.buttonsContainer}>
					<Badge className={styles.statusBadge} {...statusBadge} />
					<DropdownButton
						options={options}
						invert
						value="Actions"
						size={"medium"}
						arrow
						loading={loadingDuplicateCollection}
					/>
				</div>
			</div>
			<Text
				className={styles.title}
				placeholder={"Untitled Collection"}
				value={title}
				onChange={value => onChange("title", value)}
				bare
				bold
				size={2}
				maxLength={100}
			/>
			<Recipients onChange={onChange} />
			<RichText
				className={styles.messageInput}
				value={message}
				onChange={value => onChange("message", value)}
				placeholder="Write a message to your recipients. This text will appear in the email body or the Slack message."
				disabled={disabled}
			/>
			<div className={styles.autoLikeScheduledContainer}>
				<CalendarButton
					className={styles.scheduleButton}
					value={scheduledFor}
					formattedValue={scheduledFor ? `Scheduled ${scheduledFor?.format("M/DD/YYYY h:mm A")}` : undefined}
					color="black"
					size="large"
					icon="schedule"
					emptyValue="Not scheduled"
					disabled={disabled}
					min={dayjs().startOf("day")}
					border={false}
					invert
					showClear={true}
					onChange={value => onChange("scheduledFor", value)}
				/>
				<div className={styles.autoLikeBtn}>
					<AutoLikeButton onChange={onChange} />
				</div>
			</div>
		</div>
	);
};
