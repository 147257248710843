import {ReactElement, useCallback, useMemo, useState} from "react";
import {FetchResult, useQuery} from "@apollo/client";
import dayjs, {Dayjs} from "dayjs";
import {useLocalStorage} from "react-use";
import classNames from "classnames";

import {
	Button,
	DropdownButton,
	IconOption,
	InputHeader,
	InputRow,
	Option,
	Select,
	Separator,
	Text,
} from "../../components/input";
import {CalendarMonth} from "./calendar-month";
import {CalendarWeek} from "./calendar-week";
import {CalendarDay} from "./calendar-day";
import {ToggleGroup} from "../../components/toggle";
import {Page} from "../../layout";
import {
	GET_COMPANY_SHARE_EVENTS,
	GET_USER_SHARE_EVENTS,
	OpenGraph,
	useMyUser,
	SAVE_COLLECTION,
	UPDATE_SHARE_EVENT,
} from "../../data";
import {Component} from "../../types";
import {Span1} from "../../components/text";
import {stripHtmlTags} from "../../utils/text";
import {useCompanyPagesList} from "../../data/company";
import {useMutationToast} from "../../toast";
import {GET_SIMPLE_COLLECTION} from "../../data/collection";

import styles from "./schedule.module.scss";

export type CalenderDisplayMode = "month" | "week" | "day";
export type EventType = "collection" | "post";

export interface CalendarItem {
	id: number;
	shareId?: number;
	userId?: number;
	companyId?: number;
	type: EventType;
	posts?: number;
	date: Dayjs;
	opengraph: OpenGraph;
	network?: string;
}

export interface CalendarProps extends Component {
	loading?: boolean;
	selectedDay: Dayjs;
	data: CalendarItem[];
	setDayView: (date: Dayjs) => void;
	updateItem: (item: CalendarItem) => Promise<FetchResult>;
}

export const sortDate = (a: CalendarItem, b: CalendarItem) => a.date.diff(b.date);

const typeOptions: Option<EventType | undefined>[] = [
	{value: undefined, label: "All Content"},
	{value: "collection", label: "Collections"},
	{value: "post", label: "Posts"},
];

const toggleOptions: Option<boolean>[] = [
	{value: false, label: "Personal"},
	{value: true, label: "Company"},
];

const calendarButtons: Option<CalenderDisplayMode>[] = [
	{value: "month", label: "Month"},
	{value: "week", label: "Week"},
	{value: "day", label: "Day"},
];

export const Schedule = (): ReactElement => {
	const user = useMyUser();
	const {companies} = useCompanyPagesList();
	const [companyIds, setCompanyIds] = useState<number[]>([]);
	const [selectedDay, setSelectedDay] = useState<Dayjs>(dayjs());
	const [view, setView] = useState<CalenderDisplayMode>("month");
	const [type, setType] = useState<EventType>();
	const [companyValue, setCompany] = useLocalStorage("schedule-company", false);
	const company = useMemo(() => (user.role === "admin" ? companyValue : false), [companyValue, user.role]);

	const [search, setSearch] = useState("");
	const [shareVariables, collectionVariables] = useMemo(() => {
		const start = selectedDay.startOf("month");
		const end = start.add(1, "month");
		return [
			{start, end, ids: companyIds, sort: "SCHEDULED", limit: 0, status: ["DELIVERED", "SCHEDULED"]},
			{start, end, limit: 0, status: ["APPROVED", "SCHEDULED"]},
		];
	}, [selectedDay, companyIds]);
	const {data, loading: shareEventsLoading} = useQuery(
		company ? GET_COMPANY_SHARE_EVENTS : GET_USER_SHARE_EVENTS,
		{
			variables: shareVariables,
			skip: company && type === "collection",
		}
	);
	const {data: {collections} = {}, loading: collectionsLoading} = useQuery(GET_SIMPLE_COLLECTION, {
		variables: collectionVariables,
		skip: !company || type === "post",
	});
	const loading = useMemo(() => shareEventsLoading || collectionsLoading, [
		shareEventsLoading,
		collectionsLoading,
	]);

	const events = useMemo<CalendarItem[]>(
		() =>
			[
				...(data?.companyShareEvents?.items ?? data?.userShareEvents.items ?? []).map(
					(se): CalendarItem => ({
						date: dayjs(se.scheduledFor),
						id: se.id,
						shareId: se.share.id,
						opengraph: se.share.opengraphs[se.network] || se.share.opengraphs.general,
						type: "post",
						companyId: se.share.companyId,
						userId: se.share.userId,
						network: se.network,
					})
				),
				...(collections?.items ?? []).map(
					(c): CalendarItem => ({
						date: dayjs(c.scheduledFor),
						id: c.id,
						type: "collection",
						userId: c.owner,
						posts: c.postsCount,
						opengraph: {title: c.title, comment: stripHtmlTags(c.message)},
						network: c.network,
					})
				),
			].filter(ci => !search || ci.opengraph.title?.toLowerCase().includes(search.toLowerCase())),
		[collections, data, search]
	);

	const [years] = useState<IconOption[]>(() => {
		const currentYear = dayjs().year();
		return Array.from({length: 26}, (_, index) => ({
			label: (currentYear + index).toString(),
			onClick: () => setSelectedDay(c => c.year(currentYear + index)),
		}));
	});

	const [updateCollection] = useMutationToast(SAVE_COLLECTION);
	const [updateShare] = useMutationToast(UPDATE_SHARE_EVENT);

	const moveDateBack = () => setSelectedDay(c => c.subtract(1, view));
	const moveDateForward = () => setSelectedDay(c => c.add(1, view));

	const dayView = (date: Dayjs) => {
		setView("day");
		setSelectedDay(date);
	};

	const updateItem = useCallback(
		(item: CalendarItem) =>
			item.type === "collection"
				? updateCollection({
						variables: {id: item.id, changes: {scheduledFor: item.date.toISOString()}},
				  })
				: updateShare({
						variables: {id: item.id, scheduledFor: item.date.toISOString()},
				  }),
		[updateCollection, updateShare]
	);

	return (
		<Page title="Calendar" fullWidth>
			<div className={classNames(styles.calendar, styles.sticky)}>
				{user.role === "admin" && (
					<ToggleGroup options={toggleOptions} value={company} onChange={setCompany} />
				)}
				<InputHeader className={styles.header}>
					<InputRow>
						<Button icon="caret-left" invert onClick={moveDateBack} />
						<Span1 bold className={styles.dateViews}>
							{view === "month"
								? selectedDay.format("MMMM")
								: view === "week"
								? `${selectedDay.day(0).format("MMMM D")} - ${selectedDay.day(6).format("MMMM D")}`
								: `${selectedDay.format("MMMM D")}`}
						</Span1>
						<Button icon="caret-right" invert onClick={moveDateForward} />
						<DropdownButton
							options={years}
							value={selectedDay.format("YYYY")}
							invert
							arrow
							border={false}
							color="black"
						/>
					</InputRow>
					<InputRow className={styles.headerRow}>
						<Select label="View:" value={view} onChange={setView} options={calendarButtons} />
						<Separator />
						{company && (
							<>
								<Select label="Type:" value={type} onChange={setType} options={typeOptions} />
								<Separator />
								<Select
									multi
									label="Account:"
									placeholder="All Accounts"
									placeholderColor="black"
									value={companyIds}
									options={companies}
									onChange={setCompanyIds}
								/>
								<Separator />
							</>
						)}
						<Text icon="search" value={search} onChange={setSearch} placeholder="Search" />
					</InputRow>
				</InputHeader>
			</div>
			{view === "month" ? (
				<CalendarMonth
					loading={loading}
					selectedDay={selectedDay}
					data={events}
					updateItem={updateItem}
					setDayView={dayView}
				/>
			) : view === "week" ? (
				<CalendarWeek
					loading={loading}
					selectedDay={selectedDay}
					data={events}
					updateItem={updateItem}
					setDayView={dayView}
				/>
			) : (
				<CalendarDay selectedDay={selectedDay} data={events} updateItem={updateItem} />
			)}
		</Page>
	);
};
