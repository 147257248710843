import {useQuery} from "@apollo/client";
import {useNavigate, useParams} from "react-router-dom";
import dayjs from "dayjs";
import React, {useCallback, useMemo} from "react";

import {DELETE_COLLECTION, GET_FULL_COLLECTION, Service, services} from "../../../../data";
import {UserAvatar} from "../../../../components/user-avatar";
import {Button, DropdownButton, IconOption, InputRow, RichText} from "../../../../components/input";
import {Badge} from "../../../../components/badge";
import {P, P1, Span, Span3} from "../../../../components/text";
import {Icon} from "../../../../components/images";
import {Recepients} from "./recepients";
import {Posts} from "./posts";
import {DUPLICATE_COLLECTION, STOP_ALL_FUTURE_SHARES} from "../../../../data/collection";
import {useMutationToast} from "../../../../toast";
import {usePostSharesModal} from "../../../collections/post-shares-modal";
import {useConfirmModal} from "../../../../modals";

import styles from "./style.module.scss";

export const DeliveredCollection = () => {
	const {id} = useParams();
	const {data} = useQuery(GET_FULL_COLLECTION, {variables: {id: Number(id)}});
	const [duplicateCollection, {loading: loadingDuplicateCollection}] = useMutationToast(
		DUPLICATE_COLLECTION,
		{variables: {id}}
	);
	const collection = useMemo(() => data.collection, [data]);
	const navigate = useNavigate();
	const autoLikeNetworks = useMemo(() => services.filter(n => collection.autoLike[n]) as Service[], [
		collection,
	]);
	const onDuplicate = useCallback(() => {
		duplicateCollection().then(
			({data}) => data?.duplicateCollection?.id && navigate(`/collections/${data.duplicateCollection.id}`)
		);
	}, [duplicateCollection, navigate]);
	const totalShares = useMemo(
		() => collection.posts.flatMap(post => post?.shares ?? []).reduce((ac, cur) => ac + cur.successCount, 0),
		[collection.posts]
	);
	const postSharesModal = usePostSharesModal({posts: collection.posts});
	const onClose = useCallback(() => navigate(`/collections`), [navigate]);
	const [deleteCollection, {loading: isDeleting}] = useMutationToast(DELETE_COLLECTION, {
		variables: {id: collection.id},
		onCompleted: () => navigate("/collections"),
	});
	const deleteModal = useConfirmModal(
		() => ({
			onClose: () => navigate("/collections"),
			onConfirm: () => deleteCollection(),
			confirming: isDeleting,
			confirmColor: "pink",
			confirmText: "Delete",
			title: "Confirm Delete",
			body: <Span>Collection {collection.title} has no posts. Do you want to delete it?</Span>,
		}),
		[isDeleting, deleteCollection, collection, navigate]
	);

	const [stopAllFutureShares, {loading: stoppingShares}] = useMutationToast(STOP_ALL_FUTURE_SHARES, {
		variables: {id: collection.id},
		onCompleted: () => deleteModal.open(),
	});

	const {open: openStopAllSharesModal} = useConfirmModal(
		() => ({
			title: "You’re about to stop future sharing of all post in this Collection",
			body: (
				<div>
					Are you sure you want to permanently disable sharing of all posts? Once disabled, posts will be
					expired and others will no longer be able to share them to social media.{" "}
					<div>Some posts may already have been shared. Disabling will not recall these shares.</div>
				</div>
			),
			confirming: stoppingShares,
			onConfirm: close =>
				stopAllFutureShares().then(() => {
					deleteModal.open();
					close();
				}),
		}),
		[stopAllFutureShares, stoppingShares, deleteModal]
	);

	const options: IconOption[] = useMemo(
		() => [
			{
				label: "Duplicate Collection",
				onClick: onDuplicate,
			},
			{label: "Revoke", onClick: openStopAllSharesModal, disabled: !collection.posts.length},
		],
		[openStopAllSharesModal, onDuplicate, collection.posts]
	);

	return (
		<div className={styles.collectionContainer}>
			<div className={styles.collectionInfo}>
				<InputRow position="between">
					<UserAvatar userId={collection.owner} size="extraSmall" name />
					<div className={styles.buttonsContainer}>
						<Badge className={styles.statusBadge} color="blue" text="Delivered" />
						<DropdownButton
							options={options}
							invert
							value="Actions"
							size={"medium"}
							arrow
							loading={loadingDuplicateCollection}
						/>
					</div>
				</InputRow>
				<div>
					<P1 bold>{collection.title}</P1>
				</div>
				<Recepients collection={collection} />
				<div>
					<RichText
						value={collection.message}
						onChange={() => undefined}
						disabled
						className={styles.collectionMessage}
					/>
				</div>
				<div className={styles.collectionInfoShedule}>
					<div className={styles.scheduleDate}>
						<Icon icon="schedule" width={18} height={18} />
						<P bold>Delivered {dayjs(collection.sent).format("M/DD/YYYY h:mm A")}</P>
					</div>
					<div className={styles.scheduleDate}>
						<Icon icon="like" width={18} height={18} />
						<P bold>Auto-Like All Shared Posts</P>
						{autoLikeNetworks.length ? (
							autoLikeNetworks.map(n => (
								<Icon key={n} icon={`filled-${n}`} width={16} viewBox={"0 0 16 16"} />
							))
						) : (
							<Span3 color="pink">Off</Span3>
						)}
					</div>
				</div>
			</div>
			<Posts collection={collection} />
			<InputRow position="right" className={styles.actions}>
				<Button onClick={postSharesModal.open} invert value={`See Shares (${totalShares})`} />
				<Button onClick={onClose} value="Close" />
			</InputRow>
		</div>
	);
};
