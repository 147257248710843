import React, {useCallback} from "react";
import dayjs from "dayjs";

import {Icon} from "../../../../components/images";
import {accountNames, Share, ShareEvent} from "../../../../data";
import {Span, Span1, Span2, Span3} from "../../../../components/text";
import {Media} from "./media";
import {renderMentions} from "../../../../utils/text";
import {useNetworkPostLink} from "../../../../components/social";

import styles from "./components.module.scss";

interface SharePreviewProps {
	shareEvent: ShareEvent;
	share: Share;
}

export const SharePreview = ({shareEvent, share}: SharePreviewProps) => {
	const openGraph = share.opengraphs[shareEvent.network] ?? share.opengraphs.general;
	const postLink = useNetworkPostLink(shareEvent);

	const onNetworkClick = useCallback(() => {
		if (postLink) window.open(postLink, "_blank");
	}, [postLink]);

	return (
		<div className={styles.sharePreview}>
			<div className={styles.headerContainer}>
				<Span className={styles.network} onClick={onNetworkClick}>
					<Icon icon={`filled-${shareEvent.network}`} width={16} height={16} viewBox={"0 0 16 16"} />
					<Span1 bold>{accountNames[shareEvent.network]}</Span1>
				</Span>
				<div>
					<Span3 color="grey">{`Shared on ${dayjs(shareEvent.scheduledAt).format("M.DD.YY, h:mm a")}`}</Span3>
				</div>
			</div>
			<div className={styles.body}>
				<div className={styles.commentInput}>
					<div className={styles.comment}>
						<Span2>{renderMentions(openGraph.comment)}</Span2>
					</div>
				</div>
				{(openGraph.image || openGraph.video || share.url) && (
					<div className={styles.metadata}>
						{(openGraph.image || openGraph.video) && (
							<Media image={openGraph.image} video={openGraph.video} />
						)}
						{(share.url || openGraph.video) && (
							<div className={styles.info}>
								{share.url && <div className={styles.url}>{new URL(share.url).hostname}</div>}
								<div className={styles.title}>{openGraph.title}</div>
								<div className={styles.description}>{openGraph.description}</div>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
