import {FC, ReactElement, useCallback, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@apollo/client";

import {Modal, ModalData} from "../../modals/new";
import {UserAvatar} from "../../components/user-avatar";
import {FeedPost, HIDE_FEED_POST, HIDE_RSS_FEED_POST} from "../../data/feed";
import {P2, Span, Span1} from "../../components/text";
import {Button, DropdownButton, Separator} from "../../components/input";
import {useAddToCollectionModal} from "../collections/add-to-collection";
import {GET_SHARE, postServices, useMyUser} from "../../data";
import {useConfirmModal} from "../../modals";
import {useMutationToast} from "../../toast";
import {Slider} from "../../components/media/slider";
import {OpenGraphImage} from "../../data/opengraph";

import styles from "./post-preview-modal.module.scss";

interface PostPreviewModalProps {
	modal: ModalData;
	post: FeedPost;
	rss?: boolean;
	postTitle?: ReactElement;
}
export const PostPreviewModal: FC<PostPreviewModalProps> = ({modal, post, rss, postTitle}) => {
	const me = useMyUser();
	const {item} = post || {};
	const {title, description, comment, image, images, video} = item || {};
	const isAdmin = me.role === "admin";
	const {open: openAddToCollection} = useAddToCollectionModal({post, rss});
	const {data, loading} = useQuery(GET_SHARE, {
		variables: {id: post.id},
		skip: !modal.open || !post.id,
	});
	const sliderImages = useMemo<OpenGraphImage[] | null>(() => {
		if (data?.share) {
			const opengraphs = data.share.opengraphs;

			return postServices.reduce((acc, n) => {
				if (opengraphs[n]?.image && !opengraphs[n]?.video && !acc.includes(opengraphs[n].image)) {
					acc.push({url: opengraphs[n].image});
				}

				if (opengraphs[n]?.images) {
					opengraphs[n].images.forEach((image: OpenGraphImage) => {
						const exists = acc.find(({url}) => url === image.url);
						if (!exists) {
							acc.push(image);
						}
					});
				}

				return acc;
			}, [] as OpenGraphImage[]);
		}

		return image && !video ? [{url: image, id: ""}, ...(images ?? [])] : null;
	}, [data, image, video, images]);
	const sliderVideos = useMemo(() => {
		if (data?.share) {
			const opengraphs = data.share.opengraphs;

			return postServices.reduce((acc, n) => {
				if (opengraphs[n]?.video && !acc.includes(opengraphs[n].video)) {
					acc.push(opengraphs[n].video);
				}

				return acc;
			}, [] as string[]);
		}

		return video ? [video] : null;
	}, [data, video]);
	const navigate = useNavigate();
	const [hideSuggestedShare, {loading: loadingHide}] = useMutationToast(HIDE_FEED_POST);
	const [hideRssFeedPost, {loading: loadingHideRssFeedPost}] = useMutationToast(HIDE_RSS_FEED_POST);
	const clicksText = `${post.clickCount} ${post.clickCount === 1 ? "Click" : "Clicks"}`;
	const action =
		post.shareStatus === "scheduled"
			? "Scheduled"
			: post.shareStatus === "shared"
			? !me.org?.options?.blockDuplicatePost
				? "Share Again"
				: null
			: "Share Post";
	const handleSharePersonalPost = useCallback(
		() =>
			post.userId === me.id
				? navigate(`/collections/posts/${post.id}`, {
						state: {
							type: "personal",
							action: post.shareStatus !== "scheduled" ? "share" : "edit",
							id: post.id,
						},
				  })
				: navigate("/collections/posts/new", {state: {id: post.id, type: "personal", rss}}),
		[me.id, navigate, post.id, post?.userId, post.shareStatus, rss]
	);
	const createOptions = useMemo(
		() => [
			{
				label: "Company",
				onClick: () => {
					navigate("/collections/posts/new", {
						state: {id: post.id, type: "company", rss, companyId: post?.company?.id},
					});
				},
			},
			{label: "Personal", onClick: handleSharePersonalPost},
		],
		[navigate, post?.company?.id, post.id, handleSharePersonalPost, rss]
	);

	const hideModal = useConfirmModal(
		() => ({
			title: rss ? "Remove Post?" : "Remove Share?",
			body: rss
				? "If you remove a Post, it will no longer appear in Your Posts."
				: "If you remove a Share, it will no longer appear in Your Shares. Note that this will not remove any posts that may have already been shared to your networks.",
			confirming: loadingHide || loadingHideRssFeedPost,
			onConfirm: close => {
				(rss ? hideRssFeedPost : hideSuggestedShare)({variables: {id: post.id}}).finally(close);
			},
		}),
		[loadingHide, hideSuggestedShare, hideRssFeedPost, post.id, rss, loadingHideRssFeedPost]
	);

	return (
		<Modal modal={modal} size="fit-content" className={styles.postPreviewModal}>
			<div className={styles.content}>
				{(sliderImages || sliderVideos) && (
					<div className={styles.imageContainer}>
						<Slider images={sliderImages} videos={sliderVideos} showIndicator={!loading} />
					</div>
				)}
				<div className={styles.details}>
					<div className={styles.header}>
						{post.userId ? (
							<UserAvatar userId={post.userId} size="small" name={true} className={styles.avatar} />
						) : (
							<Span1 bold>{post.company?.name ?? postTitle}</Span1>
						)}
						<div className={styles.createdAt}>
							{post?.sharedAt
								? `${rss ? "Created" : "Shared"} ${post.sharedAt.fromNow()}`
								: `Recommended ${post.createdAt.fromNow()}`}
						</div>
					</div>
					<Separator horizontal />
					<div className={styles.body}>
						{(comment || description) && <P2>{comment || description}</P2>}
						<P2 href={post.url}>{title ?? post.url}</P2>
					</div>
					<Separator horizontal />
					<div className={styles.toolbar}>
						{isAdmin && !(post.shareStatus === "shared" && me.org?.options?.blockDuplicatePost) && (
							<Button
								key="add-to-collection"
								onClick={openAddToCollection}
								value="Add to Collection"
								icon="add"
								invert
								border={false}
								color="black"
							/>
						)}
						{action &&
							(isAdmin ? (
								<DropdownButton
									key="share-admin"
									value={action}
									icon="post"
									invert
									border={false}
									color="black"
									className={styles.dropdownButton}
									options={createOptions}
								/>
							) : (
								<Button
									key="share-user"
									onClick={handleSharePersonalPost}
									disabled={post.shareStatus === "scheduled"}
									value={action}
									border={false}
									color="black"
									className={styles.dropdownButton}
									icon="post"
									invert
								/>
							))}
						{isAdmin && !rss && (
							<Button
								key="hide"
								onClick={hideModal.open}
								icon="delete"
								invert
								color="black"
								border={false}
								value="Remove Share"
							/>
						)}
						{rss && (
							<Button
								key="hide"
								onClick={hideModal.open}
								icon="delete"
								invert
								color="black"
								border={false}
								value="Remove Post"
							/>
						)}
						{!!post.clickCount && <Span className={styles.clicks}>{clicksText}</Span>}
					</div>
				</div>
			</div>
		</Modal>
	);
};
