import React, {useCallback} from "react";
import {useFormikContext} from "formik";

import {FormValues} from "./types";
import {useConfirmModal} from "../../../../modals";
import {accountNames, PostServices, services, ShareEvent} from "../../../../data";
import {Span1} from "../../../../components/text";
import {Icon} from "../../../../components/images";
import {getShareStatusBadge} from "../../../collections";
import {Badge} from "../../../../components/badge";
import {SmallButton} from "../../../../components/input";
import {HoverTooltip} from "../../../../components/tooltip";

import styles from "./styles.module.scss";

const getErrorCategory = (message = "") => {
	if (message.toLocaleLowerCase().includes("duplicate")) {
		return "Duplicate Post";
	}

	if (message.toLocaleLowerCase().includes("limit")) {
		return "Limit Reached";
	}

	if (message.toLocaleLowerCase().includes("expired")) {
		return "Expired Token";
	}

	if (message.toLocaleLowerCase().includes("restricted")) {
		return "Restricted member";
	}

	if (message.toLocaleLowerCase().includes("verify access token")) {
		return "Unable to verify access token";
	}

	if (message.toLocaleLowerCase().includes("revoked")) {
		return "Revoked token";
	}

	if (
		message.toLocaleLowerCase().includes("unauthorized") ||
		message.toLocaleLowerCase().includes("authenticate")
	) {
		return "Unauthorized";
	}

	if (message.toLocaleLowerCase().includes("blocked")) {
		return "Posting blocked";
	}

	if (message.toLocaleLowerCase().includes("locked")) {
		return "Account locked";
	}

	if (message.toLocaleLowerCase().includes("policy")) {
		return "Policy violation";
	}

	if (message.toLocaleLowerCase().includes("not found")) {
		return "Resource Not Found";
	}

	if (message.toLocaleLowerCase().includes("invalid image")) {
		return "Invalid Image";
	}

	return "Error Sharing this Article";
};

export const EditorHeader = ({
	shareEvent,
	activeNetwork,
}: {
	shareEvent?: ShareEvent;
	activeNetwork: PostServices;
}) => {
	const {values, setFieldValue, setValues} = useFormikContext<FormValues>();
	const {perNetwork, recipient, opengraphs} = values;
	const resetOpengraphs = useCallback(() => {
		const newOpengraphs = {
			general: opengraphs.general,
			...services.reduce((acc, service) => {
				acc[service] = null;
				return acc;
			}, {}),
		};

		setFieldValue(`opengraphs`, newOpengraphs);

		return newOpengraphs;
	}, [opengraphs, setFieldValue]);
	const {open: openEditBulkModal} = useConfirmModal(
		() => ({
			title: "Are you sure you want to make this change?",
			body: <div>Making this change will delete the content in the post. This action cannot be undone.</div>,
			onConfirm: close => {
				const newOpengraphs = resetOpengraphs();
				setValues({
					...values,
					opengraphs: newOpengraphs,
					perNetwork: !perNetwork,
					activeNetwork: "general",
				});
				close();
			},
		}),
		[setValues, values, perNetwork, resetOpengraphs]
	);
	const onEditModeChange = useCallback(() => {
		if (perNetwork && services.some(s => opengraphs[s])) {
			return openEditBulkModal();
		}

		let newOpengraphs;
		if (perNetwork) {
			newOpengraphs = resetOpengraphs();
		}

		setValues({
			...values,
			...(newOpengraphs ? {opengraphs: newOpengraphs} : {}),
			perNetwork: !perNetwork,
			activeNetwork: perNetwork ? "general" : recipient?.networks?.[0],
		});
	}, [openEditBulkModal, setValues, values, perNetwork, recipient?.networks, resetOpengraphs, opengraphs]);

	return (
		<div className={styles.editorHeader}>
			{shareEvent ? (
				<>
					<div className={styles.network}>
						{activeNetwork !== "general" && (
							<Icon icon={`filled-${activeNetwork}`} width={16} height={16} viewBox={"0 0 16 16"} />
						)}
						<Span1 bold>{accountNames[activeNetwork]}</Span1>
					</div>
					<div>
						{shareEvent.sharedAt && !shareEvent.result ? (
							<HoverTooltip text={getErrorCategory(shareEvent?.resultData?.errorMessage)}>
								<Badge {...getShareStatusBadge(shareEvent)} />
							</HoverTooltip>
						) : (
							<Badge {...getShareStatusBadge(shareEvent)} />
						)}
					</div>
				</>
			) : (
				<>
					<Span1 bold className={styles.headerTitle}>
						{perNetwork ? "Individual Network Posts" : "Bulk Post"}
					</Span1>
					<SmallButton
						className={styles.editModeButton}
						IconComponent={
							<Icon
								color="blue"
								icon={perNetwork ? "bulkEdit" : "customizeNetwork"}
								width={16}
								viewBox="0 0 16 16"
							/>
						}
						value={perNetwork ? "Edit all posts in bulk" : "Customize per network"}
						onClick={onEditModeChange}
						color="blue"
						invert
					/>
				</>
			)}
		</div>
	);
};
