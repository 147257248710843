import {ReactElement, useMemo, useState} from "react";

import {DropdownButton, InputRow, Option, Text} from "../../components/input";
import {FeedPost} from ".";
import {FeedPost as FeedPostData, FeedPostType, GET_MY_HUB, inflateFeedPost} from "../../data/feed";
import {HidablePanel} from "../../components/hidable-panel";
import {defaultLoadingProps as loadingProps, usePaginatedQuery} from "../../paginated-query";
import MyHubPanel from "./my-hub-panel";
import {FeedEmptyList} from "./feed-empty-list";
import {useDebounce} from "../../debounce";
import {ToggleGroup} from "../../components/toggle";

import styles from "./feed.module.scss";

interface Filter {
	sort: "clicks" | "newest";
	type: FeedPostType | undefined;
}

const renderItem = (post: FeedPostData) => <FeedPost post={post} key={post.id} comment={post.item.comment} />;

const filterOptions: readonly Option<FeedPostType | undefined>[] = [
	{value: "shared", label: "Shared"},
	{value: "recommended", label: "Recommended"},
];

const empty = <FeedEmptyList text="You haven’t shared anything yet." />;

export const MyHub = (): ReactElement => {
	const [filter, setFilter] = useState<Filter>({sort: "newest", type: "shared"});
	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce(search, 300);
	const sortOptions = useMemo(
		() => [
			{label: "Most Popular", onClick: () => setFilter(c => ({...c, sort: "clicks"}))},
			{label: "Newest First", onClick: () => setFilter(c => ({...c, sort: "newest"}))},
		],
		[]
	);

	const variables = useMemo(
		() => ({
			sort: filter.sort.toUpperCase(),
			search: debouncedSearch,
			type: filter.type ? [filter.type.toUpperCase()] : [],
		}),
		[filter, debouncedSearch]
	);

	const {handleScroll, render} = usePaginatedQuery<FeedPostData>(GET_MY_HUB, {
		loadingProps,
		inflateItem: inflateFeedPost,
		variables,
		renderItem,
		empty,
	});

	const header = (
		<InputRow position="between">
			<ToggleGroup
				value={filter.type}
				options={filterOptions}
				onChange={v => setFilter(c => ({...c, type: v}))}
				disableDot
				className={styles.filterOptions}
			/>
			<InputRow position="right">
				<div className="space">
					<Text value={search} onChange={setSearch} placeholder="Search article" icon="search" />
				</div>
				<DropdownButton icon="sort" invert color="blue" border options={sortOptions} />
			</InputRow>
		</InputRow>
	);

	return (
		<HidablePanel header={header} Panel={MyHubPanel} what="stats" onScroll={handleScroll}>
			{render}
		</HidablePanel>
	);
};
