import {useCallback, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";
import classnames from "classnames";
import dayjs from "dayjs";
import {useLocalStorage} from "react-use";

import {CompanyPost} from "./company-post";
import {PersonalPost, postCookieDeserializer} from "./personal-post";
import {clearTypename, GET_SHARE, loadShare, services, Share, useMyUser} from "../../../data";
import {useToast} from "../../../toast";
import {Span} from "../../../components/text";
import {ContentLibrary} from "../components/library";
import {Button} from "../../../components/input";
import {Icon} from "../../../components/images";
import {Loading} from "../../../components/loading";
import {GET_RSS_FEED_POST} from "../../../data/rssFeed";
import {Page} from "../../../layout";
import {useWindowDeviceSize} from "../../../device-size";

import styles from "./post.module.scss";

const bStyles = classnames.bind(styles);

const clearOpengraphTypename = ogs => {
	if (!ogs) return {};
	const ogsCleared = clearTypename(ogs);
	return Object.keys(ogsCleared).reduce((acc, el) => {
		if (!ogsCleared[el]) return acc;
		const og = {...ogsCleared[el]};
		if (og.images) {
			og.images = og.images.map(el => clearTypename(el));
		}
		return {...acc, [el]: og};
	}, {});
};

export const Post = () => {
	const {id} = useParams<{id: `${number}` | "new"}>();
	const {state} = useLocation();
	const navigate = useNavigate();
	const toast = useToast();
	const windowSize = useWindowDeviceSize();
	const [advanced, setAdvanced] = useLocalStorage("post-builder-advanced", false);
	const [shareFromUrl, setShareFromUrl] = useState<string | undefined>(state?.url);
	const isShareAction = state?.action === "share";
	const isNew = id === "new" || isShareAction;
	const fromPostId = state?.id;
	const rss = state?.rss;
	const me = useMyUser();

	const {data, loading} = useQuery(GET_SHARE, {
		variables: {id},
		fetchPolicy: "cache-first",
		skip: isNew,
		onError: error => toast({color: "red", text: error.message}),
	});
	const {data: parentPost, loading: loadingParentPost} = useQuery(rss ? GET_RSS_FEED_POST : GET_SHARE, {
		variables: {id: fromPostId},
		fetchPolicy: "network-only",
		skip: !fromPostId,
		onError: error => toast({color: "red", text: error.message}),
	});
	const parentPostUrl = useMemo(
		() => (parentPost ? (rss ? parentPost.rssFeedPost?.url : parentPost.share.url) : undefined),
		[parentPost, rss]
	);
	const parentPostOpengraph = useMemo(
		() =>
			parentPost ? (rss ? {general: parentPost.rssFeedPost?.item} : parentPost.share?.opengraphs) : undefined,
		[parentPost, rss]
	);
	const availableNetworks = useMemo(
		() => services.filter(s => me.connections[s === "facebook" ? "facebookPage" : s]?.connected),
		[me.connections]
	);

	const [newPostCookie] = useLocalStorage(`new-post-personal-${me.id}`, null, {
		raw: false,
		serializer: JSON.stringify,
		deserializer: postCookieDeserializer({[me.id]: availableNetworks}),
	});

	const loadedShare = useMemo(
		() =>
			(data?.share && !isShareAction
				? loadShare(data.share)
				: {
						url: parentPostUrl,
						opengraphs: {
							general: {
								comment: "",
							},
							...clearOpengraphTypename(parentPostOpengraph),
						},
						shareEvents: [],
				  }) as Share,
		[data?.share, parentPostOpengraph, parentPostUrl, isShareAction]
	);
	const isExpired = useMemo(() => data?.share?.expiresAt && dayjs(data.share.expiresAt).isBefore(dayjs()), [
		data?.share?.expiresAt,
	]);
	const personal = isNew ? state?.type === "personal" : !!loadedShare.userId;
	const excludedUrls = useMemo(
		() => [loadedShare.url, shareFromUrl, newPostCookie?.url].filter(Boolean) as string[],
		[loadedShare.url, shareFromUrl, newPostCookie?.url]
	);
	const goBack = useCallback(() => navigate("/collections/posts"), [navigate]);
	const isLoading = useMemo(() => loading || (fromPostId ? loadingParentPost : false), [
		loading,
		loadingParentPost,
		fromPostId,
	]);

	return (
		<Page
			className={styles.postPage}
			title={isNew || isLoading ? "Builder" : `${personal ? "Personal" : "Company"} Post`}
			parentPath="/collections"
			{...(isNew
				? {
						tabs: [
							{name: "Collections", path: "/collections/list"},
							{name: "Posts", path: "/collections/posts"},
						],
				  }
				: {returnTo: "/collections/posts"})}
			fullWidth={true}
		>
			<div className={styles.postContainer}>
				{isLoading && <Loading />}
				{!isLoading && (
					<>
						<div className={styles.post}>
							{isNew && (
								<div className={styles.control}>
									<Span className={styles.postHeader}>{`Create a ${
										personal ? "Personal" : "Company"
									} Post`}</Span>
									<Button
										invert
										border={false}
										onClick={() => setAdvanced(!advanced)}
										value={
											<div className={bStyles(styles.advancedBtn, {[styles.advanced]: advanced})}>
												<Icon
													color={"blue"}
													className={styles.advancedBtnIcon}
													icon={advanced ? "chevron-up" : "chevron-down"}
												/>
												<Span className={styles.advancedBtnText}>
													{advanced ? "Simple Builder" : "Advanced Builder"}
												</Span>
											</div>
										}
									/>
								</div>
							)}
							{personal ? (
								<PersonalPost
									share={loadedShare}
									shareFromUrl={shareFromUrl}
									setShareFromUrl={setShareFromUrl}
									onComplete={goBack}
									disabled={isExpired}
									disableContentEditing={
										me.role === "user" &&
										me.org.options?.preventPostEditing &&
										(data?.share?.fromCollection || parentPost?.share?.fromCollection)
									}
								/>
							) : (
								<CompanyPost
									share={loadedShare}
									shareFromUrl={shareFromUrl}
									setShareFromUrl={setShareFromUrl}
									onComplete={goBack}
									disabled={isExpired}
								/>
							)}
						</div>
						{isNew && advanced && (
							<div
								className={bStyles(styles.hidableContainer, {[styles.floating]: windowSize !== "desktop"})}
							>
								<ContentLibrary
									type={"Post"}
									onAdd={({url}) => setShareFromUrl(url)}
									excludedUrls={excludedUrls}
								/>
							</div>
						)}
					</>
				)}
			</div>
		</Page>
	);
};
