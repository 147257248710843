import {gql} from "@apollo/client";

const CANVA_THUMBNAIL_FRAGMENT = gql`
	fragment CanvaThumbnailFields on Thumbnail {
		height
		width
		url
	}
`;

const CANVA_INTEGRATION_FRAGMENT = gql`
	fragment CanvaIntegrationFields on CanvaIntegration {
		connected
		id
		url
	}
`;

const CANVA_ASSET_JOB_FRAGMENT = gql`
	fragment CanvaAssetJobFields on AssetJob {
		id
		status
		errors {
			code
			message
		}
		asset {
			id
			name
			thumbnail {
				...CanvaThumbnailFields
			}
			url
		}
	}
	${CANVA_THUMBNAIL_FRAGMENT}
`;

const CANVA_DESIGN_EXPORT_JOB_FRAGMENT = gql`
	fragment CanvaDesignExportJobFields on ExportJob {
		id
		status
		errors {
			code
			message
		}
		urls
	}
`;

const CANVA_DESIGN_FRAGMENT = gql`
	fragment CanvaDesignFields on CanvaDesign {
		id
		title
		thumbnail {
			...CanvaThumbnailFields
		}
		createdAt
		updatedAt
		urls {
			editUrl
			viewUrl
		}
	}
	${CANVA_THUMBNAIL_FRAGMENT}
`;

export const CANVA_INTEGRATION = gql`
	query CanvaIntegration {
		canvaIntegration {
			...CanvaIntegrationFields
		}
	}
	${CANVA_INTEGRATION_FRAGMENT}
`;

export const DISCONNECT_CANVA = gql`
	mutation DisconnectCanva {
		canvaDisconnect {
			...CanvaIntegrationFields
		}
	}
	${CANVA_INTEGRATION_FRAGMENT}
`;

export const CANVA_DESIGNS = gql`
	query CanvaDesigns($cursor: String, $search: String, $order: CanvaDesignOrder) {
		canvaDesigns(cursor: $cursor, search: $search, order: $order) {
			items {
				...CanvaDesignFields
			}
			cursor
		}
	}
	${CANVA_DESIGN_FRAGMENT}
`;

export const CANVA_DESIGN = gql`
	query CanvaDesign($id: ID!) {
		canvaDesign(id: $id) {
			...CanvaDesignFields
		}
	}
	${CANVA_DESIGN_FRAGMENT}
`;

export const CREATE_CANVA_EXPORT_DESIGN_JOB = gql`
	mutation CreateCanvaDesignExportJob($id: ID!) {
		createCanvaDesignExportJob(id: $id) {
			...CanvaDesignExportJobFields
		}
	}
	${CANVA_DESIGN_EXPORT_JOB_FRAGMENT}
`;

export const GET_CANVA_EXPORT_DESIGN_JOB = gql`
	query ExportDesignJob($id: ID!) {
		exportDesignJob(id: $id) {
			...CanvaDesignExportJobFields
		}
	}
	${CANVA_DESIGN_EXPORT_JOB_FRAGMENT}
`;

export const UPLOAD_IMAGE_TO_CANVA = gql`
	mutation UploadImageToCanva($url: String!, $title: String) {
		uploadImageToCanva(url: $url, title: $title) {
			...CanvaAssetJobFields
		}
	}
	${CANVA_ASSET_JOB_FRAGMENT}
`;

export const GET_ASSET_UPLOAD_JOB = gql`
	query AssetUploadJob($id: ID!) {
		assetUploadJob(id: $id) {
			...CanvaAssetJobFields
		}
	}
	${CANVA_ASSET_JOB_FRAGMENT}
`;

export const CREATE_CANVA_DESIGN = gql`
	mutation CreateCanvaDesign($title: String, $assetId: String!, $width: PositiveInt, $height: PositiveInt) {
		createDesign(title: $title, assetId: $assetId, width: $width, height: $height) {
			...CanvaDesignFields
		}
	}
	${CANVA_DESIGN_FRAGMENT}
`;

export const UPDATE_IMAGE = gql`
	mutation UpdateImage(
		$id: ID!
		$imageUrl: String!
		$designId: ID!
		$imageDestination: ImageDestination!
		$opengraphId: ID!
	) {
		updateImage(
			id: $id
			url: $imageUrl
			designId: $designId
			imageDestination: $imageDestination
			opengraphId: $opengraphId
		)
	}
`;
