import React from "react";
import classNames from "classnames";
import {useLocation} from "react-router-dom";

import {OpenGraph} from "../../../../data";
import {Icon} from "../../../../components/images";
import {Loading} from "../../../../components/loading";
import {useNewModal, NewModal as Modal} from "../../../../modals";
import {CanvaButton} from "./canva";

import styles from "./components.module.scss";

interface MediaProps {
	image?: OpenGraph["image"];
	video?: OpenGraph["video"];
	width?: number;
	loadingMedia?: boolean;
	height?: number;
	border?: boolean;
	onRemove?: () => void;
	disabled?: boolean;
	canvaButton?: {
		opengraphId: undefined | number;
		canvaId?: string;
		opengraphNetwork?: string;
		shuffledCommentId?: string;
		imageId?: string;
		imageDestination?: "OPENGRAPH" | "CANVA_LIBRARY" | "SHUFFLED_IMAGE" | "MULTI_IMAGE";
	};
}
export const Media = ({
	image,
	video,
	width = 128,
	height = 96,
	border,
	canvaButton,
	onRemove,
	loadingMedia,
	disabled,
}: MediaProps) => {
	const {state} = useLocation();
	const {preview, canvaImageUrl} = state ?? {};
	const {open, modal} = useNewModal({size: "medium", defaultOpen: preview && image === canvaImageUrl});
	return (
		<div
			className={classNames(styles.media, (border || loadingMedia) && styles.border)}
			style={{width, height}}
		>
			{loadingMedia ? (
				<Loading position="absolute" size="small" />
			) : (
				<>
					{image && <img onClick={open} src={image} alt="Post Image" />}
					{video && !image && (
						<video src={video} controls={true} muted preload="metadata">
							<source src={video} />
							<track kind="captions" srcLang="en" label="english_captions" />
						</video>
					)}
					{image && !video && canvaButton && (
						<div className={styles.canvaBtn}>
							<CanvaButton
								image={image}
								imageDestination={canvaButton?.imageDestination ?? "OPENGRAPH"}
								opengraphId={canvaButton.opengraphId}
								imageId={canvaButton.imageId}
								opengraphNetwork={canvaButton.opengraphNetwork}
								canvaId={canvaButton.canvaId}
								shuffledCommentId={canvaButton.shuffledCommentId}
							/>
						</div>
					)}
					{onRemove && !disabled && (
						<div className={styles.removeBtn} onClick={onRemove}>
							<Icon icon="close" width={24} color="white" />
						</div>
					)}
				</>
			)}
			{image && (
				<Modal modal={modal}>
					<div className={styles.previewContainer}>
						{canvaButton && (
							<div className={styles.canvaBtn}>
								<CanvaButton
									image={image}
									preview={true}
									imageDestination={canvaButton?.imageDestination ?? "OPENGRAPH"}
									opengraphId={canvaButton?.opengraphId}
									imageId={canvaButton?.imageId}
									opengraphNetwork={canvaButton?.opengraphNetwork}
									canvaId={canvaButton?.canvaId}
									shuffledCommentId={canvaButton?.shuffledCommentId}
								/>
							</div>
						)}
						<img className={styles.preview} src={image} alt="Post Image" />
					</div>
				</Modal>
			)}
			{video && (
				<Modal modal={modal}>
					<video className={styles.preview} src={video} controls={true} muted preload="metadata">
						<source src={video} />
						<track kind="captions" srcLang="en" label="english_captions" />
					</video>
				</Modal>
			)}
		</div>
	);
};
