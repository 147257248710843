import {Fragment, useCallback, useMemo, useState} from "react";
import classNames from "classnames";

import {Dot, Icon} from "../../../../components/images";
import {P, P1, P2, Span, Span2, Span3, Span4} from "../../../../components/text";
import {
	Collection,
	getPostEmv,
	PostServices,
	postServices,
	Post as PostType,
	useCategories,
	DELETE_POST,
} from "../../../../data";
import {formatMoney} from "../../../../utils/number";
import {Button, Separator} from "../../../../components/input";
import {IconToggleGroup} from "../../../../components/toggle";
import {ShuffleCommentsToolbarComponent} from "../form/toolbars";
import {Media} from "../../components";
import {renderMentions} from "../../../../utils/text";
import {useMutationToast} from "../../../../toast";
import {useConfirmRevokeModal} from "../../../../modals";
import {HoverTooltip} from "../../../../components/tooltip";

import styles from "./style.module.scss";

const iconMapper = {general: "email"};

const Post = ({post, collectionId}: {post: PostType; collectionId: number}) => {
	const [isOpen, setIsOpen] = useState(false);
	const setOpen = useCallback(() => setIsOpen(true), [setIsOpen]);
	const setClosed = useCallback(() => setIsOpen(false), [setIsOpen]);
	const [captionIndex, setCaptionIndex] = useState(1);
	const {url, keywords} = post;
	const [activeNetwork, setActiveNetwork] = useState<PostServices>("general");

	//TODO: implement showing keywords if they exist
	const emvValue = useMemo(
		() =>
			new Intl.NumberFormat("en-US", {
				style: "currency",
				currency: "USD",
			}).format(getPostEmv({url, keywords} as PostType)),
		[url, keywords]
	);
	const perNetwork = post.opengraphs && Object.values(post.opengraphs).filter(e => e).length > 2;
	const hasShuffledContent = !!(post.shuffledComments.length || post.shuffledImages.length);
	const toggleOptions = postServices?.map(s => ({value: s, icon: iconMapper[s] || s}));
	const onNetworkChange = useCallback((network: PostServices) => setActiveNetwork(network), [
		setActiveNetwork,
	]);
	const opengraph = post.opengraphs?.[activeNetwork] ?? post.opengraphs?.general ?? {};
	const {image, video, title, comment, description, images} = opengraph;
	const activeComment =
		hasShuffledContent && captionIndex !== 1 ? post?.shuffledComments[captionIndex - 2]?.comment : comment;

	const [deletePost, {loading: deleting}] = useMutationToast(DELETE_POST);
	const handleDelete = useCallback(
		(close: () => void) =>
			deletePost({
				variables: {id: post.id, collectionId: collectionId},
			}).then(() => {
				close();
			}),
		[deletePost, post.id, collectionId]
	);
	const {open: confirmRevoke} = useConfirmRevokeModal({
		what: "post",
		onConfirm: handleDelete,
		confirming: deleting,
	});

	const onDeleteClick = useCallback(() => confirmRevoke(), [confirmRevoke]);

	if (isOpen) {
		return (
			<>
				<div className={styles.post}>
					<div className={styles.postTop}>
						<P1 bold>
							{perNetwork
								? "Individual Network Posts"
								: hasShuffledContent
								? "Shuffle Content Post"
								: "Bulk Post"}
						</P1>
						<div className={styles.collapseBtn} onClick={setClosed}>
							<Icon icon="chevron-up" color="black" />
						</div>
					</div>
					<div className={styles.postBottom}>
						{perNetwork && (
							<div>
								<IconToggleGroup
									className={styles.toggleNetwork}
									options={toggleOptions}
									value={activeNetwork}
									onChange={onNetworkChange}
								/>
							</div>
						)}
						{!!post.shuffledComments.length && (
							<ShuffleCommentsToolbarComponent
								shuffledCommentIndex={captionIndex}
								setShuffledCommentIndex={setCaptionIndex}
								shuffledComments={post.shuffledComments}
							/>
						)}

						{activeComment && <P2 className={styles.bulkComment}>{renderMentions(activeComment)}</P2>}
						{post.url ? (
							<div className={styles.urlBox}>
								<div>
									{image && (
										<img src={image} alt="Post Image" style={{height: 96, width: 128, objectFit: "cover"}} />
									)}
									{!image && video && (
										<video
											src={video}
											controls={true}
											muted
											preload="metadata"
											style={{height: 96, width: 128, objectFit: "cover"}}
										>
											<source src={video} />
											<track kind="captions" srcLang="en" label="english_captions" />
										</video>
									)}
								</div>
								<div className={styles.text}>
									<a href={post.url} className={styles.postLink} target="_blank" rel="noreferrer">
										{new URL(post.url).hostname}
									</a>
									<Span bold className={styles.title}>
										{title}
									</Span>
									<Span4 className={styles.description}>{description}</Span4>
								</div>
							</div>
						) : image ? (
							post.shuffledImages.length ? (
								<div className={styles.shuffledImages}>
									{[{image: image}, ...post.shuffledImages].map((si, index) => (
										<Media key={`${index}-${si.image}`} image={si.image} width={96} height={72} />
									))}
								</div>
							) : (
								<div className={styles.shuffledImages}>
									{[{url: image, id: ""}, ...(images ?? [])].map((img, index) => (
										<Media key={`${index}-${img}`} image={img.url} width={96} height={72} />
									))}
								</div>
							)
						) : !image && video ? (
							<video
								src={video}
								controls={true}
								muted
								preload="metadata"
								style={{height: 96, width: 128, objectFit: "cover"}}
							>
								<source src={video} />
								<track kind="captions" srcLang="en" label="english_captions" />
							</video>
						) : null}
						<div className={styles.revoke}>
							<HoverTooltip text={"Revoke Post"} positions={["top"]}>
								<Button
									onClick={onDeleteClick}
									icon="delete"
									value=""
									color="black"
									invert
									border={false}
									loading={deleting}
								/>
							</HoverTooltip>
						</div>
					</div>
				</div>
				{url && (
					<div className={styles.emv}>
						<Icon color="grey" icon="information" />
						<Span>EMV: {emvValue} Saved</Span>
					</div>
				)}
			</>
		);
	}
	return (
		<div className={classNames(styles.post, styles.closed)}>
			{(image || video) && (
				<div className={styles.media}>
					{image && <img src={image} alt="Post Image" style={{height: 60}} />}
					{!image && video && (
						<video src={video} controls={true} muted preload="metadata" style={{height: 60}}>
							<source src={video} />
							<track kind="captions" srcLang="en" label="english_captions" />
						</video>
					)}
				</div>
			)}
			<div className={styles.text}>
				<Span2 bold className={styles.title}>
					{title}
				</Span2>
				<Span3 className={styles.comment}>{renderMentions(comment || description)}</Span3>
			</div>
			<div className={styles.collapseBtn} onClick={setOpen}>
				<Icon icon="chevron-down" color="black" />
			</div>
		</div>
	);
};

export const Posts = ({collection}: {collection: Collection}) => {
	const emvValue = useMemo(
		() => formatMoney(collection.posts.map(getPostEmv).reduce((acc, obj) => acc + obj, 0)),
		[collection.posts]
	);
	const allCategories = useCategories({});
	const categories = useMemo<string[]>(
		() =>
			collection?.categories?.map(id => {
				const cat = allCategories.find(c => c.id === id);
				if (cat) return cat.name;
				return "";
			}) ?? [],
		[collection, allCategories]
	);
	const categorisedPosts = useMemo(() => {
		if (collection.categories) {
			return collection.categories.map(cat => collection.posts.filter(post => post.categoryId === cat));
		}
		return [];
	}, [collection]);
	const nonCategorisedPosts = useMemo(() => collection.posts.filter(post => !post.categoryId), [collection]);

	return (
		<div className={styles.posts}>
			<div className={styles.postData}>
				<P color="grey">
					Total Posts: <span className={styles.black}>{collection.posts.length}</span>
				</P>
				<Dot height={4} color="grey-400" />
				<P color="grey">
					Total EMV: <span className={styles.black}>{emvValue}</span>
				</P>
			</div>
			{categories?.map((c, i) => (
				<Fragment key={`${c}-${i}`}>
					{i !== 0 && <Separator className={styles.noMargin} horizontal />}
					<h3>
						{c} ({categorisedPosts[i].length})
					</h3>
					<div className={styles.postGroup}>
						{categorisedPosts[i].map(post => (
							<Post key={post.id} post={post} collectionId={collection.id} />
						))}
					</div>
				</Fragment>
			))}
			{nonCategorisedPosts.length !== 0 && (
				<div className={styles.postGroup}>
					{nonCategorisedPosts.map(post => (
						<Post key={post.id} post={post} collectionId={collection.id} />
					))}
				</div>
			)}
		</div>
	);
};
