import {FC, useCallback, useEffect, useState} from "react";
import {Portal} from "react-portal";
import {autoUpdate, flip, offset, shift, useFloating} from "@floating-ui/react-dom";
import classnames from "classnames";

import {Alerter} from "../../components/alerter";
import {Span2, Span4} from "../../components/text";
import {UPDATE_USER_FEED} from "../../data/rssFeed";
import {RSSFeed} from "./explore";
import {useMutationToast} from "../../toast";
import {Loading} from "../../components/loading";

import styles from "./auto-queue-modal.module.scss";

const daysOfWeek = [
	{label: "Mo", value: 1},
	{label: "Tu", value: 2},
	{label: "We", value: 3},
	{label: "Th", value: 4},
	{label: "Fr", value: 5},
];

interface AutoQueueModalProps {
	feed?: RSSFeed;
	isOpen: boolean;
	onClose: (updated?: boolean) => void;
	reference?: HTMLDivElement;
}

export const AutoQueueModal: FC<AutoQueueModalProps> = ({isOpen, onClose, feed, reference}) => {
	const {refs, floatingStyles} = useFloating({
		placement: "top",
		whileElementsMounted: autoUpdate,
		elements: {reference},
		middleware: [flip({padding: 20, fallbackAxisSideDirection: "end"}), shift(), offset(10)],
	});
	const [updateUserFeed, {loading: updating}] = useMutationToast(UPDATE_USER_FEED);
	const [selected, setSelected] = useState<number[]>([]);
	const onClick = useCallback(
		day => setSelected(days => (days.includes(day) ? days.filter(d => d !== day) : [...days, day])),
		[]
	);
	const onOutsideClick = useCallback(async () => {
		if (
			selected.some(d => !feed?.dayOfWeek?.includes(d)) ||
			feed?.dayOfWeek?.some(d => !selected.includes(d))
		) {
			await updateUserFeed({
				variables: {
					changes: {
						dayOfWeek: selected,
					},
					id: feed?.bookmarkId,
				},
			});
			onClose(true);
		} else {
			onClose();
		}
	}, [onClose, selected, feed, updateUserFeed]);
	useEffect(() => {
		setSelected(
			feed?.autoFeed ? feed?.dayOfWeek?.filter(d => daysOfWeek.find(dow => dow.value === d)) ?? [] : []
		);
	}, [feed?.autoFeed, feed?.dayOfWeek]);

	if (!isOpen) return null;

	return (
		<Portal>
			<div className={styles.autoQueueModal} ref={refs.setFloating} style={floatingStyles}>
				<Alerter onOutsideClick={onOutsideClick} className={styles.content}>
					{updating && <Loading className={styles.loader} />}
					<div className={styles.title}>Auto-Collection</div>
					<Span2>Weekly send day(s):</Span2>
					<div className={styles.weekContainer}>
						{daysOfWeek.map(item => (
							<div
								key={item.label}
								className={classnames(styles.day, selected.includes(item.value) && styles.selected)}
								onClick={() => onClick(item.value)}
							>
								{item.label}
							</div>
						))}
					</div>
					<Span4>This will send Auto-Collection to you each week on the selected days.</Span4>
				</Alerter>
			</div>
		</Portal>
	);
};
