import React, {useCallback, useEffect, useMemo, useState} from "react";
import classNames from "classnames";
import {useLocation, useNavigate} from "react-router-dom";
import dayjs from "dayjs";

import {Button, InputRow, required, Select, Separator, Text} from "../../components/input";
import {Modal, useModal} from "../../modals/new";
import {useConfirmModal} from "../../modals";
import {CREATE_CONTEST, Contest, GET_CONTESTS, UPDATE_CONTEST} from "../../data/contest";
import {useMutationToast} from "../../toast";
import {Span, Span1, Span4} from "../../components/text";
import {Icon} from "../../components/images";
import {UserAvatar} from "../../components/user-avatar";
import {usePaginatedQuery} from "../../paginated-query";
import {usePageScroll} from "../../layout";
import {Role, useAllGroups, useMyUser, useSimpleUsers} from "../../data";
import {
	DateIntervalPicker,
	useCustomRange,
	DateIntervalType,
} from "../../components/input/date-time/date-interval-picker";

import styles from "./contests.module.scss";

const minDate = dayjs().add(-10, "year").startOf("year");

type AddContestModalProps = {
	close: () => void;
	contest?: Contest | null;
	setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const kpiDrivers = [
	{value: "smart_score", label: "Social Score"},
	{value: "emv", label: "EMV"},
	{value: "shared_articles", label: "Shares"},
	{value: "clicks", label: "Clicks"},
];

const validateNumber = ({min, max, required = true}: {min?: number; max?: number; required?: boolean}) => ({
	required,
	check: value => {
		const num = Number(value ?? 0);
		const minVal = min ?? Number.MIN_SAFE_INTEGER;
		const maxVal = max ?? Number.MAX_SAFE_INTEGER;
		if (!value && required) return "You must enter a value.";
		if (num > maxVal || num < minVal) return "Not a valid value";
	},
});

export const AddContestModal: React.FC<AddContestModalProps> = ({close, contest, setIsDirty}) => {
	const [contestName, setContestName] = useState<string>(contest?.name ?? "");
	const initialStartDate = contest?.startDate ? dayjs(contest.startDate) : dayjs().startOf("day");
	const initialEndDate = contest?.endDate ? dayjs(contest.endDate) : dayjs().add(7, "day").startOf("day");
	const [dateRange, setDateRange] = useState<DateIntervalType>([initialStartDate, initialEndDate]);
	const [kpiDriver, setKpiDriver] = useState(contest?.kpiDriver.toString() ?? "smart_score");
	const [group, setGroup] = useState<number>(Number(contest?.group ?? 0));
	const [milestone, setMilestone] = useState(contest?.milestone.toString() ?? "120");
	const [winners, setWinners] = useState(contest?.winners.toString() ?? "5");
	const [prize, setPrize] = useState(contest?.prize.toString() ?? "100");
	const [createContest, {loading}] = useMutationToast(CREATE_CONTEST);
	const [updateContest, {loading: updateLoading}] = useMutationToast(UPDATE_CONTEST);
	const dateInterval = useCustomRange(dateRange, minDate);
	const {start, end} = dateInterval || {};
	const finnished = dayjs().isAfter(contest?.endDate);

	const onAddContest = useCallback(() => {
		createContest({
			variables: {
				name: contestName,
				startDate: start?.startOf("day")?.toISOString(),
				endDate: end?.endOf("day")?.toISOString(),
				kpiDriver: kpiDriver,
				milestone: parseFloat(milestone),
				winners: parseInt(winners),
				prize: parseFloat(prize),
				timezone: dayjs.tz.guess(),
				group: `${group}`,
			},
			onCompleted: close,
		});
	}, [contestName, start, end, kpiDriver, milestone, winners, prize, close, createContest, group]);
	const confirmUpdateModal = useConfirmModal(
		() => ({
			title: `Update Contest`,
			body: `
				Are you sure you want to update the "${contest?.name}" settings?
			`,
			confirmColor: "blue",
			confirmText: "Confirm",
			onConfirm: () => {
				confirmUpdateModal.close();
				updateContest({
					variables: {
						id: contest?.id,
						...(contest?.name !== contestName && {name: contestName}),
						...(finnished || start?.isSame(dayjs(contest?.startDate), "day")
							? {}
							: {
									startDate: start?.startOf("day").toISOString(),
							  }),
						...(finnished || end?.isSame(dayjs(contest?.endDate), "day")
							? {}
							: {
									endDate: end?.endOf("day").toISOString(),
							  }),
						...(contest?.kpiDriver !== kpiDriver && {kpiDriver}),
						...(contest?.milestone !== parseFloat(milestone) && {milestone: parseFloat(milestone)}),
						...(contest?.winners !== parseInt(winners) && {winners: parseInt(winners)}),
						...(contest?.prize !== parseFloat(prize) && {prize: parseFloat(prize)}),
						...(contest?.group !== String(group) && {group: `${group}`}),
					},
					onCompleted: close,
				});
			},
		}),
		[
			updateContest,
			contestName,
			start,
			end,
			kpiDriver,
			milestone,
			winners,
			prize,
			close,
			contest,
			group,
			finnished,
		]
	);
	const onUpdate = useCallback(() => {
		if (
			contest?.name !== contestName ||
			!start?.isSame(dayjs(contest?.startDate), "day") ||
			!end?.isSame(dayjs(contest?.endDate), "day") ||
			contest?.kpiDriver !== kpiDriver ||
			contest?.milestone !== parseFloat(milestone) ||
			contest?.winners !== parseInt(winners) ||
			contest?.prize !== parseFloat(prize) ||
			contest?.group !== String(group)
		) {
			confirmUpdateModal.open();
		} else {
			close();
		}
	}, [
		contestName,
		start,
		end,
		kpiDriver,
		milestone,
		winners,
		prize,
		close,
		contest,
		group,
		confirmUpdateModal,
	]);

	const {groups, loading: loadingGroups} = useAllGroups();
	useEffect(() => {
		if (groups && group === 0) {
			const everyoneId = groups.find(g => g.label === "Everyone")?.value;
			setGroup(everyoneId ?? 0);
		}
	}, [groups, group]);

	useEffect(() => {
		setIsDirty?.(
			Boolean(
				(contestName && contestName !== contest?.name.toString()) ||
					kpiDriver !== (contest?.kpiDriver.toString() ?? "smart_score") ||
					milestone !== (contest?.milestone.toString() ?? "120") ||
					winners !== (contest?.winners.toString() ?? "5") ||
					prize !== (contest?.prize.toString() ?? "100") ||
					!start?.isSame(dayjs(initialStartDate), "day") ||
					!end?.isSame(dayjs(initialEndDate), "day") ||
					(group !== Number(groups.find(g => g.label === "Everyone")?.value ?? 0) &&
						group !== Number(contest?.group ?? 0))
			)
		);
	}, [
		contestName,
		kpiDriver,
		milestone,
		winners,
		prize,
		start,
		end,
		group,
		groups,
		contest?.name,
		contest?.kpiDriver,
		contest?.milestone,
		contest?.winners,
		contest?.prize,
		initialStartDate,
		initialEndDate,
		contest?.group,
		setIsDirty,
	]);

	const hasError = useMemo(() => {
		if (!contestName) return true;
		if (Number(milestone) < 0) return true;
		if (Number(winners) < 1) return true;
		if (Number(prize) < 0) return true;
		return !contestName || !start || !end || !kpiDriver || !milestone || !winners || !prize || !group;
	}, [contestName, start, end, kpiDriver, milestone, winners, prize, group]);
	const onWinnersKeyDown = e => {
		if ([",", "."].includes(e.key)) {
			e.stopPropagation();
			e.preventDefault();
		}
	};

	return (
		<>
			<Text
				value={contestName}
				onChange={setContestName}
				validate={required}
				placeholder="Contest Name"
				label="Contest Name"
				maxLength={100}
				autoFocus
			/>
			{!finnished && (
				<div className="space">
					<label className={styles.label}> Start/End Dates</label>
					<DateIntervalPicker
						min={start?.isAfter(dayjs()) ? dayjs() : start || dayjs()}
						value={dateRange}
						onChange={v => setDateRange(v)}
					/>
				</div>
			)}
			<div className={classNames(styles.contestValues, "space")}>
				<Select
					className={styles.leftInput}
					label="KPI driver"
					options={kpiDrivers}
					value={kpiDriver}
					onChange={v => setKpiDriver(v)}
				/>
				<div className={styles.rightInput}>
					<Text
						label="Milestone to reach"
						value={milestone}
						type="number"
						validate={validateNumber({min: 0})}
						onChange={v => Number(v) >= 0 && Number(v) <= 999999.99 && setMilestone(v)}
						withRemove={false}
					/>
				</div>
			</div>
			<div className={classNames(styles.contestValues, "space")}>
				<div className={styles.leftInput}>
					<Text
						currency={"$"}
						type="number"
						className={styles.prize}
						label="Prize"
						value={prize}
						onChange={v => Number(v) >= 0 && Number(v) <= 999999.99 && setPrize(v)}
						validate={validateNumber({min: 0})}
						withRemove={false}
					/>
				</div>
				<div className={styles.rightInput}>
					<Text
						label="Winners"
						value={winners}
						type="number"
						validate={validateNumber({min: 1})}
						onKeyDown={onWinnersKeyDown}
						onChange={v => Number(v) >= 0 && Number(v) <= 999999999 && setWinners(v)}
						withRemove={false}
					/>
				</div>
			</div>
			<div className="space">
				<label className={styles.label}>Groups</label>
				<Select loading={loadingGroups} options={groups} value={group} onChange={setGroup} />
			</div>

			<Separator horizontal />
			<InputRow position="right">
				<Button value="Cancel" invert border={false} onClick={close} />
				{contest ? (
					<Button value="Update" loading={updateLoading} onClick={onUpdate} />
				) : (
					<Button value="Add Contest" loading={loading} onClick={onAddContest} disabled={hasError} />
				)}
			</InputRow>
		</>
	);
};

const Contests: React.FC = () => {
	const navigate = useNavigate();
	const me = useMyUser();
	const isAdmin = useMemo(() => me.role === "admin", [me.role]);
	const [contest, setContest] = useState<Contest | null>(null);
	const [owner, setOwner] = useState<string | null>("0");
	const [select, setSelect] = useState<string>("all");
	const [dateInterval, setDateInterval] = useState<DateIntervalType | undefined>(undefined);
	const dateRange = useCustomRange(dateInterval || "Max", minDate);
	const {start = dayjs(), end = dayjs()} = dateRange || {};
	const {users} = useSimpleUsers({
		limit: null,
		filter: {roles: ["admin"].map(e => e.toUpperCase()) as Role[], isContestOwner: true},
	});
	const userSelect = useMemo(
		() => [{value: "0", label: "All owners"}, ...users.map(u => ({value: `${u.id}`, label: u.fullName}))],
		[users]
	);
	const variables = useMemo(
		() => ({
			filter: {
				status: select === "all" ? undefined : select.toUpperCase(),
				owner: users.find(user => user.id === Number(owner)) ? owner : undefined,
				startDate: dateInterval ? start.startOf("day").toISOString() : undefined,
				endDate: dateInterval ? end.startOf("day").toISOString() : undefined,
			},
		}),
		[select, owner, users, dateInterval, start, end]
	);
	const {state} = useLocation();
	const isNew = state?.new;

	const {modal, open, close, setIsDirty} = useModal({});

	useEffect(() => {
		if (isNew) {
			open();
			navigate("/contests");
		}
	}, [isNew, navigate, open]);

	const onAddContest = useCallback(() => {
		setContest(null);
		open();
	}, [open]);
	const onEditContest = useCallback(
		contest => {
			setContest(contest);
			open();
		},
		[open]
	);

	const ContestItem = useCallback(
		contest => {
			const now = dayjs();
			const startDate = dayjs(contest.startDate);
			const endDate = dayjs(contest.endDate);
			const finnished = now > endDate;
			const completed = contest.complete;
			const totalContestants = contest.totalContestants;
			const users = finnished
				? totalContestants > contest.winners
					? contest.winners
					: totalContestants
				: totalContestants;

			const winners = contest.winners;
			const fullName = contest.owner.firstName + " " + contest.owner.lastName;
			const totalAvatars = Math.min(finnished ? winners : Number.MAX_SAFE_INTEGER, totalContestants);
			const inProgress = now > startDate && now < endDate;
			return (
				<div
					key={contest.id}
					className={classNames(
						styles.contest,
						completed && styles.finnished,
						inProgress && styles.inProgress
					)}
					onClick={() => {
						navigate(`/contests/${contest.id}`);
					}}
				>
					<h3>{contest.name}</h3>
					<Span>
						{startDate.format("MMM D, YYYY")} - {endDate.format("MMM D, YYYY")}
					</Span>
					<Span4 color="grey">Created by {fullName}</Span4>
					{inProgress ? (
						<Span color="pink">In progress</Span>
					) : now > endDate ? (
						completed ? (
							<Span>Completed</Span>
						) : (
							<Span color="blue">Gifts Ready!</Span>
						)
					) : (
						<Span color="blue">Upcoming</Span>
					)}
					<div className={styles.kpiAchieved}>
						<Icon icon="star" color="pink" viewBox="0 0 17 15" width={16} height={16} />
						<Span1>
							{contest.milestone} {kpiDrivers.find(({value}) => value === contest.kpiDriver)?.label}
						</Span1>
					</div>
					<Span color="grey">
						{!finnished
							? `${users} user${users === 1 ? "" : "s"} qualifying`
							: `${users > 0 ? users : "No"} user${users === 1 ? "" : "s"} won`}
					</Span>
					<div className={styles.avatars}>
						{contest?.contestantsPreview
							.slice(0, totalAvatars === 8 ? 8 : Math.min(totalAvatars, 7))
							.map(({id}) => (
								<UserAvatar key={`user-${id}`} userId={id} size="extraSmall" className={styles.avatar} />
							))}
						{totalAvatars > 8 && (
							<div className={classNames(styles.andMore, styles.avatar)}>
								<b>+{totalAvatars - 7}</b>
							</div>
						)}
					</div>
					{!completed && !finnished && isAdmin && (
						<button
							className={styles.edit}
							onClick={e => {
								e.stopPropagation();
								onEditContest(contest);
							}}
						>
							<Icon width={16} height={16} icon="edit" />
						</button>
					)}
				</div>
			);
		},
		[onEditContest, navigate, isAdmin]
	);

	const empty = useMemo(() => <div className={styles.empty}>No contests found</div>, []);

	const {handleScroll, render} = usePaginatedQuery(GET_CONTESTS, {
		variables,
		renderItem: ContestItem,
		empty,
	});
	usePageScroll(handleScroll);

	return (
		<div className={styles.contests}>
			<Modal modal={modal} title={`${contest ? "Edit" : "Add a"} Contest`}>
				<AddContestModal contest={contest} close={close} setIsDirty={setIsDirty} />
			</Modal>
			<div className={styles.header}>
				<div className={styles.leftHeader}>
					<div>{isAdmin && <Button icon="add" value="Add Contest" onClick={onAddContest} />}</div>
				</div>
				<div className={styles.rightHeader}>
					<div>
						<Select options={userSelect} value={owner} onChange={setOwner} />
					</div>
					<div>
						<Select
							options={[
								{value: "all", label: "All Contests"},
								{value: "active", label: "In Progress"},
								{value: "gifts_ready", label: "Gifts Ready"},
								{value: "completed", label: "Completed"},
							]}
							value={select}
							onChange={setSelect}
						/>
					</div>
					{isAdmin && (
						<div>
							<DateIntervalPicker
								min={minDate}
								value={dateInterval}
								onChange={setDateInterval}
								labelText={!dateInterval ? "All Time" : undefined}
								showClear
							/>
						</div>
					)}
				</div>
			</div>
			<div className={styles.content}>{render}</div>
		</div>
	);
};

export default Contests;
