import {KeyboardEvent, Key, ReactElement, useCallback, useMemo} from "react";
import classnames from "classnames/bind";

import {useStateRef} from "../../state-ref";
import {Input, SingleOptionProps, MultiOptionProps, ValidationCheck, toggle, useId, useValidate} from ".";
import {DropdownCallback, useDropdown} from "./dropdown";
import {Arrow, Icon} from "../images";
import {Pill} from "../pill";
import {Span} from "../text";
import {Loading} from "../loading";
import {ColorWithGrey} from "../../types";

import styles from "./input.module.scss";

const bStyles = classnames.bind(styles);

interface SingleSelect<T> extends SingleOptionProps<T> {
	multi?: false | null | undefined;
	pills?: never;
	placeholder?: string;
	loading?: boolean;
	placeholderColor?: ColorWithGrey;
}
interface MultiSelect<T> extends MultiOptionProps<T> {
	multi: true;
	pills?: boolean;
	placeholder?: string;
	placeholderColor?: ColorWithGrey;
	loading?: boolean;
}

export type SelectProps<T> = MultiSelect<T> | SingleSelect<T>;

export function Select<T = string>({
	disabled,
	loading,
	id: maybeId,
	multi,
	onChange,
	options,
	pills,
	placeholder,
	placeholderColor,
	validate,
	value,
	...props
}: SelectProps<T>): ReactElement {
	const id = useId(maybeId);
	const [activeIndex, setActiveIndex, aiRef] = useStateRef<number | undefined>(undefined);
	const {error, inputProps, update} = useValidate<T | T[]>(
		id,
		value,
		validate as ValidationCheck<T | T[]> | undefined
	);
	const handleUnset = useCallback(() => setActiveIndex(undefined), [setActiveIndex]);

	const [valueArray, selected, available] = useMemo(() => {
		const valueArray = multi ? value : [value];
		const selected = options.filter(o => valueArray.includes(o.value));
		const available = pills ? options.filter(o => !valueArray.includes(o.value)) : options;
		return [valueArray, selected, available];
	}, [multi, options, pills, value]);

	const handleChange = useCallback(
		(v: T, close: () => void) => {
			if (!multi) {
				close();
				onChange(v);
				update();
				return;
			}
			onChange(toggle(v, value));
			update();
		},
		[multi, onChange, update, value]
	);

	const handleKeyDown = useCallback(
		(e: KeyboardEvent<HTMLDivElement>, {close}: DropdownCallback) => {
			switch (e.key) {
				case "Enter":
				case " ":
					if (aiRef.current !== undefined) {
						handleChange(available[aiRef.current].value, close);
						if (multi) setActiveIndex(c => ((c || 1) % available.length) - 1);
					}
					break;
				case "ArrowDown":
					setActiveIndex(c => ((c ?? -1) + 1) % available.length);
					break;
				case "ArrowUp":
					setActiveIndex(c => ((c ?? available.length + 1) - 1 + available.length) % available.length);
					break;
				default:
					return;
			}
			e.stopPropagation();
			e.preventDefault();
		},
		[aiRef, available, handleChange, multi, setActiveIndex]
	);

	const removeAll = () => {
		multi && onChange([]);
	};

	const popup = useCallback(
		({close}: DropdownCallback) =>
			loading ? (
				<Loading position="center" />
			) : (
				available.map((o, i) => (
					<div
						className={bStyles("option", {
							active: i === activeIndex,
							selected: valueArray.includes(o.value),
							disabled: o.disabled,
						})}
						key={o.value as Key}
						tabIndex={i === activeIndex ? 0 : -1}
						onMouseOver={() => setActiveIndex(i)}
						onClick={e => {
							e.stopPropagation();
							if (o.disabled) return;
							handleChange(o.value, close);
						}}
					>
						<Span>{o.label}</Span>
					</div>
				))
			),

		[activeIndex, available, handleChange, setActiveIndex, valueArray, loading]
	);

	const {close, isOpen, portal, reference, toggle: toggleDropdown} = useDropdown({
		popup,
		portalClassName: styles.menu,
		onClose: handleUnset,
		onKeyDown: handleKeyDown,
		onMouseOut: handleUnset,
	});

	return (
		<Input baseClass="select" disabled={disabled} id={id} {...props} {...inputProps}>
			<div
				onClick={disabled ? undefined : toggleDropdown}
				ref={reference}
				className={bStyles("select", "inputBorder", {error, pillSelect: pills, open: isOpen, disabled})}
				tabIndex={disabled ? -1 : 0}
			>
				{!selected.length ? (
					<Span color={placeholderColor ? placeholderColor : "grey"} trim={1}>
						{placeholder}
					</Span>
				) : pills ? (
					<div className={styles.pillsContainer}>
						<div className={styles.labels}>
							{selected.map(label => (
								<Pill
									key={label.value as Key}
									color="blue"
									text={label.label}
									onDelete={() => handleChange(label.value, close)}
								/>
							))}
						</div>
						{multi && <Icon icon="close" className={styles.closeIcon} onClick={removeAll} />}
					</div>
				) : (
					<Span trim={1}>{selected.map(o => o.label).join(", ")}</Span>
				)}
				<Arrow direction={isOpen ? "up" : "down"} />
				{portal}
			</div>
		</Input>
	);
}
