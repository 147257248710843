import {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import classNames from "classnames";

import {Icon} from "../images";
import {OpenGraphImage} from "../../data/opengraph";

import styles from "./slider.module.scss";

interface SliderProps {
	images: OpenGraphImage[] | null;
	videos?: string[] | null;
	showIndicator?: boolean;
}

export const Slider = ({images, videos, showIndicator}: SliderProps): ReactElement => {
	const [activeMediaIndex, setActiveMediaIndex] = useState(0);
	const mediaItems = useMemo(() => [...(images ?? []), ...(videos ?? [])], [images, videos]);
	const multiMedia = mediaItems.length > 1;
	const onPrevClick = useCallback(
		() =>
			setActiveMediaIndex(activeMediaIndex =>
				activeMediaIndex < 1 ? mediaItems.length - 1 : activeMediaIndex - 1
			),
		[mediaItems]
	);
	const onNextClick = useCallback(
		() =>
			setActiveMediaIndex(activeMediaIndex =>
				activeMediaIndex === mediaItems.length - 1 ? 0 : activeMediaIndex + 1
			),
		[mediaItems]
	);

	useEffect(() => {
		const handleKeyDown = e => {
			switch (e.key) {
				case "ArrowLeft":
				case "ArrowUp":
					onPrevClick();
					break;
				case "ArrowRight":
				case "ArrowDown":
					onNextClick();
					break;
				default:
					return;
			}

			e.stopPropagation();
			e.preventDefault();
		};

		window.addEventListener("keydown", handleKeyDown);

		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [onPrevClick, onNextClick]);

	return (
		<div className={styles.slider}>
			{multiMedia && (
				<div className={classNames(styles.sliderBtn, styles.left)} onClick={onPrevClick}>
					<Icon icon={"chevron-up"} />
				</div>
			)}
			{images?.map((img, i) => (
				<div
					key={`img-${i}`}
					className={classNames(styles.slideImage, {[styles.active]: i === activeMediaIndex})}
				>
					<img src={img.url || ""} alt={""} />
				</div>
			))}
			{videos?.map((video, i) => (
				<div
					key={`video-${i}`}
					className={classNames(styles.slideImage, {[styles.active]: i === activeMediaIndex})}
				>
					<video src={video} controls={true} muted preload="metadata">
						<source src={video} />
						<track kind="captions" srcLang="en" label="english_captions" />
					</video>
				</div>
			))}
			{multiMedia && (
				<div className={classNames(styles.sliderBtn, styles.right)} onClick={onNextClick}>
					<Icon icon={"chevron-up"} />
				</div>
			)}
			{multiMedia && showIndicator && (
				<div className={styles.slideIndicator}>
					{mediaItems.map((_, i) => (
						<div
							key={i}
							className={classNames(styles.dot, {[styles.active]: i === activeMediaIndex})}
							onClick={() => setActiveMediaIndex(i)}
						>
							<div />
						</div>
					))}
				</div>
			)}
		</div>
	);
};
