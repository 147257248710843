import {FC, useMemo} from "react";
import {useQuery} from "@apollo/client";
import classnames from "classnames";
import dayjs from "dayjs";

import {useCustomRange} from "../../../components/input/date-time/date-interval-picker";
import {Card} from "../../../components/card";
import {P, Span, Span2, Span3, Span4} from "../../../components/text";
import {Loading} from "../../../components/loading";
import {EmployeeAdvocacyFilter, minDate} from "./index";
import {EmptyList} from "../../../components/empty-list";
import {GET_POPULAR_POSTS, PopularPost} from "../../../data/analytics";
import {OpengraphMedia} from "../../../components/opengraph";
import {Icon} from "../../../components/images";
import {UserPopularPostsModal} from "./user-popular-posts-modal";
import {Service} from "../../../data";
import {useModal} from "../../../modals/new";

import styles from "./employee-advocacy.module.scss";

const LIMIT = 5;

export const PopularPostsCard: FC<{filter: EmployeeAdvocacyFilter}> = ({filter}) => {
	const dateRange = useCustomRange(filter.dateRange || "Max", minDate);
	const {start = dayjs(), end = dayjs()} = dateRange || {};
	const {open, modal} = useModal({});
	const variables = useMemo(
		() => ({
			start: start.isBefore(minDate) ? minDate : start,
			end: end.isBefore(minDate) ? minDate : end,
			groups: filter.groups?.length ? filter.groups : undefined,
			network: filter.network,
			limit: LIMIT,
		}),
		[filter?.groups, filter?.network, start, end]
	);
	const {data, loading} = useQuery(GET_POPULAR_POSTS, {
		variables,
		skip: modal.open,
	});
	const posts = useMemo(() => data?.popularPosts?.items || [], [data?.popularPosts]) as PopularPost[];

	return (
		<>
			<Card className={styles.card}>
				<div className={styles.cardHeader}>
					<div className={styles.sameLine}>
						<h3>User Popular Posts</h3>
						<Span4 color={"grey"}>{`${start.formatAs("shortDate")} - ${end.formatAs("shortDate")}`}</Span4>
					</div>
					{!!posts?.length && <Span color={"blue"} onClick={open} bold>{`See all posts`}</Span>}
				</div>
				<div className={styles.postsTable}>
					{loading ? (
						<Loading className={styles.loader} position={"center"} />
					) : posts?.length ? (
						posts.slice(0, 5).map((post, index) => (
							<div
								key={`${index}-${post.openGraph?.title?.slice(0, 5)}`}
								className={classnames(styles.post, "space")}
							>
								{post.openGraph.image || post.openGraph.video ? (
									<OpengraphMedia
										className={styles.image}
										openGraph={post.openGraph}
										width={64}
										height={50}
									/>
								) : (
									<div className={styles.image}>
										<img src="/default-image.png" alt="" />
									</div>
								)}
								<div className={styles.text}>
									<div className={styles.postHeader}>
										<Icon
											viewBox={"0 0 16 16"}
											width={16}
											height={16}
											icon={`filled-${post.network as Service}`}
										/>
										<P title={post.openGraph.title} bold href={post.url} trim={1}>
											{post.openGraph.title}
										</P>
									</div>
									{(post.openGraph.comment || post.openGraph.description) && (
										<Span4 title={post.openGraph.comment || post.openGraph.description} trim={2}>
											{post.openGraph.comment || post.openGraph.description}
										</Span4>
									)}
								</div>
								<div className={styles.postStats}>
									<Span2 bold>{post.clicks}</Span2>
									<Span3 color="grey">Clicks</Span3>
								</div>
								<div className={styles.postStats}>
									<Span2 bold>{post.shares}</Span2>
									<Span3 color="grey">Shares</Span3>
								</div>
								<div className={styles.postStats}>
									<Span2 bold color="blue">
										${post.emv}
									</Span2>
									<Span3 color="grey">EMV</Span3>
								</div>
							</div>
						))
					) : (
						<EmptyList className={styles.emptyMsg} text={"No popular posts found for this time period."} />
					)}
				</div>
			</Card>
			<UserPopularPostsModal filter={filter} modal={modal} />
		</>
	);
};
