import {useCallback, useMemo, useState} from "react";
import classNames from "classnames";
import {Breathing} from "react-shimmer";
import {useLocation} from "react-router-dom";
import {useLocalStorage} from "react-use";

import {Button, Checkbox, DropdownButton, InputRow, Text} from "../input";
import {CANVA_DESIGNS} from "../../data/canva";
import {Span, Span2} from "../text";
import {useDebounce} from "../../debounce";
import {usePaginatedQuery} from "../../paginated-query";
import {Icon} from "../images";
import {useMyUser} from "../../data";

import styles from "./canva-media-selector.module.scss";

const Shimmers = (fill: number) =>
	Array(fill)
		.fill(null)
		.map((m, i) => <Breathing key={i} className={styles.shimmerImg} />);

const encodeCorrelationState = (stringifiedState: string) => btoa(stringifiedState);

function CanvaMediaSelector({
	close,
	onSelect,
	opengraphId,
}: {
	close: () => void;
	onSelect: (id: string) => void;
	opengraphId?: number;
}) {
	const [search, setSearch] = useState("");
	const [order, setOrder] = useState<string>();
	const [selected, setSelected] = useState<string>();
	const me = useMyUser();
	const [, setReturnState] = useLocalStorage(`canva-return-state-${me.id}`);

	const onInput = useCallback(
		val => {
			setSelected(undefined);
			setSearch(val);
		},
		[setSearch]
	);
	const onSort = useCallback(
		val => {
			setSelected(undefined);
			setOrder(val);
		},
		[setOrder]
	);

	const debouncedSearch = useDebounce(search, 300);
	const toggleItem = useCallback(
		(id: string) => {
			if (selected === id) setSelected(undefined);
			else setSelected(id);
		},
		[selected]
	);

	const {state, pathname} = useLocation();
	const setValues = useCallback(
		designId =>
			setReturnState({
				originPage: pathname,
				designId,
				...(pathname === "/collections/posts/new" ? {newPostType: state?.type ?? "company"} : {}),
				...(opengraphId ? {opengraphId} : {}),
				imageDestination: "CANVA_LIBRARY",
			}),
		[pathname, opengraphId, state, setReturnState]
	);

	const getEditUrl = useCallback(
		item => {
			const url = new URL(item.urls.editUrl);
			setValues(item.id);
			url.searchParams.append("correlation_state", encodeCorrelationState(JSON.stringify({user_id: me.id})));
			window.open(url, "_self");
		},
		[setValues, me.id]
	);

	const renderItem = useCallback(
		item => (
			<div
				key={item.id}
				className={classNames(styles.mediaItem, selected === item.id && styles.selected)}
				onClick={() => {
					toggleItem(item.id);
				}}
			>
				<div
					className={styles.imageContainer}
					tabIndex={0}
					onKeyDown={e => {
						if (e.key === "Enter" || e.key === " ") {
							e.preventDefault();
							toggleItem(item.id);
						}
					}}
				>
					{item.thumbnail ? (
						<img src={item.thumbnail.url} className={styles.image} alt={item.title} />
					) : (
						<Icon className={styles.image} icon="canvaEmpty" />
					)}
					<Checkbox
						className={styles.checkbox}
						value={selected === item.id}
						onChange={() => {
							if (item.id === selected) setSelected(undefined);
							else setSelected(item.id);
						}}
					/>
					<div onClick={() => getEditUrl(item)} className={styles.canvaBtn}>
						<Icon icon={"canva"} width={24} />
					</div>
				</div>

				<Span className={styles.itemTitle} bold>
					{item.title ?? "Untitled design"}
				</Span>
			</div>
		),
		[selected, toggleItem, getEditUrl]
	);
	const variables = useMemo(() => ({search: debouncedSearch, order: order?.toUpperCase()}), [
		debouncedSearch,
		order,
	]);
	const emptyMessage = useMemo(
		() => (
			<Span2 className={styles.empty}>
				{search.trim().length < 3 && search.trim().length > 0
					? "Please enter at least 3 characters to begin your search."
					: "No designs found"}
			</Span2>
		),
		[search]
	);

	const {render, handleScroll} = usePaginatedQuery(CANVA_DESIGNS, {
		renderItem,
		loadingProps: {position: "center"},
		variables,
		loading: <>{Shimmers(25)}</>,
		empty: emptyMessage,
	});

	const onContinue = useCallback(() => {
		if (selected) {
			onSelect(selected);
			close();
		}
	}, [selected, onSelect, close]);

	return (
		<>
			<div className={styles.modalCanva} onScroll={handleScroll}>
				<InputRow position="left" className={styles.topRow}>
					<div className={classNames(styles.search, "space")}>
						<Text icon="search" placeholder="Search file" value={search} onChange={onInput} condensed />
					</div>
					<DropdownButton
						arrow
						invert
						options={[
							{label: "Most relevant", icon: "enhance", onClick: () => onSort("relevant")},
							{label: "Newest edit", icon: "schedule", onClick: () => onSort("updated_asc")},
							{label: "Oldest edit", icon: "schedule", onClick: () => onSort("updated_desc")},
							{label: "Alphabetical (A-Z)", icon: "chevron-up", onClick: () => onSort("alphabetical_asc")},
							{label: "Alphabetical (Z-A)", icon: "chevron-down", onClick: () => onSort("alphabetical_desc")},
						]}
						value="Sort by"
					/>
				</InputRow>
				<div className={styles.minHeight}>
					<div className={styles.mediaContainer}>{render}</div>
				</div>
				<InputRow className={styles.bottomRow}>
					<Button value="Cancel" invert border={false} onClick={close} />
					<Button value="Continue" disabled={!selected} onClick={onContinue} />
				</InputRow>
			</div>
		</>
	);
}

export default CanvaMediaSelector;
