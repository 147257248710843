import classnames from "classnames";
import React, {useCallback, useRef, ChangeEvent, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {Placement} from "@floating-ui/react-dom";

import {Icon} from "../images";
import {Button, InputRow} from "../input";
import {DropdownCallback, useDropdown} from "../input/dropdown";
import {MediaLibraryModalArgs, useMediaLibraryModal} from "../../modals/media/media-library";
import {Span2} from "../text";
import {Modal, useModal} from "../../modals/new";
import CanvaMediaSelector from "./canva-media-selector";
import {HoverTooltip} from "../tooltip";
import {CANVA_INTEGRATION} from "../../data/canva";
import {Loading} from "../loading";

import styles from "./media-button.module.scss";

export function MediaButton<T>({
	ButtonComponent,
	mediaLibrary,
	onFileChange,
	onCanvaSelect,
	loading,
	opengraphId,
	canvaDefaultOpen,
	dropdownPlacement,
	disabled,
	multiple,
}: {
	ButtonComponent?: React.FunctionComponent<{isOpen?: boolean}>;
	mediaLibrary: MediaLibraryModalArgs<T>;
	onFileChange: (file: File[]) => void;
	loading?: boolean;
	onCanvaSelect: (id: string) => void;
	opengraphId?: number;
	canvaDefaultOpen?: boolean;
	dropdownPlacement?: Placement;
	disabled?: boolean;
	multiple?: boolean;
}) {
	const navigate = useNavigate();
	const inputRef = useRef<HTMLInputElement>(null);
	const {data: canvaIntegrationData, loading: loadingCanvaIntegration} = useQuery(CANVA_INTEGRATION);
	const connected = useMemo(() => canvaIntegrationData?.canvaIntegration?.connected ?? false, [
		canvaIntegrationData,
	]);
	const mediaLibraryModal = useMediaLibraryModal(mediaLibrary);
	const {open: openCanva, close, modal} = useModal({
		size: "large",
		maxWidth: "936px",
		padding: "0",
		defaultOpen: canvaDefaultOpen,
	});

	const handleOnClick = useCallback(() => inputRef.current?.click(), []);
	const handleFileChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			if (!e.target.files) {
				return;
			}

			onFileChange(Array.from(e.target.files));

			if (inputRef?.current) {
				inputRef.current.value = "";
			}
		},
		[onFileChange]
	);

	const popup = useCallback(
		({close}: DropdownCallback) => (
			<div>
				<div
					className={styles.menuItem}
					onClick={() => {
						close();
						mediaLibraryModal.open();
					}}
				>
					<Icon icon="photo-library" />
					<Span2>Media Library</Span2>
				</div>
				<div className={styles.menuItem} onClick={handleOnClick}>
					<Icon icon="upload" />
					<Span2>Upload Image</Span2>
				</div>
				<div
					className={classnames(styles.menuItem, loadingCanvaIntegration && styles.disabled)}
					onClick={() => {
						if (!connected) {
							navigate("/settings/integrations");
							return;
						}
						close();
						openCanva();
					}}
				>
					{loadingCanvaIntegration && <Loading size="small" position="absolute" />}
					<Icon icon="canva" />
					<Span2>Import from Canva</Span2>
				</div>
			</div>
		),

		[handleOnClick, mediaLibraryModal, openCanva, connected, loadingCanvaIntegration, navigate]
	);
	const {portal, reference, open, isOpen} = useDropdown({
		popup,
		portalClassName: styles.menu,
		placement: dropdownPlacement,
	});

	return (
		<>
			{portal}

			<input
				accept={`image/*`}
				type="file"
				multiple={multiple}
				ref={inputRef}
				onChange={handleFileChange}
				className={styles.inputFile}
			/>
			<Modal
				modal={modal}
				className={styles.canvaMediaModal}
				header={
					<InputRow position="between" className={styles.canvaModalTitle}>
						<div className={styles.canvaMediaHeader}>
							<img src={"/svgs/canva.svg"} />
							<h2>Import from Canva</h2>
						</div>
						<Icon icon="close" onClick={close} />
					</InputRow>
				}
			>
				<CanvaMediaSelector opengraphId={opengraphId} close={close} onSelect={onCanvaSelect} />
			</Modal>
			<div className={"space"}>
				<HoverTooltip text={"Select Media"} positions={["top"]}>
					{ButtonComponent ? (
						<div ref={reference} onClick={!disabled ? open : undefined}>
							<ButtonComponent isOpen={isOpen} />
						</div>
					) : (
						<Button
							ref={reference}
							icon="photo"
							loading={loading}
							color="black"
							invert
							border={false}
							onClick={open}
							disabled={disabled}
						/>
					)}
				</HoverTooltip>
			</div>
		</>
	);
}
