import {ReactElement, useCallback, useMemo, useState} from "react";
import {NetworkStatus} from "@apollo/client";

import {defaultLoadingProps as loadingProps, usePaginatedQuery} from "../../paginated-query";
import {Icon} from "../../components/images";
import {useDebounce} from "../../debounce";
import {DropdownButton, InputRow, Select, Text} from "../../components/input";
import {CompanyCard} from "../../components/company-card";
import {FindOutWhoCard} from "./find-out-who-card";
import {LeaderboardWidget} from "../../components/leaderboard-widget";
import {useCompanyList, useMyUser} from "../../data";
import {HidablePanel} from "../../components/hidable-panel";
import {FeedPost} from ".";
import {FeedPost as FeedPostType, GET_COMPANY_SHARES, inflateFeedPost} from "../../data/feed";
import {CompanyFeedEmptyList} from "./feed-empty-list";

import styles from "./feed.module.scss";

interface Filter {
	sort: "clicks" | "newest";
	ids: number[];
	type?: "shared" | "recommended";
}

export const Company = (): ReactElement => {
	const companies = useCompanyList();
	const me = useMyUser();
	const hasCompanyStats = useMemo(() => me.org.options?.companyStats || me.role === "admin", [
		me.org.options,
		me.role,
	]);
	const [filter, setFilter] = useState<Filter>({sort: "newest", ids: [], type: "shared"});
	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce(search, 300);

	const renderPost = useCallback(
		(post: FeedPostType) => <FeedPost post={post} key={post.id} comment={post.item.comment} />,
		[]
	);

	const empty = useMemo(
		() =>
			debouncedSearch ? (
				<div className={styles.filterEmpty}>
					<Icon icon="feed" height={114} />
					<h3>No posts match your filters.</h3>
				</div>
			) : (
				<CompanyFeedEmptyList />
			),
		[debouncedSearch]
	);
	const variables = useMemo(() => ({...filter, sort: filter.sort.toUpperCase(), search: debouncedSearch}), [
		filter,
		debouncedSearch,
	]);

	const {handleScroll, render, data, networkStatus} = usePaginatedQuery<FeedPostType>(GET_COMPANY_SHARES, {
		loadingProps,
		inflateItem: inflateFeedPost,
		variables,
		renderItem: renderPost,
		empty,
	});

	const isEmptyList = data.length === 0 && networkStatus === NetworkStatus.ready;

	const Panel = useCallback(
		() => (
			<>
				{hasCompanyStats && <CompanyCard disabled={me.role !== "admin"} />}
				{me.org.options.leaderboard && <LeaderboardWidget />}
				<FindOutWhoCard />
			</>
		),
		[hasCompanyStats, me.role, me.org.options.leaderboard]
	);
	const header = (
		<InputRow position="right">
			<div className="space">
				<Text value={search} onChange={setSearch} placeholder="Search article" icon="search" />
			</div>
			<Select
				options={companies}
				value={filter.ids}
				onChange={ids => setFilter(c => ({...c, ids}))}
				placeholder="All company posts"
				multi
			/>
			{!isEmptyList && (
				<DropdownButton
					icon="sort"
					invert
					color="blue"
					border
					options={[
						{label: "Most Popular", onClick: () => setFilter(c => ({...c, sort: "clicks"}))},
						{label: "Newest First", onClick: () => setFilter(c => ({...c, sort: "newest"}))},
					]}
				/>
			)}
		</InputRow>
	);

	return (
		<HidablePanel header={header} Panel={Panel} what="stats" onScroll={handleScroll}>
			{render}
		</HidablePanel>
	);
};
