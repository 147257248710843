import {FC, ReactElement, UIEvent, useCallback, useMemo, useState} from "react";
import classnames from "classnames/bind";
import {Argument} from "classnames";

import {Component} from "../../types";
import {Button, InputRow} from "../input";
import {useWindowDeviceSize} from "../../device-size";

import styles from "./hidable-panel.module.scss";
const bStyles = classnames.bind(styles);

export interface HidingProps {
	open: () => void;
	close: () => void;
	isOpen: boolean;
	maybeClose: () => void;
	handleMaybeClose: (fn: () => void) => void;
}

export interface HidablePanelProps extends Component {
	header?: ReactElement;
	Panel: (props: HidingProps) => ReactElement;
	what: string;
	childClassName?: Argument;
	onScroll?: (e: UIEvent<HTMLDivElement>) => void;
}

export const HidablePanel: FC<HidablePanelProps> = ({
	childClassName,
	children,
	className,
	header,
	onScroll,
	Panel,
	what,
}) => {
	const [isOpen, setIsOpen] = useState<boolean>();
	const close = () => setIsOpen(false);
	const open = () => setIsOpen(true);
	const windowSize = useWindowDeviceSize();

	const isOpenMemo = useMemo(
		() => (isOpen !== undefined ? isOpen : windowSize === "desktop" ? true : false),
		[isOpen, windowSize]
	);
	const toggleOpen = useCallback(() => setIsOpen(() => !isOpenMemo), [isOpenMemo, setIsOpen]);

	const maybeClose = () =>
		setIsOpen(c => {
			if (!c) return false;
			if (window.innerWidth <= 767) return false;
			return true;
		});

	const handleMaybeClose = fn => (...args) => {
		maybeClose();
		return fn(...args);
	};

	const button = useMemo(
		() => (
			<Button
				color="blue"
				invert
				border={false}
				value={`${isOpenMemo ? "Hide" : "Show"} ${what}`}
				onClick={toggleOpen}
			/>
		),
		[isOpenMemo, toggleOpen, what]
	);

	return (
		<div className={bStyles(className, "container", {closed: !isOpenMemo, hasHeader: !!header})}>
			{isOpenMemo && windowSize === "desktop" ? (
				<>
					{header && <div>{header}</div>}
					<div className={styles.show}>{button}</div>
				</>
			) : (
				<InputRow position="right" className={styles.headerCt}>
					<div className={bStyles("headerRow", "space")}>{header}</div>
					{button}
				</InputRow>
			)}
			<div onScroll={onScroll} className={bStyles("child", childClassName, "space")}>
				{children}
			</div>
			<div className={bStyles("panel", {hidden: !isOpenMemo})}>
				{Panel({isOpen: isOpenMemo, close, open, maybeClose, handleMaybeClose})}
			</div>
		</div>
	);
};
