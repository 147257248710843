import React, {ReactElement, useCallback, useMemo} from "react";
import Select, {
	GroupBase,
	MultiValue,
	MultiValueProps,
	OptionProps,
	SingleValue,
	components,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import classnames from "classnames";

import {Pill} from "../pill";
import {Icon} from "../images";
import {GroupedOptions, Option} from ".";
import {Color} from "../../types";

import styles from "./input.module.scss";

const lightBlue = "#0997D9";
const darkBlue = "#056490";
const white = "#FFFFFF";
const gray300 = "#DCE0E7";
const gray200 = "#EEF1F5";

interface SearchableSelectProps<T> {
	isMulti?: boolean;
	name?: string;
	options: readonly (GroupedOptions<T> | Option<T>)[];
	className?: string;
	bare?: boolean;
	label?: string;
	pillColor?: Color;
	placeholder?: string;
	value?: Option<T>[];
	onChange?: (value: MultiValue<Option<T>> | SingleValue<Option<T>>) => void;
	disabled?: boolean;
	onAdd?: (value: string) => void;
	disableDropdownIndicator?: boolean;
	isSearchable?: boolean;
}
const OptionReactSelect = components.Option;

const OptionCustom = <T,>(props: OptionProps<Option<T>, boolean, GroupBase<Option<T>>>) => {
	const {label, icon} = props.data;
	return (
		<OptionReactSelect {...props}>
			{icon && <Icon icon={icon} />}
			{label}
		</OptionReactSelect>
	);
};

function SearchableSelect<T>({
	isMulti,
	name,
	options,
	className,
	bare,
	label,
	placeholder,
	disabled,
	value,
	onChange,
	pillColor,
	onAdd,
	disableDropdownIndicator,
	isSearchable,
}: SearchableSelectProps<T>): ReactElement {
	const SelectComponent = useMemo(() => (onAdd ? CreatableSelect : Select), [onAdd]);
	const sortedValues = useMemo(
		() => value?.sort((a: Option<T>, b: Option<T>) => a.label.localeCompare(b.label)),
		[value]
	);
	const Container = !label ? React.Fragment : "div";
	const handleChange = useCallback(
		(value: MultiValue<Option<T>> | SingleValue<Option<T>>) => {
			onChange?.(value);
		},
		[onChange]
	);
	const getNoOptionsMessage = useCallback(
		({inputValue}) =>
			value?.some((value: Option<T>) => value.label.toLowerCase().includes(inputValue.toLowerCase().trim()))
				? "Already Selected"
				: "No matches found",
		[value]
	);
	const getTheme = useCallback(
		theme => ({
			...theme,
			borderRadius: 15,
			colors: {
				...theme.colors,
				neutral30: bare ? "transparent" : "#DCE0E7",
				primary25: "#eef1f5",
				primary: bare ? "transparent" : "#DCE0E7",
				danger: "#FFF",
				dangerLight: "rgba(255, 255, 255, 0.35)",
				primary50: "#eef1f5",
			},
		}),
		[bare]
	);

	const style = useMemo(
		() => ({
			control: (baseStyles, state) => ({
				...baseStyles,
				borderColor: bare ? "transparent" : state.isFocused ? lightBlue : gray300,
				"&:hover": {
					borderColor: bare ? "transparent" : state.isFocused ? lightBlue : darkBlue,
				},
				boxShadow: "none",
				minWidth: "150px",
			}),
			valueContainer: baseStyles => ({
				...baseStyles,
				padding: bare ? "7px" : "7px 15px",
				gap: "8px",
			}),
			menu: baseStyles => {
				const styleCpy = {...baseStyles};
				delete styleCpy.boxShadow;
				return styleCpy;
			},
			option: (baseStyles, state) => ({
				...baseStyles,
				padding: "8px 16px",
				display: "flex",
				gap: "8px",
				alignItems: "center",
				background: state.isSelected
					? state.isFocused
						? darkBlue
						: lightBlue
					: state.isFocused
					? gray200
					: white,
			}),
			noOptionsMessage: baseStyles => ({
				...baseStyles,
				textAlign: "left",
			}),
			menuPortal: baseStyles => {
				const styleCpy = {...baseStyles};
				delete styleCpy.zIndex;
				return styleCpy;
			},
			dropdownIndicator: (base, state) => ({
				...base,
				transform: state?.selectProps?.menuIsOpen ? "rotate(180deg)" : null,
			}),
		}),
		[bare]
	);

	const classNames = useMemo(
		() => ({
			container: () => classnames(styles.reactSelect, className),
			menu: () => classnames(styles.menu),
			menuPortal: () => classnames(styles.menuPortal),
			dropdownIndicator: () => classnames(styles.dropdownIndicator),
			indicatorSeparator: () => classnames(styles.separator),
		}),
		[className]
	);
	const MultiValueComponent = useMemo(() => {
		const Component = <T,>({
			data,
			removeProps,
		}: MultiValueProps<Option<T>, boolean, GroupBase<Option<T>>>) => {
			const text = data.label;
			const onDelete = removeProps?.onClick as () => void;
			return <Pill text={text} color={pillColor ?? "black"} onDelete={() => onDelete?.()} />;
		};
		Component.displayName = "MultiValueComponent";
		return Component;
	}, [pillColor]);

	const components = useMemo(() => {
		const customComponents = {
			MultiValue: MultiValueComponent,
			Option: OptionCustom,
		};

		if (disableDropdownIndicator) {
			customComponents["DropdownIndicator"] = null;
			customComponents["IndicatorSeparator"] = null;
		}

		return customComponents;
	}, [disableDropdownIndicator, MultiValueComponent]);

	return (
		<Container {...(label ? {className: classnames(styles.searchableSelectContainer, className)} : {})}>
			{label && <label>{label}</label>}
			<SelectComponent
				isDisabled={disabled}
				menuPortalTarget={document.body}
				onChange={handleChange}
				value={sortedValues}
				isMulti={isMulti ?? false}
				name={name}
				placeholder={placeholder}
				options={options}
				isClearable={false}
				styles={style}
				theme={getTheme}
				classNames={classNames}
				components={components}
				noOptionsMessage={getNoOptionsMessage}
				{...(onAdd ? {onCreateOption: onAdd} : {})}
				isSearchable={isSearchable ?? true}
			/>
		</Container>
	);
}

export default SearchableSelect;
