import React, {useCallback, useContext, useMemo} from "react";
import {useFormikContext} from "formik";

import {OnChange, PostCollectionFormValues} from "./post";
import {useConfirmModal} from "../../../../modals";
import {postServices, services, useCategories} from "../../../../data";
import {Span1} from "../../../../components/text";
import {DropdownButton, SmallButton} from "../../../../components/input";
import {Icon} from "../../../../components/images";
import {ListCollapsedContext} from "./post-list";
import {DragHandleProps} from "./drag-drop";
import {useConfirmChangeModal} from "../../../collections/change-modal";

import styles from "./styles.module.scss";

export const PostEditorHeader = ({
	onChange,
	dragHandleProps,
}: {
	onChange: OnChange;
	dragHandleProps?: DragHandleProps;
}) => {
	const {values, setFieldValue, submitForm, setValues} = useFormikContext<PostCollectionFormValues>();
	const availableCategories = useCategories({fetchPolicy: "cache-first"});
	const {id, opengraphs, perNetwork, categoryId, shuffledComments, shuffledImages} = values;
	const categoryName = useMemo(() => availableCategories.find(c => c.id === categoryId)?.name, [
		availableCategories,
		categoryId,
	]);
	const resetOpenGraphs = useCallback(() => {
		const newOpengraphs = {
			general: opengraphs.general,
			...services.reduce((acc, service) => {
				acc[service] = null;
				return acc;
			}, {}),
		};
		onChange(`opengraphs`, newOpengraphs);

		return newOpengraphs;
	}, [opengraphs, onChange]);
	const {open: openEditBulkModal} = useConfirmModal(
		() => ({
			title: "Are you sure you want to make this change?",
			body: <div>Making this change will delete the content in the post. This action cannot be undone.</div>,
			onConfirm: close => {
				const opengraphs = resetOpenGraphs();
				setValues({
					...values,
					opengraphs,
					perNetwork: !perNetwork,
					activeNetwork: "general",
				});
				close();
			},
		}),
		[setValues, values, perNetwork, resetOpenGraphs]
	);
	const hasShuffledContent = shuffledComments || shuffledImages;
	const {collapsedPosts, setCollapsedPosts} = useContext(ListCollapsedContext);
	const onEditModeChange = useCallback(() => {
		if ((perNetwork && services.some(s => opengraphs[s])) || hasShuffledContent) {
			return openEditBulkModal();
		}

		let newOpengraphs;
		if (perNetwork) {
			newOpengraphs = resetOpenGraphs();
		}

		setValues({
			...values,
			...(newOpengraphs ? {opengraphs: newOpengraphs} : {}),
			perNetwork: !perNetwork,
			activeNetwork: perNetwork ? "general" : postServices?.[0],
		});
	}, [openEditBulkModal, setValues, values, perNetwork, resetOpenGraphs, opengraphs, hasShuffledContent]);

	const resetShuffleContent = useCallback(
		async close => {
			await onChange("shuffledComments", undefined);
			onChange("shuffledImages", undefined);
			setFieldValue("perNetwork", !perNetwork);
			close();
		},
		[onChange, perNetwork, setFieldValue]
	);

	const confirmResetShuffleModal = useConfirmChangeModal({
		confirmColor: "pink",
		confirmText: "Delete Shuffle Content",
		onConfirm: resetShuffleContent,
	});

	const onCollapse = useCallback(() => {
		setCollapsedPosts(id, !collapsedPosts[id]);
		submitForm();
	}, [submitForm, setCollapsedPosts, collapsedPosts, id]);

	return (
		<div className={styles.editorHeader}>
			<div className={styles.headerTitleContainer}>
				<div className={styles.dragZone} {...dragHandleProps}>
					<Icon icon="drag" color="black" height={16} className={styles.draggable} />
				</div>
				<Span1 bold className={styles.headerTitle}>
					{perNetwork
						? "Individual Network Posts"
						: hasShuffledContent
						? "Shuffle Content Post"
						: "Bulk Post"}
				</Span1>
			</div>
			<div className={styles.buttons}>
				{categoryId && !!availableCategories.length && (
					<DropdownButton
						className={styles.selectCategoryBtn}
						options={availableCategories.map(c => ({
							label: c.name,
							onClick: () => onChange("categoryId", c.id),
						}))}
						value={categoryName}
						color="black"
						invert
						arrow
						searchBar
						size={"small"}
					/>
				)}
				<SmallButton
					className={styles.editModeButton}
					IconComponent={
						<Icon
							color="blue"
							icon={perNetwork ? "bulkEdit" : "customizeNetwork"}
							width={16}
							viewBox="0 0 16 16"
						/>
					}
					value={perNetwork ? "Edit all posts in bulk" : "Customize per network"}
					onClick={shuffledComments || shuffledImages ? confirmResetShuffleModal.open : onEditModeChange}
					color="blue"
					invert
				/>
				<div className={styles.collapseBtn} onClick={onCollapse}>
					<Icon icon="chevron-up" color="black" />
				</div>
			</div>
		</div>
	);
};
