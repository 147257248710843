export * from "./editor";
export * from "./media";
export * from "./share-preview";
export * from "./social-score";

export const networkCaptionMaxLength = {
	twitter: 255,
	linkedin: 3000,
	facebook: 3000,
	instagram: 3000,
	general: 3000,
};

export const networkTitleMaxLength = {
	linkedin: 100,
	twitter: 100,
	facebook: 100,
	instagram: 100,
	general: undefined,
};

export const networkDescriptionMaxLength = {
	linkedin: undefined,
	twitter: undefined,
	facebook: 300,
	instagram: undefined,
	general: 300,
};
