import {Page} from "../../../layout";
import {CollectionSplit} from "./collectionSplit";

import styles from "./collection.module.scss";

export const Collection = () => (
	<Page
		className={styles.collectionPage}
		title="Builder"
		parentPath="/collections"
		tabs={[
			{name: "Collections", path: "/collections"},
			{name: "Posts", path: "/collections/posts"},
		]}
		fullWidth={true}
	>
		<CollectionSplit />
	</Page>
);
