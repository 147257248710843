import {useCallback, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useLazyQuery} from "@apollo/client";
import {useLocalStorage} from "react-use";

import {Dot, Icon} from "../../../../components/images";
import {Loading} from "../../../../components/loading";
import {Span2, Span4} from "../../../../components/text";
import {CANVA_DESIGN, CREATE_CANVA_DESIGN} from "../../../../data/canva";
import {useCanvaAsset} from "../../../../hooks/use-canva-asset";
import {Modal, useModal} from "../../../../modals/new";
import {useMutationToast} from "../../../../toast";
import {useMyUser} from "../../../../data";

import styles from "./components.module.scss";

interface CanvaButtonProps {
	image: string;
	opengraphId: number | undefined;
	canvaId?: string;
	preview?: boolean;
	imageDestination: "OPENGRAPH" | "CANVA_LIBRARY" | "SHUFFLED_IMAGE" | "MULTI_IMAGE";
	opengraphNetwork?: string;
	imageId?: string;
	shuffledCommentId?: string;
}
const encodeCorrelationState = (stringifiedState: string) => btoa(stringifiedState);

export const CanvaButton = ({
	image,
	opengraphId,
	preview,
	canvaId,
	imageDestination,
	opengraphNetwork,
	shuffledCommentId,
	imageId,
}: CanvaButtonProps) => {
	const me = useMyUser();
	const hasCanva = me?.integrations?.canva;
	const [, setReturnState] = useLocalStorage(`canva-return-state-${me.id}`);
	const [redirecting, setRedirecting] = useState(false);
	// this is for when the canvaId is no longer valid and we need to create the asset again
	const [stepOverrule, setStepOverrule] = useState(false);
	const [getCanvaDesign, {loading: loadingCanvaDesign}] = useLazyQuery(CANVA_DESIGN);
	const {state, pathname} = useLocation();
	const loadImageSizes = useCallback(() => {
		const img = new Image();
		return new Promise<{width: number; height: number}>((resolve, reject) => {
			img.src = image;
			img.onload = function () {
				resolve({width: img.width, height: img.height});
			};
			img.onerror = reject;
		});
	}, [image]);

	const [url, setUrl] = useState("");
	const {open, modal} = useModal({});
	const navigate = useNavigate();
	const newPostState = state?.type ? state?.type ?? "company" : null;
	const setValues = useCallback(
		designId =>
			setReturnState({
				originPage: pathname,
				opengraphId,
				designId,
				imageDestination,
				...(shuffledCommentId ? {shuffledCommentId} : {}),
				...(preview ? {preview} : {}),
				...(imageId ? {imageId} : {}),
				...(newPostState ? {newPostType: newPostState} : {}),
				...(opengraphNetwork ? {opengraphNetwork} : {}),
			}),
		[
			pathname,
			opengraphId,
			preview,
			imageDestination,
			imageId,
			shuffledCommentId,
			newPostState,
			opengraphNetwork,
			setReturnState,
		]
	);

	const openCanvaDesign = useCallback(
		design => {
			setRedirecting(true);
			const url = new URL(design.urls.editUrl);
			setValues(design.id);
			url.searchParams.append("correlation_state", encodeCorrelationState(JSON.stringify({user_id: me.id})));
			setUrl(url.toString());
			window.open(url, "_self");
		},
		[me.id, setValues]
	);

	const [createCanvaDesign, {data, loading: loadingCreateDesign}] = useMutationToast(CREATE_CANVA_DESIGN, {
		onCompleted: data => {
			if (data) {
				openCanvaDesign(data.createDesign);
			}
		},
	});
	const onCanvaUploadComplete = useCallback(
		async assetId => {
			const {width, height} = await loadImageSizes();
			createCanvaDesign({
				variables: {
					assetId,
					width,
					height,
				},
			});
		},
		[createCanvaDesign, loadImageSizes]
	);
	const {createAssetJob, loading: loadingAssetUpload} = useCanvaAsset({
		onComplete: onCanvaUploadComplete,
	});

	const loadCanvaDesign = useCallback(
		canvaId =>
			getCanvaDesign({
				variables: {
					id: canvaId,
				},
			}).then(({data}) => {
				if (data?.canvaDesign?.urls?.editUrl) {
					openCanvaDesign(data.canvaDesign);
					return true;
				}
				return false;
			}),
		[getCanvaDesign, openCanvaDesign]
	);

	const onCanvaButtonClick = useCallback(async () => {
		if (!hasCanva) {
			navigate("/settings/integrations");
		}
		// reset values
		setUrl("");
		setStepOverrule(false);
		setRedirecting(false);

		open();

		if (canvaId) {
			const hasDesign = await loadCanvaDesign(canvaId);
			if (hasDesign) return;
			setStepOverrule(true);
		}
		createAssetJob(image);
		return;
	}, [open, createAssetJob, image, hasCanva, navigate, canvaId, loadCanvaDesign]);
	return (
		<>
			<Icon onClick={onCanvaButtonClick} icon={"canva"} width={24} />
			<Modal title="Please wait" modal={modal}>
				<div className={styles.canvaContainer}>
					{canvaId && !stepOverrule ? (
						<div className={styles.canvaStep}>
							{loadingCanvaDesign ? <Loading size="extraSmall" /> : <Icon icon="check" color={"black"} />}
							<Span2 color={"black"}>Loading Canva Design</Span2>
						</div>
					) : (
						<>
							<div className={styles.canvaStep}>
								{loadingAssetUpload ? <Loading size="extraSmall" /> : <Icon icon="check" color={"black"} />}
								<Span2 color={loadingAssetUpload ? "grey" : "black"}>Uploading photo to Canva</Span2>
							</div>
							<div className={styles.canvaStep}>
								{loadingCreateDesign ? (
									<Loading size="extraSmall" />
								) : data && !loadingAssetUpload ? (
									<Icon icon="check" color={"black"} />
								) : (
									<Dot color="grey" className={styles.dot} />
								)}
								<Span2 color={data && !loadingAssetUpload ? "black" : "grey"}>Creating Canva Design</Span2>
							</div>
						</>
					)}
					<div className={styles.canvaStep}>
						{redirecting ? <Loading size="extraSmall" /> : <Dot color="grey" className={styles.dot} />}
						<Span2 color={"grey"}>Redirecting to Canva</Span2>
					</div>
				</div>
				{url && (
					<Span4 className={styles.linkMessage}>
						If the link does not open automatically, please click <a href={url}>here</a>.
					</Span4>
				)}
			</Modal>
		</>
	);
};
