import * as Yup from "yup";

import {networkCaptionMaxLength, networkDescriptionMaxLength, networkTitleMaxLength} from "../../components";
import {accountNames, PostServices, postServices} from "../../../../data";

const getGeneralOpengraphSchema = () => ({
	comment: Yup.string()
		.notRequired()
		.test("comment-length", "Caption too long", (value, schema) => {
			const context = schema?.options?.context || {};
			const opengraphs = context.opengraphs || {};
			const isValid = n => (value?.length || 0) <= networkCaptionMaxLength[n];

			if (!context.perNetwork) {
				return isValid("general");
			}

			const notValidNetworks = postServices
				.filter(network => (!opengraphs[network] || network === "general") && !isValid(network))
				.map(n => accountNames[n] || "General");

			return (
				!notValidNetworks.length ||
				schema.createError({message: `Caption too long for ${notValidNetworks.join(", ")}`})
			);
		})
		.test("comment-required", "Caption is required", (value, schema) => {
			const context = schema?.options?.context || {};
			const {image, video} = context.opengraphs?.general || {};
			const shareType = context.url ? "url" : image || video ? "media" : "text";
			if (shareType === "media") return true;
			return !!context.url || context.perNetwork || !!value?.length || !context.opengraphs?.general;
		}),
	video: Yup.string().notRequired(),
	image: Yup.string().notRequired(),
	title: Yup.string()
		.test("title-length", "Title too long", (value, schema) => {
			const context = schema?.options?.context || {};
			const opengraphs = context.opengraphs || {};
			const isValid = n =>
				!networkTitleMaxLength[n] || (value?.length || 0) <= (networkTitleMaxLength[n] ?? 0);

			if (!context.perNetwork) {
				return isValid("general");
			}

			const notValidNetworks = postServices
				.filter(network => (!opengraphs[network] || network === "general") && !isValid(network))
				.map(n => accountNames[n] || "General");

			return (
				!notValidNetworks.length ||
				schema.createError({message: `Title too long for ${notValidNetworks.join(", ")}`})
			);
		})
		.notRequired(),
	description: Yup.string()
		.test("description-length", "Description too long", (value, schema) => {
			const context = schema?.options?.context || {};
			const opengraphs = context.opengraphs || {};
			const {video} = context.opengraphs?.general || schema?.parent || {};

			if (!(context.url || video)) return true;

			const isValid = n =>
				!networkDescriptionMaxLength[n] || (value?.length || 0) <= (networkDescriptionMaxLength[n] ?? 0);

			if (!context.perNetwork) {
				return isValid("general");
			}

			const notValidNetworks = ["general", "facebook"]
				.filter(network => (!opengraphs[network] || network === "general") && !isValid(network))
				.map(n => accountNames[n] || "General");

			return (
				!notValidNetworks.length ||
				schema.createError({message: `Description too long for ${notValidNetworks.join(", ")}`})
			);
		})
		.notRequired(),
});

const getOpengraphSchema = (n: PostServices) => ({
	comment: Yup.string()
		.notRequired()
		.test(
			"comment-length",
			accountNames[n] ? `Caption too long for ${accountNames[n]}` : "Caption too long",
			value => (value?.length || 0) <= networkCaptionMaxLength[n]
		)
		.test("comment-required", "Caption is required", (value, schema) => {
			const context = schema?.options?.context || {};
			const opengraph = context.opengraphs?.[n] || schema?.parent || {};
			const shareType = context.url ? "url" : opengraph.image || opengraph.video ? "media" : "text";
			if (shareType === "media") return true;
			return !!context.url || (n === "general" && context.perNetwork) || !!value?.length || !opengraph;
		}),
	video: Yup.string().notRequired(),
	image: Yup.string().notRequired(),
	title: Yup.string()
		.test(
			"title-length",
			accountNames[n] ? `Title too long for ${accountNames[n]}` : "Title too long",
			value => !networkTitleMaxLength[n] || (value?.length || 0) <= (networkTitleMaxLength[n] ?? 0)
		)
		.notRequired(),
	description: Yup.string()
		.test(
			"description-length",
			accountNames[n] ? `Description too long for ${accountNames[n]}` : "Description too long",
			(value, schema) => {
				const context = schema?.options?.context || {};
				const {video} = context.opengraphs?.[n] || schema?.parent || {};

				if (!(context.url || video)) return true;

				return (
					!networkDescriptionMaxLength[n] || (value?.length || 0) <= (networkDescriptionMaxLength[n] ?? 0)
				);
			}
		)
		.notRequired(),
});

export const postValidationSchema = Yup.object().shape({
	opengraphs: Yup.object().shape({
		general: Yup.object().shape(getGeneralOpengraphSchema()),
		facebook: Yup.object().shape(getOpengraphSchema("facebook")).notRequired(),
		instagram: Yup.object().shape(getOpengraphSchema("instagram")).notRequired(),
		linkedin: Yup.object().shape(getOpengraphSchema("linkedin")).notRequired(),
		twitter: Yup.object().shape(getOpengraphSchema("twitter")).notRequired(),
	}),
});
