import {gql} from "@apollo/client";
import dayjs, {Dayjs} from "dayjs";

import {HexColorString} from "../components/input/color";

export interface EnrollmentDomain {
	domain: string;
	group?: number;
}

export interface SelfEnrollment {
	code: string;
	domains: EnrollmentDomain[];
}

export interface OrgSettings {
	uiLogo?: string;
	emailLogo?: string;
	loginLinkColor: HexColorString;
	emailLogoSize?: number;
	queueButtonColor: HexColorString;
	queueCoBranding: boolean;
	hideTips: boolean;
	suppressAdminSpoof: boolean;
}

export interface SamlSettings {
	ssoUrl: string;
	sloUrl: string;
	issuerUrl: string;
	service: "OKTA" | "ONELOGIN" | "AZUREAD";
	certificate: string;
	domains: string[];
	disableRequestedAuthnContext: boolean;
	signingCertificate: string;
}
export interface OrgOptions {
	ai: boolean;
	virtualAssistant: boolean;
	peakTime: boolean;
	adminsInvisible: boolean;
	socialMediaApproval: boolean;
	leaderboard: boolean;
	queueToPendingUsersForever: boolean;
	emvQueueEnabled: boolean;
	urlShortener: string;
	ssoOnly: boolean;
	allowRedirect: boolean;
	companyStats: boolean;
	upcomingFeaturesEnabled: boolean;
	blockDuplicatePost: boolean;
	preventPostEditing: boolean;
}

export interface OrgFeatures {
	sso: boolean;
	uiBranding: boolean;
	leaderboard: boolean;
	emailBranding: boolean;
	rbac: boolean;
	facebookAnalytics: boolean;
	instagramAnalytics: boolean;
}

export type Feature = keyof OrgFeatures;

export interface Org {
	id: number;
	name: string;
	settings: OrgSettings;
	options: OrgOptions;
	created: Dayjs;
	subscription: string;
	type: string;
	csmUserId: string | number;
	primaryAdmin: number;
	features: OrgFeatures;
	salesforceId?: string;
}

export interface TrackingCookie {
	name?: string;
	company?: string;
	created: Dayjs;
	email?: string;
}

export const ORG_FRAGMENT = gql`
	fragment OrgFields on Org {
		id
		name
		settings {
			uiLogo
			emailLogo
			emailLogoSize
			loginLinkColor
			queueButtonColor
			queueCoBranding
			hideTips
			suppressAdminSpoof
		}
		options {
			ai
			virtualAssistant
			peakTime
			adminsInvisible
			leaderboard
			socialMediaApproval
			queueToPendingUsersForever
			emvQueueEnabled
			urlShortener
			ssoOnly
			allowRedirect
			companyStats
			upcomingFeaturesEnabled
			blockDuplicatePost
			preventPostEditing
		}
		features {
			sso
			uiBranding
			emailBranding
			rbac
			facebookAnalytics
			instagramAnalytics
		}
		created
		subscription
		type
		csmUserId
		primaryAdmin
		salesforceId
	}
`;

export const SAML_SETTINGS_FRAGMENT = gql`
	fragment SamlSettingsFields on SamlSettings {
		ssoUrl
		sloUrl
		issuerUrl
		certificate
		service
		domains
		disableRequestedAuthnContext
		signingCertificate
	}
`;

export const SELF_ENROLLMENT_FRAGMENT = gql`
	fragment EnrollmentFields on SelfEnrollment {
		code
		domains {
			domain
			group
		}
	}
`;

export const TRACKING_COOKIES_FRAGMENT = gql`
	fragment TrackingCookiesFields on TrackingCookies {
		name
		company
		email
		created
	}
`;

export const SELF_ENROLLMENT = gql`
	query {
		selfEnrollment {
			...EnrollmentFields
		}
	}
	${SELF_ENROLLMENT_FRAGMENT}
`;

export const GET_TRACKING_COOKIES = gql`
	query {
		trackingCookies {
			...TrackingCookiesFields
		}
	}
	${TRACKING_COOKIES_FRAGMENT}
`;

export const REFRESH_CODE = gql`
	mutation RefreshCode {
		refreshCode {
			...EnrollmentFields
		}
	}
	${SELF_ENROLLMENT_FRAGMENT}
`;

export const UPDATE_SELF_ENROLLMENT = gql`
	mutation UpdateSelfEnrollment($domains: [InputEnrollmentDomain!]!) {
		updateSelfEnrollment(domains: $domains) {
			...EnrollmentFields
		}
	}
	${SELF_ENROLLMENT_FRAGMENT}
`;

export const UPDATE_ORG = gql`
	mutation updateOrg($changes: OrgInput!, $deletions: OrgInputDeletions) {
		updateOrg(changes: $changes, deletions: $deletions) {
			...OrgFields
		}
	}
	${ORG_FRAGMENT}
`;

export const SAML_SETTINGS = gql`
	query {
		samlSettings {
			...SamlSettingsFields
		}
	}
	${SAML_SETTINGS_FRAGMENT}
`;

export const UPDATE_SAML_SETTINGS = gql`
	mutation updateSamlSettings($settings: SamlSettingsInput!) {
		updateSamlSettings(settings: $settings) {
			...SamlSettingsFields
		}
	}
	${SAML_SETTINGS_FRAGMENT}
`;

export const UPLOAD_LOGO = gql`
	mutation UploadLogo($file: Upload!, $type: LogoType!) {
		uploadLogo(file: $file, type: $type) {
			...OrgFields
		}
	}
	${ORG_FRAGMENT}
`;

export const REMOVE_LOGO = gql`
	mutation RemoveLogo($type: LogoType!) {
		removeLogo(type: $type) {
			...OrgFields
		}
	}
	${ORG_FRAGMENT}
`;

export const inflateTrackingCookie = (raw): TrackingCookie => ({
	...raw,
	created: dayjs(raw.created),
});
