/* eslint-disable @typescript-eslint/no-explicit-any */
import {FC, useMemo, useState} from "react";
import classnames from "classnames/bind";
import ReactQuill, {Quill} from "react-quill";
import AutoLinks from "quill-auto-links";

import {Input, InputComponent, useId, useValidate} from ".";
import {Icon} from "../images";
import {useMutationToast} from "../../toast";
import {UPLOAD_IMAGE} from "../../data";

import "react-quill/dist/quill.snow.css";
import "./rich-text.scss";
import styles from "./input.module.scss";
const bStyles = classnames.bind(styles);

const SizeStyle = Quill.import("attributors/style/size");
Quill.register(SizeStyle, true);
Quill.register("modules/autoLinks", AutoLinks);

export interface RichTextProps extends InputComponent<string | undefined, string> {
	placeholder?: string;
}

function undo(this: any) {
	this.quill.history.undo();
}
function redo(this: any) {
	this.quill.history.redo();
}

const formats = [
	"header",
	"font",
	"size",
	"bold",
	"italic",
	"underline",
	"strike",
	"blockquote",
	"list",
	"bullet",
	"indent",
	"link",
	"image",
	"color",
];

const Toolbar: FC<{show?: boolean}> = ({show}) => {
	const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
	};

	return (
		<div className={bStyles("toolbar", {show})} onMouseDown={handleMouseDown}>
			<div id="toolbar">
				<button className="ql-bold" />
				<button className="ql-italic" />
				<button className="ql-strike" />
				<button className="ql-underline" />
				<div className="space" />
				<button className="ql-list" value="bullet" />
				<button className="ql-list" value="ordered" />
				<div className="space" />
				<button className="ql-link" />
				<button className="ql-image" />
				<div className="space" />
				<select className="ql-size" defaultValue={false as any} onChange={e => e.persist()}>
					<option value="10px">Small</option>
					<option value={false as any}>Normal</option>
					<option value="18px">Large</option>
					<option value="32px">Huge</option>
				</select>
				<div className="space" />
				<button className="ql-undo">
					<Icon icon="undo" className={styles.icon} />
				</button>
				<button className="ql-redo">
					<Icon icon="redo" className={styles.icon} />
				</button>
			</div>
		</div>
	);
};

export const RichText: FC<RichTextProps> = ({
	disabled,
	id: maybeId,
	onChange,
	placeholder,
	validate,
	value,
	...props
}) => {
	const id = useId(maybeId);
	const [focus, setFocus] = useState(false);
	const [uploadImage] = useMutationToast(UPLOAD_IMAGE);
	const {inputProps} = useValidate(id, value, validate);

	const modules = useMemo(
		() => ({
			autoLinks: true,
			toolbar: {
				container: "#toolbar",
				handlers: {
					undo,
					redo,
					image: function (this: any) {
						let fileInput = this.container.querySelector("input.ql-image[type=file]");
						if (!fileInput) {
							fileInput = document.createElement("input");
							fileInput.setAttribute("type", "file");
							fileInput.setAttribute("accept", "image/png, image/gif, image/jpeg, image/bmp, image/x-icon");
							fileInput.classList.add("ql-image");
							fileInput.addEventListener("change", () => {
								if (!fileInput.files?.[0]) return;
								uploadImage({variables: {file: fileInput.files[0]}}).then(({data}) => {
									if (!data?.uploadImage) return;
									const selection = this.quill.getSelection();
									this.quill.insertEmbed(selection.index ?? 0, "image", data.uploadImage, "user");
								});
							});
							this.container.appendChild(fileInput);
						}
						fileInput.click();
					},
				},
			},
			clipboard: {matchVisual: false},
		}),
		[uploadImage]
	);

	return (
		<Input baseClass="text" id={id} disabled={disabled} {...inputProps} {...props}>
			<Toolbar show={focus} />
			<ReactQuill
				id={id}
				placeholder={placeholder}
				theme="snow"
				bounds="#toolbar"
				formats={formats}
				value={value}
				onChange={onChange}
				modules={modules}
				onFocus={() => !disabled && setFocus(true)}
				onBlur={() => !disabled && setFocus(false)}
				className={styles.richText}
				readOnly={disabled}
			/>
		</Input>
	);
};
