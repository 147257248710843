import {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";

import {Page} from "../../layout";
import {P, Span2} from "../../components/text";
import {Loading} from "../../components/loading";
import {Dot, Icon} from "../../components/images";
import useCanvaUpdate from "../../hooks/use-canva-update";
import {Button} from "../../components/input";

import styles from "./index.module.scss";

const Canva = () => {
	const {
		loadingExport,
		loadingUpload,
		loadingUpdateImage,
		finnishedUpdate,
		status,
		finnishedDesignUpload,
		step,
		startUpdate,
		hasError,
		backButtonUrl,
	} = useCanvaUpdate();

	useEffect(() => {
		startUpdate();
	}, [startUpdate]);

	const navigate = useNavigate();
	const onGoBack = useCallback(() => navigate(backButtonUrl), [navigate, backButtonUrl]);

	return (
		<Page title={"Canva"} className={styles.canvaPage}>
			<div className={styles.canvaHeader}>
				<h2>Processing Canva Return</h2>
				<div className={styles.canvaReturnContainer}>
					<div className={styles.loadingItem}>
						<Icon icon="check" color={"black"} />
						<Span2>Fetching updated design.</Span2>
					</div>
					<div className={styles.loadingItem}>
						{loadingExport ? (
							<Loading size="extraSmall" />
						) : step > 1 || finnishedDesignUpload ? (
							<Icon icon="check" color={"black"} />
						) : (
							<Dot color="grey" className={styles.dot} />
						)}
						<Span2 color={status === "in_progress" ? "black" : "grey"}>Exporting updated design.</Span2>
					</div>
					<div className={styles.loadingItem}>
						{loadingUpload ? (
							<Loading size="extraSmall" />
						) : step > 2 || finnishedDesignUpload ? (
							<Icon icon="check" color={"black"} />
						) : (
							<Dot color="grey" className={styles.dot} />
						)}
						<Span2 color={status === "completed" ? "black" : "grey"}>Uploading image.</Span2>
					</div>
					<div className={styles.loadingItem}>
						{loadingUpdateImage ? (
							<Loading size="extraSmall" />
						) : finnishedUpdate ? (
							<Icon icon="check" color={"black"} />
						) : (
							<Dot color="grey" className={styles.dot} />
						)}
						<Span2 color="grey"> Updating your content.</Span2>
					</div>
					<div className={styles.loadingItem}>
						<Dot color="grey" className={styles.dot} />
						<Span2 color="grey"> Redirecting to the original page.</Span2>
					</div>
				</div>
				{hasError && (
					<>
						<P color="red">
							There was an inssue processing the Canva return. Please try again or contact support if the
							issue persists.
						</P>
						<Button onClick={onGoBack} value="Go back" />
					</>
				)}
			</div>
		</Page>
	);
};

export default Canva;
