import {FC, ReactElement} from "react";
import {Navigate, Outlet, useLocation} from "react-router";
import {useQuery} from "@apollo/client";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import * as Sentry from "@sentry/react";

import {PathPerms, itemAllowed} from "./perms";
import {GET_ME, GET_RELEASE_CHANNEL} from "../data/user";
import {Layout} from "./layout";
import {Page} from "./page";
import {Tabs} from "./tabs";
import {Login} from "../containers/login";
import {Demo} from "../containers/demo";
import {Peaktime} from "../containers/peaktime";
import Visitors from "../containers/internal/visitors";
import {Collections, Posts} from "../containers/collections";
import {Schedule} from "../containers/schedule";
import {Span1} from "../components/text";
import {CompanyInfo, LogoUpload, Preferences, Templates} from "../containers/settings/company";
import {Account, ContentFeeds, CorporateAccounts, FindOutWho} from "../containers/settings";
import {Groups, Users} from "../containers/users";
import {Integrations} from "../containers/settings/integrations";
import {
	EmployeeAdvocacy,
	Collection,
	Company as CompanyAnalytics,
	Leaderboard,
	Personal,
} from "../containers/analytics";
import {Facebook, Instagram, LinkedIn, Twitter} from "../containers/analytics/company";
import {Company, Explore, MyHub} from "../containers/feed";
import {Loading} from "../components/loading";
import config from "../config";
import {IconType} from "../components/images";
import {OrgOptions} from "../data/org";
import {GET_SAVED_SHARE_PARAMS} from "../data/share";
import {OpenSlackModal} from "../containers/settings/integrations/slack";
import {BugsPriority} from "../containers/internal/bugs-priority";
import {Contest, Contests} from "../containers/contests";
import {Post} from "../containers/builder/posts";
import {Collection as CollectionEdit} from "../containers/builder/collections";
import {PageErrorBoundary} from "../components/error";
import {SSO} from "../containers/settings/sso";
import Canva from "../containers/canva";

import companyAnalyticsPageStyles from "../containers/analytics/company/company.module.scss";

export interface NavItem extends PathPerms {
	name?: string;
	icon?: IconType;
	bottom?: boolean;
	element?: ReactElement;
	children?: Omit<NavItem, "children">[];
	disabled?: boolean;
	pageWidth?: "full" | "fit-content" | "auto";
	isVisible?: keyof OrgOptions;
	errorElement?: ReactElement;
}

const RequireAuth = (): ReactElement => {
	const {data: user, error, loading} = useQuery(GET_ME);
	const {data: releaseChannelData, loading: releaseChannelLoading} = useQuery(GET_RELEASE_CHANNEL);
	const {data, refetch} = useQuery(GET_SAVED_SHARE_PARAMS);
	const location = useLocation();
	const {pathname} = location;

	if (loading || releaseChannelLoading) return <Loading position="absolute" />;

	if (
		releaseChannelData?.releaseChannel.domain &&
		!releaseChannelData?.releaseChannel.domain.includes(window.location.origin)
	) {
		window.location.href = releaseChannelData?.releaseChannel.domain;
	}

	if (error) {
		return <Navigate to="/login" state={{from: location}} replace />;
	}

	let routes: PathPerms[] | undefined = baseRoutes;
	while (routes) {
		const route = routes.find(r => (r.end ? pathname === r.path : pathname.startsWith(r.path)));
		if (!route) break;
		if (!itemAllowed(route, user.me)) return <Navigate to={routes[0].path} replace />;
		routes = route.children;
	}

	if (
		data?.savedShareParams &&
		!location.pathname.startsWith("/collections/posts") &&
		!location.pathname.startsWith("/collections/list")
	) {
		const {queueId, queuedUrlId, companyUserId, url: urlToFetch, queue_url} = data.savedShareParams;
		let url;
		if (queueId || queuedUrlId) {
			url = `/collections/posts?${queueId ? `queue_id=${queueId}` : ""}${queueId && queuedUrlId ? "&" : ""}${
				queuedUrlId ? `queued_url_id=${queuedUrlId}` : ""
			}${(queueId || queuedUrlId) && companyUserId ? "&" : ""}${
				companyUserId ? `company_user_id=${companyUserId}` : ""
			}`;
		} else if (urlToFetch) {
			url = `/collections/posts?url=${urlToFetch}`;
		} else if (queue_url) {
			url = `/collections/list?url=${queue_url}`;
		}
		if (url) {
			refetch();
			return <Navigate to={url} state={{from: location}} replace />;
		}
	}
	return <Layout />;
};

const CompanyTabsHeader = (): ReactElement => (
	<>
		<h3>Company and Branding</h3>
		<Span1>
			Adjust company settings, adapt platform visuals, content preferences, and emails to seamlessly match
			your brand&apos;s unique style.
		</Span1>
	</>
);

const feedTabs: NavItem[] = [
	{name: "My Hub", path: "/feed/hub", element: <MyHub />, icon: "myHub"},
	{name: "Company", path: "/feed/company", element: <Company />, icon: "company"},
	{name: "Explore", path: "/feed/explore", element: <Explore />, icon: "explore"},
];

const collectionsTabs: NavItem[] = [
	{
		name: "Collections",
		path: "/collections/list",
		element: <Collections />,
		adminOnly: true,
		icon: "collections",
	},
	{name: "Posts", path: "/collections/posts", element: <Posts />, icon: "posts"},
];

const analyticsTabs: NavItem[] = [
	{name: "Personal", path: "/analytics/personal", element: <Personal />, icon: "personal"},
	{
		name: "Company",
		path: "/analytics/company",
		element: <CompanyAnalytics />,
		children: [
			{path: "/analytics/company/facebook", element: <Facebook />, name: "Facebook"},
			{path: "/analytics/company/instagram", element: <Instagram />, name: "Instagram"},
			{path: "/analytics/company/linkedin", element: <LinkedIn />, name: "LinkedIn"},
			{path: "/analytics/company/twitter", element: <Twitter />, name: "X"},
		],
		adminOnly: true,
		icon: "company",
	},
	{
		name: "Collections",
		path: "/analytics/collections",
		element: <Collection />,
		adminOnly: true,
		icon: "collections",
	},
	{
		name: "Leaderboard",
		path: "/analytics/leaderboard",
		isVisible: "leaderboard",
		element: <Outlet />,
		children: [
			{path: "/analytics/leaderboard/", element: <Leaderboard />, name: "Leaderboard"},
			{path: "/analytics/leaderboard/:id", element: <Personal />, name: "Leaderboard"},
		],
		icon: "leaderboard",
	},
	{
		name: "Employee Advocacy",
		path: "/analytics/employee-advocacy",
		element: <EmployeeAdvocacy />,
		adminOnly: true,
		icon: "employeeAdvocacy",
	},
];

const settingsCompanyTabs: NavItem[] = [
	{path: "/settings/company", end: true, element: <Preferences />, name: "Content Preferences"},
	{path: "/settings/company/logo", element: <LogoUpload />, name: "Logo Upload", feature: "uiBranding"},
	{
		path: "/settings/company/templates",
		element: <Templates />,
		name: "Email Templates Preview",
		feature: "emailBranding",
	},
	{path: "/settings/company/info", element: <CompanyInfo />, name: "Company Info"},
];

export const settingSideNav: NavItem[] = [
	{
		name: "Personal Account",
		path: "/settings/personal",
		element: <Account />,
		icon: "personal",
		pageWidth: "fit-content",
	},
	{
		name: "Customization & Branding",
		path: "/settings/company",
		element: <Tabs tabs={settingsCompanyTabs} header={<CompanyTabsHeader />} />,
		children: settingsCompanyTabs,
		adminOnly: true,
		icon: "magic",
	},
	{
		name: "Content & Feeds",
		path: "/settings/content-feeds",
		element: <ContentFeeds />,
		adminOnly: true,
		icon: "feed",
	},
	{
		name: "Find Out Who",
		path: "/settings/find-out-who",
		element: <FindOutWho />,
		adminOnly: true,
		icon: "help",
	},
	{
		name: "Corporate Accounts",
		path: "/settings/accounts",
		element: <CorporateAccounts />,
		adminOnly: true,
		icon: "office",
	},
	{
		name: "Single-Sign on",
		path: "/settings/sso",
		element: <SSO />,
		feature: "sso",
		pageWidth: "fit-content",
		adminOnly: true,
	},
	{
		name: "Integrations",
		path: "/settings/integrations",
		element: <Integrations />,
		pageWidth: "full",
		children: [
			{name: "Slack", path: "/settings/integrations/slack", element: <OpenSlackModal />, adminOnly: true},
		],
		icon: "puzzle",
	},
];

export const navigationRoutes: NavItem[] = [
	{
		name: "Feeds",
		path: "/feed",
		icon: "feed",
		element: <Page title="Feeds" parentPath="/feed" tabs={feedTabs} scroll={false} />,
		children: feedTabs,
	},
	{
		name: "Builder",
		path: "/collections",
		icon: "caption",
		element: <Page title="Builder" parentPath="/collections" tabs={collectionsTabs} />,
		children: collectionsTabs,
	},
	{name: "Calendar", path: "/schedule", icon: "calendar", element: <Schedule />},
	{
		name: "Analytics",
		path: "/analytics",
		icon: "analytics",
		element: (
			<Page
				className={companyAnalyticsPageStyles.companyAnalyticsPageStyles}
				title="Analytics"
				parentPath="/analytics"
				tabs={analyticsTabs}
			/>
		),
		children: analyticsTabs,
	},
	{
		name: "Contests",
		path: "/contests",
		icon: "trophy",
		element: <Page title="Contests" returnTo={"/contests"} />,
		children: [
			{
				name: "Contests",
				path: "/contests",
				element: <Contests />,
			},
			{
				name: "Contests",
				path: "/contests/:id",
				element: <Contest />,
			},
		],
	},
	{
		name: "Users",
		path: "/users",
		icon: "users",
		adminOnly: true,
		element: (
			<Page
				title="Users"
				parentPath="/users"
				tabs={[
					{name: "User Management", path: "/users/list", element: <Users />, adminOnly: true, icon: "person"},
					{
						name: "Group Management",
						path: "/users/groups",
						element: <Groups />,
						adminOnly: true,
						icon: "team",
					},
				]}
			/>
		),
		children: [
			{
				name: "User Management",
				path: "/users/list",
				element: <Users />,
				adminOnly: true,
				icon: "userManagement",
			},
			{
				name: "Group Management",
				path: "/users/groups",
				element: <Groups />,
				adminOnly: true,
				icon: "groupManagement",
			},
		],
	},

	{
		name: "Internal Tools",
		path: config.internalToolsUrl,
		icon: "key",
		internalOnly: true,
		bottom: true,
	},
	{
		name: "Settings",
		path: "/settings",
		icon: "settings",
		element: <Page title="Settings" sideNav parentPath="/settings" tabs={settingSideNav} />,
		children: settingSideNav,
		bottom: true,
	},
	{name: "Help", path: "https://cvssupport.wpenginepowered.com", icon: "help", bottom: true},
];

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const baseRoutes: NavItem[] = [
	{path: "/login", element: <Login />},
	{
		path: "/",
		element: <RequireAuth />,
		children: [
			...navigationRoutes,
			{path: "/demo", element: <Demo />},
			{path: "/peaktime", element: <Peaktime />, internalOnly: true},
			{path: "/internal/bugs-priority", element: <BugsPriority />, internalOnly: true},
			{path: "/visitors", element: <Visitors />, internalOnly: true},
			{path: "/canva-return", element: <Canva />},
			{path: "/collections/:id", element: <CollectionEdit />},
			{path: "/collections/posts/:id", element: <Post />},
			{path: "/", element: <Navigate to="/feed" replace />},
		],
	},
];

const addPageErrorBoundary = (routes: NavItem[]): NavItem[] =>
	routes.map(route => {
		route = {
			...route,
			errorElement: <PageErrorBoundary />,
		};

		if (route.children) {
			route = {
				...route,
				children: addPageErrorBoundary(route.children),
			};
		}

		return route;
	});

export const router = sentryCreateBrowserRouter(addPageErrorBoundary(baseRoutes));

export const AppRouterProvider: FC = () => <RouterProvider router={router} />;
