import {useCallback, useEffect, useMemo, useState} from "react";
import {useFormikContext} from "formik";
import {useNavigate} from "react-router-dom";

import {Collection, DELETE_COLLECTION} from "../../../../data";
import {Button} from "../../../../components/input";
import {CollectionSettings} from "./collection-settings";
import {useDebounceCallback} from "../../../../hooks/use-debounce-callback";
import {PostList} from "./post-list";
import {postValidationSchema} from "./validation-schema";
import {useMutationToast} from "../../../../toast";
import {useConfirmModal} from "../../../../modals";
import {Span} from "../../../../components/text";
import {CollectionScheduleModal} from "./collection-schedule-modal";
import {useModal} from "../../../../modals/new";

import styles from "./styles.module.scss";

export type CollectionFormValues = Pick<
	Collection,
	| "id"
	| "status"
	| "title"
	| "owner"
	| "autoLike"
	| "scheduledFor"
	| "message"
	| "deliveryMessage"
	| "to"
	| "sent"
	| "categories"
	| "posts"
>;

export type OnChange = (field: string, value: number | object | string | undefined) => void;
interface CollectionFormProps {
	collection: Collection;
	onSendPreview: () => void;
	onDiscard: () => void;
	addingPost?: boolean;
}

export const CollectionForm = (props: CollectionFormProps) => {
	const {collection, onSendPreview, onDiscard, addingPost} = props;
	const {values, setFieldValue, submitForm, isSubmitting, isValid} = useFormikContext<CollectionFormValues>();
	const [postsErrors, setPostsErrors] = useState(false);
	const navigate = useNavigate();
	const {open, modal} = useModal({});
	const [deleteCollection, {loading: isDeleting}] = useMutationToast(DELETE_COLLECTION, {
		variables: {id: collection.id},
		onCompleted: () => navigate("/collections"),
	});
	const debouncedSubmit = useDebounceCallback(submitForm, 2000);
	const onChange = useCallback(
		(field, value) => {
			setFieldValue(field, value);

			debouncedSubmit();
		},
		[setFieldValue, debouncedSubmit]
	);

	const deleteModal = useConfirmModal(
		() => ({
			onConfirm: () => deleteCollection(),
			confirming: isDeleting,
			confirmColor: "pink",
			confirmText: "Delete",
			title: "Confirm Delete",
			body: <Span>Collection {collection.title} has no posts. Do you want to delete it?</Span>,
		}),
		[isDeleting, deleteCollection, collection]
	);
	const onRemoveAllPosts = useCallback(() => {
		if (collection.scheduledFor && collection.status === "approved") deleteModal.open();
	}, [collection, deleteModal]);
	const hasPosts = useMemo(() => collection.posts.length !== 0, [collection.posts]);

	useEffect(() => {
		if (collection.posts.length === 0) return setPostsErrors(false);
		Promise.all(collection.posts.map(post => postValidationSchema.isValid(post))).then(values => {
			setPostsErrors(values.some(isValid => !isValid));
		});
	}, [collection.posts]);

	const onConfirm = useCallback(() => {
		navigate("/collections");
	}, [navigate]);

	return (
		<>
			<div className={styles.collectionForm}>
				<CollectionSettings onChange={onChange} collection={collection} />
				<PostList
					collection={collection}
					onChange={onChange}
					addingPost={addingPost}
					onRemoveAllPosts={onRemoveAllPosts}
				/>
				<div className={styles.formBottomToolbar}>
					<Button
						value="Send Preview"
						onClick={onSendPreview}
						invert
						disabled={!isValid || !hasPosts || postsErrors}
					/>
					<div>
						<Button
							onClick={open}
							value={values.scheduledFor ? "Schedule" : "Send Now"}
							disabled={isSubmitting || postsErrors || !isValid}
							color="blue"
						/>
						<Button value={"Close"} onClick={onDiscard} invert />
					</div>
				</div>
			</div>
			<CollectionScheduleModal modal={modal} collection={collection} onConfirm={onConfirm} />
		</>
	);
};
