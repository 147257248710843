import React, {useCallback, useState, useEffect, useMemo, createContext} from "react";
import {useQuery} from "@apollo/client";
import {useFormikContext} from "formik";
import {DropResult} from "react-beautiful-dnd";
import {useLocation} from "react-router-dom";

import {
	ADD_POST,
	Category,
	Collection,
	CREATE_CATEGORY,
	GET_CATEGORIES,
	getPostEmv,
	Post as PostType,
	REORDER_CATEGORIES,
} from "../../../../data";
import {Button, DropdownButton} from "../../../../components/input";
import {Post} from "./post";
import {useMutationToast} from "../../../../toast";
import {useNewModal} from "../../../../modals";
import {ManageCategories} from "../../../../modals/collections/manage-categories";
import {CollectionFormValues, OnChange} from "./collection-form";
import {PostCategory} from "./post-category";
import {PostSkeleton} from "../../components/post/post-skeleton";
import {REORDER_POSTS} from "../../../../data/collection";
import {useDebounceCallback} from "../../../../hooks/use-debounce-callback";
import {DnDContext, DraggableItem, DroppableList} from "./drag-drop";
import {P} from "../../../../components/text";
import {Dot} from "../../../../components/images";
import {formatMoney} from "../../../../utils/number";

import styles from "./styles.module.scss";

type ListCollapsedContext = {
	collapsedPosts: Record<number, boolean>;
	setCollapsedPosts: (id: number, isCollapsed: boolean) => void;
	collapsedCategories: Record<number, boolean>;
	setCollapsedCategories: (id: number, isCollapsed: boolean) => void;
};
export const ListCollapsedContext = createContext<ListCollapsedContext>({
	collapsedPosts: {},
	setCollapsedPosts: () => null,
	collapsedCategories: {},
	setCollapsedCategories: () => null,
});
interface PostListProps {
	collection: Collection;
	onChange: OnChange;
	disabled?: boolean;
	addingPost?: boolean;
	onRemoveAllPosts: () => void;
}

export const PostList = (props: PostListProps) => {
	const {collection, onChange, disabled, addingPost, onRemoveAllPosts} = props;
	const [posts, setPosts] = useState(collection.posts || []);
	const {state} = useLocation();
	const focusedOpengraphId = state?.focusedOpengraphId;
	const {values, setFieldValue, setFieldError, setSubmitting} = useFormikContext<CollectionFormValues>();
	const {id, categories: collectionCategories} = values;
	const [reorderPosts, {loading: reorderingPosts}] = useMutationToast(REORDER_POSTS);
	const {data: categoriesData, refetch: refetchCategories} = useQuery<{categories: Category[]}>(
		GET_CATEGORIES
	);
	const [addPost, {loading: addingNewPost}] = useMutationToast(ADD_POST);
	const [createCategory, {loading: creatingCategory}] = useMutationToast(CREATE_CATEGORY);
	const [reorderCategories, {loading: reorderingCategories}] = useMutationToast(REORDER_CATEGORIES);
	const {open: manageCategories, modal: categoriesModal} = useNewModal({});
	const [collapsedPosts, setCollapsedPosts] = useState<Record<number, boolean>>(() =>
		collection.posts.reduce((acc, post) => {
			const ogs = post.opengraphs;
			const og = Object.keys(ogs)
				.map(key => ogs[key])
				.find(og => og?.id === focusedOpengraphId);
			acc[post.id] = og ? false : true;

			return acc;
		}, {} as Record<number, boolean>)
	);
	const [collapsedCategories, setCollapsedCategories] = useState<Record<number, boolean>>({});
	const emvValue = useMemo(
		() => formatMoney(collection.posts.map(getPostEmv).reduce((acc, obj) => acc + obj, 0)),
		[collection.posts]
	);

	useEffect(() => {
		setPosts(posts => [...collection.posts, ...posts.filter(post => !post.id)]);
	}, [collection.posts]);

	useEffect(() => setSubmitting(reorderingCategories), [reorderingCategories, setSubmitting]);

	const onAddPost = useCallback(
		() =>
			addPost({
				variables: {
					id: collection.id,
					categoryId: collectionCategories?.[collectionCategories?.length - 1],
					openGraphData: {
						comment: "",
					},
				},
			}),
		[collectionCategories, collection.id, addPost]
	);

	const onRemovePost = useCallback(
		index => {
			const newPosts = posts.filter((_, i) => i !== index);

			if (posts.length <= 1) {
				onRemoveAllPosts();
			}

			setPosts(newPosts);
		},
		[setPosts, posts, onRemoveAllPosts]
	);

	const categories = useMemo(() => categoriesData?.categories ?? [], [categoriesData?.categories]);
	const categoriesOptions = useMemo(
		() =>
			categories
				.filter(cat => !collection?.categories?.includes(cat.id))
				.map(cat => ({
					onClick: () => onChange("categories", [...(collectionCategories || []), cat.id]),
					label: cat.name,
				})),
		[collection, categories, collectionCategories, onChange]
	);

	const handleAddCategory = useCallback(
		(categoryName: string) =>
			createCategory({
				variables: {
					name: categoryName,
				},
			}).then(({data: {createCategory: category}}) =>
				onChange("categories", [...(collectionCategories || []), category.id])
			),
		[createCategory, onChange, collectionCategories]
	);

	const onValidityChange = useCallback(
		valid => {
			setFieldError("posts", valid ? undefined : "Invalid posts");
		},
		[setFieldError]
	);

	const renderPosts = useCallback(
		({
			posts: listPost,
			index,
			collapsed: isCollapsed,
		}: {
			posts: PostType[];
			collapsed?: boolean;
			index?: number;
		}) =>
			listPost.map((post, idx) => {
				const listIndex = posts.indexOf(post);
				return (
					<DraggableItem
						key={`${post.id}-${listIndex}`}
						draggableId={post.id}
						index={index ?? idx}
						isDragDisabled={reorderingPosts || !post.id}
					>
						{({dragHandleProps}) => (
							<Post
								post={post}
								collectionId={id}
								onRemove={() => onRemovePost(listIndex)}
								dragHandleProps={dragHandleProps}
								onValidityChange={onValidityChange}
								isCollapsed={isCollapsed || collapsedPosts?.[post.id]}
							/>
						)}
					</DraggableItem>
				);
			}),
		[onRemovePost, id, posts, collapsedPosts, reorderingPosts, onValidityChange]
	);

	const setPostCollapsed = useCallback(
		(id: number, isCollapsed: boolean) => {
			setCollapsedPosts(value => ({...value, [id]: isCollapsed}));
		},
		[setCollapsedPosts]
	);
	const setCategoryCollapsed = useCallback(
		(id: number, isCollapsed: boolean) => {
			setCollapsedCategories(value => ({...value, [id]: isCollapsed}));
		},
		[setCollapsedCategories]
	);

	const debouncedReorderPosts = useDebounceCallback(reorderPosts, 2000);
	const onPostDragEnd = useCallback(
		(result: DropResult) => {
			const {source, destination} = result;
			const newPosts = [...posts];
			const [removed] = newPosts.splice(source.index, 1);
			newPosts.splice(destination.index, 0, removed);
			setPosts(newPosts);

			return debouncedReorderPosts({
				variables: {
					id: collection.id,
					postIdsOrder: newPosts.map(post => post.id).filter(Boolean),
				},
			});
		},
		[posts, collection, setPosts, debouncedReorderPosts]
	);
	const onCategoryPostDragEnd = useCallback(
		(result: DropResult) => {
			const {destination} = result;
			let newPosts = [...posts].sort((a, b) => {
				if (a.categoryId !== b.categoryId) {
					return (a.categoryId ?? 0) - (b.categoryId ?? 0);
				}
				return (a.rank ?? 0) - (b.rank ?? 0);
			});
			const post = newPosts.find(p => p.id?.toString() === result.draggableId);

			if (!post) {
				return;
			}

			const postIndex = newPosts.indexOf(post);

			const destinationListPosts = newPosts.filter(p => p.categoryId?.toString() === destination.droppableId);
			const replaceablePost = destinationListPosts[destination.index];
			const destIndex = replaceablePost ? newPosts.indexOf(replaceablePost) : destinationListPosts.length;
			newPosts.splice(postIndex, 1);
			newPosts.splice(destIndex, 0, {...post, categoryId: Number(destination.droppableId)});
			newPosts = newPosts.map((p, index) => ({...p, rank: index + 1}));
			setPosts(newPosts);

			return debouncedReorderPosts({
				variables: {
					id: collection.id,
					postIdsOrder: newPosts.map(post => post.id).filter(Boolean),
					categoryPosts: collection.posts.reduce((acc, post) => {
						const newPost = newPosts.find(p => p.id === post.id);
						const postCategoryId = newPost?.categoryId;

						if (postCategoryId && post.categoryId?.toString() !== postCategoryId.toString()) {
							acc.push({categoryId: postCategoryId, postId: post.id});
						}

						return acc;
					}, [] as {categoryId: number; postId: number}[]),
				},
			});
		},
		[posts, collection, setPosts, debouncedReorderPosts]
	);

	const debouncedReorderCategories = useDebounceCallback(reorderCategories, 2000);
	const onCategoryDragEnd = useCallback(
		(result: DropResult) => {
			const {source, destination} = result;
			const newCategories = [...(collectionCategories ?? [])];
			const [removed] = newCategories.splice(source.index, 1);
			newCategories.splice(destination.index, 0, removed);

			setFieldValue("categories", newCategories);

			debouncedReorderCategories({variables: {id: collection.id, categoryIds: newCategories}});
		},
		[collection.id, debouncedReorderCategories, setFieldValue, collectionCategories]
	);

	const onDragEnd = useCallback(
		(result: DropResult) => {
			if (!result.destination) {
				return;
			}

			if (result.type === "posts") {
				return onPostDragEnd(result);
			}
			if (result.type === "categorized_posts") {
				return onCategoryPostDragEnd(result);
			}
			return onCategoryDragEnd(result);
		},
		[onCategoryDragEnd, onPostDragEnd, onCategoryPostDragEnd]
	);

	const renderList = useCallback(
		placeholderProps => {
			if (!collectionCategories?.length) {
				return (
					<DroppableList
						className={styles.postList}
						droppableId="posts"
						placeholderProps={placeholderProps}
						type="posts"
					>
						{posts.map((post, index) => renderPosts({posts: [post], index}))}
						{!!posts?.length && <div className={styles.separator} />}
					</DroppableList>
				);
			}

			return (
				<DroppableList className={styles.categoryList} droppableId="categories" type="categories">
					{[...collectionCategories, null].map((catId, idx) => {
						const categoryPosts = posts
							.filter(post => Number(post.categoryId) === Number(catId))
							.sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0));

						if (catId === null && !categoryPosts.length) return;

						return (
							<DraggableItem
								className={styles.categoryItem}
								key={`${idx}-${catId}`}
								draggableId={catId}
								index={idx}
								isDragDisabled={!catId || reorderingCategories}
							>
								{({dragHandleProps}) => (
									<>
										{catId && (
											<PostCategory
												postsCount={categoryPosts.length}
												category={
													categories.find(cat => Number(cat.id) === Number(catId)) || ({} as Category)
												}
												onChange={onChange}
												dragHandleProps={dragHandleProps}
											/>
										)}
										{!collapsedCategories[catId ?? 0] && (
											<DroppableList
												droppableId={catId?.toString()}
												type="categorized_posts"
												placeholderProps={placeholderProps}
												className={styles.postList}
											>
												{renderPosts({posts: categoryPosts})}
											</DroppableList>
										)}
										<div className={styles.separator} />
									</>
								)}
							</DraggableItem>
						);
					})}
				</DroppableList>
			);
		},
		[
			posts,
			collectionCategories,
			reorderingCategories,
			collapsedCategories,
			categories,
			onChange,
			renderPosts,
		]
	);

	return (
		<ListCollapsedContext.Provider
			value={{
				collapsedPosts,
				setCollapsedPosts: setPostCollapsed,
				collapsedCategories,
				setCollapsedCategories: setCategoryCollapsed,
			}}
		>
			<div className={styles.posts}>
				<div className={styles.postData}>
					<P color="grey">
						Total Posts: <span className={styles.black}>{collection.posts.length}</span>
					</P>
					<Dot height={4} color="grey-400" />
					<P color="grey">
						Total EMV: <span className={styles.black}>{emvValue}</span>
					</P>
				</div>
				<DnDContext onDragEnd={onDragEnd}>{renderList}</DnDContext>
				{addingPost && <PostSkeleton />}
				<div className={styles.postsToolbar}>
					<Button
						value="New Post"
						onClick={onAddPost}
						loading={addingNewPost}
						icon="add"
						color="blue"
						border={false}
						invert
					/>
					<div className={styles.buttonsSeparator} />
					<DropdownButton
						options={categoriesOptions}
						border={false}
						color="blue"
						invert
						value="Add a Category"
						arrow
						disabled={disabled}
						searchBar
						onAdd={handleAddCategory}
						onManage={manageCategories}
						loading={creatingCategory}
					/>
					<ManageCategories modal={categoriesModal} categories={categories} refetch={refetchCategories} />
				</div>
			</div>
		</ListCollapsedContext.Provider>
	);
};
